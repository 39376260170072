import React, { Component } from "react";

// Functions
import { getServiceNameByAcronym } from "../../../../utils/services";

// Components
import { Row, Col } from "react-bootstrap";

// Style
import '../Board.scss'
import './Documentation.scss'

// ETC
import tts from "../../../../img/tutorial/tts.svg"
import stt from "../../../../img/tutorial/stt.svg"


class Documentation extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="documentation-container">
                <div className="main-bg"></div>
                <div className="main-container">
                    <div className="main-header">
                        <h3>Dokumentasi</h3>
                        <p>Lihat dokumentasi dibawah ini untuk mempelajari lebih lanjut Speech to Text API dan Text To Speech API</p>
                    </div>
                    <div className="main-card-container">
                        <Row>
                            {/* // stt */}
                            <Col className="dashboard-card-col" xs={12} md={6} xl={4}>
                                <DocumentationCard 
                                    title={getServiceNameByAcronym("stt")}
                                    bgImg={stt}
                                    fileUrl={window._link_.stt_api_docs}
                                />
                            </Col> 
                            {/* // tts */}
                            <Col className="dashboard-card-col" xs={12} md={6} xl={4}>
                                <DocumentationCard 
                                    title={getServiceNameByAcronym("tts")}
                                    bgImg={tts}
                                    fileUrl={window._link_.tts_api_docs}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}

class DocumentationCard extends Component {
    render() {
        return (
            <a href={this.props.fileUrl} target="_blank">
                <div className="custom-card documentation-card" id={this.props.id}>
                    <div className="title">{this.props.title.replace("Documentation", "Dokumentasi")}</div>
                    <img className="bg-img" src={this.props.bgImg} />
                </div>
            </a>
        )
    }
}

export default Documentation;