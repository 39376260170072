import { tutorial_list } from "../dummydata";
import { history } from "../store";
import { fetchRefreshToken } from "./UserManagementReducers";

/** 
Declare the type of action as constant.
WHY?
    - Help reduce typos 
    - Help reduce bugs and mistake
    - If you make typo and dispatch an undefined constants,
      the app will throw error to alert the mistake. 
*/

// Import

// Functions

// Action Types
const UPDATE_ID_TOKEN   = "UserManagementReducers/UPDATE_ID_TOKEN";
const REQ_LOGIN         = "UserManagementReducers/REQ_LOGIN";
const RCV_LOGIN         = "UserManagementReducers/RCV_LOGIN";

// Action Creators
/* 
 *  !LEGEND! 
 *      req = request
 *      rcv = receive
 *      fch = fetch
 *      snd = send
 */


// Actions


// You can put impure functions within your action creators

export const fetchTutorialList = () => dispatch => {
    return new Promise((resolve, reject) => {    
        // Put outgoing requester here  

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/"+process.env.REACT_APP_SYSTEM_STATUS+"/tutorial/list/", {
                headers: {
                    "Content-Type"  : 'application/json'
                },
                method: "POST"
            }).then(response => {
                // return dummy data
                // return new Promise((resolve, reject) => {
                //     setTimeout(() => {
                //         resolve(tutorial_list);
                //     }, 2000)    
                // })

                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            }).then(result => {
                if ( result.status === "success" ) {
                    resolve(result);

                } else {
                    if ( result.error === "token expired" ) {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchTutorialList())
                                    .then(()=>{})
                                    .catch(()=>{});
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                    } 
                    else {
                        throw(result.error)
                    }
                }

            }).catch(err => {
                reject(err)
            });
    });
};

// Reducer's initial state
const initialState = {
    status   : "",
    data     : null,
    id_token : ""
};


// Reducers

// You must only write pure function when trying to build the reducer! 

export default function UserManagementReducers(state = initialState, action) {
    switch (action.type) {
        case UPDATE_ID_TOKEN:
            return {
                ...state,
                id_token: action.payload.id_token
            };
        case REQ_LOGIN:
            return {
                ...state,
                status: action.payload.status,
                data: action.payload.data
            };
        case RCV_LOGIN:
            return {
                ...state,
                status: action.payload.status,
                data: action.payload.data
            };
        default:
            return state;
    }
}



// Side effects, only as applicable
// e.g. thunks,epics, etc