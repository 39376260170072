import { NotificationManager} from 'react-notifications';
 
export function toastNotification(type, title, desc) {
    switch (type) {
        case 'info':
            NotificationManager.info(desc, title, 3000, null, true);
            break;
        case 'success':
            NotificationManager.success(desc, title, 3000, null, true);
            break;
        case 'warning':
            NotificationManager.warning(desc, title, 3000, null, true);
            break;
        case 'error':
            NotificationManager.error(desc, title, 6000, null, true);
            break;
        default:
            break;
    }
}