import React, { Component } from "react"
import store, { history } from "../../../store";
import { Link } from "react-router-dom"

// Functions
import { fetchResetPassword } from "../../../reducers/UserManagementReducers";

// components
import { Col, Spinner } from "react-bootstrap";
import ParticlesBackground from '../../../components/ParticlesBackground/ParticlesBackground';

// ETC
import warning from "../../../img/warning.svg"
import back from "../../../img/back.svg"
import close from "../../../img/close.svg"
import sent from "../../../img/email-sent.svg"


class ResetPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            showError: false,
            errorMessage: "",
            showResetDone: false,
            isLoading: false
        };        
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.textInput = React.createRef();
        this.focus = this.focus.bind(this);
    }

    focus() {
        this.textInput.current.focus();
    }
    
    handleEmailChange = ({ target }) => {
        /* eslint-disable */
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([a-zA-Z0-9-\\.]+)$/;
        this.setState({ inputemail: target.value });        
        if (target.value.length > 0) {
          this.setState({ 
            showEmpty: false,
            showError: false
          });
        }        
        if (reg.test(target.value) === false) 
          {   // alert('Invalid Email Address');
            this.setState({ 
              username: target.value,
              email: "" 
            }); 
        } 
        else
        { 
            this.setState({ 
              email: target.value,
              username: ""
            }); 
        }
    };
    
    handleSubmit = (e) => {
        if ( this.state.isLoading ) return;

        e.preventDefault();
        
        let email = this.state.email;

        this.setState({
            isLoading: true,            
            showError: false
        })
        
        store.dispatch(fetchResetPassword(email))
            .then((res) => {
                this.setState({
                    showResetDone: true
                })
            })
            .catch((err) => {     
                this.setState({ 
                    isLoading: false,
                    showError: true,
                    errorMessage: err.toString()
                });           
            }) 
    }

    AlertDismissibleError() {
        let showError = this.state.showError;
      
        if (showError) {
          return (
            <p className="error-alert" dismissible="true">
              <img src={warning} alt="error-dismissible"/>  {this.state.errorMessage}
            </p>
          );
        }
    }

    ForgotPass() {
        let showResetDone = this.state.showResetDone;

        if (!showResetDone) {
            return (
                <React.Fragment>
                    <img className="button-back" src={back} alt="icon-back" onClick={() => this.props.history.go(-1)}/>
                    <div className="form-wrapper">
                        <p className="onboard-header">Forgot Password?</p>
                        <p className="onboard-header-span">Enter your registration email to receive reset password instruction.</p>
                        {this.AlertDismissibleError()}
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-input-onboard">
                                <input 
                                    ref={this.textInput}
                                    type="email" 
                                    id="email" 
                                    autoComplete="on"
                                    placeholder=" "
                                    onChange={this.handleEmailChange}
                                />
                                <label onClick={this.focus}>email</label>
                            </div>
                            
                            <button type="submit" className="submit-button-onboard" id="submit" disabled={this.state.email.length !== 0 ? false : true}>
                                { this.state.isLoading ? 
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> 
                                    : "Reset"
                                }
                            </button>
                        </form>
                    </div>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <img className="button-back" src={close} alt="icon-back" onClick={() => this.props.history.push("/")} />
                    <div className="form-wrapper">
                        <div className="onboard-img-sent"><img src={sent} alt="sent-email-image"/></div>
                        <p className="onboard-header">Email has been sent!</p>
                        <p className="onboard-header-span">
                        Let’s check your inbox and click in the received link to reset password
                        </p>
                        
                        <form onSubmit={this.handleSubmit}>
                            <Link to="/">
                            <button type="submit" className="submit-button-onboard" id="submit">
                                Check email
                            </button>
                            </Link>
                        </form>
                    </div>
                </React.Fragment>
            )
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="onboard-container">
                    <Col xs={12} sm={7} md={5} xl={5} className="onboard-wrapper onboard-form">
                        {this.ForgotPass()}
                    </Col>
                    <Col xs={0} sm={5} md={7} xl={7} className="onboard-wrapper onboard-slider">
                        <ParticlesBackground />
                        <p>Platform Percakapan <br />Kecerdasan Buatan Terbaik</p>
                    </Col>
                </div>               
            </React.Fragment>
        )
    }
}
export default ResetPasswordPage