import React, { Component } from 'react';

const styles = {
    margin: "auto",
    fontSize: "14px",
    color: "rgba(0,0,0,0.5)",
    // fontWeight: "bold"
}

class NoData extends Component {
    render() {
        return (
            <div style={styles}>Tidak ada data</div>
        )
    }
}

export default NoData;