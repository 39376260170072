import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

//FUNCTIONS

//COMPONENTS
import Redirector from '../page/Redirector';
import LandingPageService from '../page/landingpage/LandingPageService'
import LandingPageTTS from '../page/landingpage/LandingPageTTS'
import LandingPageSTT from '../page/landingpage/LandingPageSTT'
import LandingPageNotulite from '../page/landingpage/LandingPageNotulite';
import LoginPage from '../page/OnBoard/LoginPage/LoginPage';
import RegisterPage from '../page/OnBoard/RegisterPage/RegisterPage';
import ResetPasswordPage from '../page/OnBoard/ResetPasswordPage/ResetPasswordPage';
import NewPasswordPage from '../page/OnBoard/NewPasswordPage/NewPasswordPage';
import PrivacyPolicy from '../page/OnBoard/PrivacyPolicy';
import TermsService from '../page/OnBoard/TermsService';
import VerifyEmailPage from '../page/OnBoard/VerifyEmailPage/VerifyEmailPage';

//CSS IMPORTS


function PublicRoutes() {
	return (
		<>
            <Switch>
                <Route exact path="/" component={LandingPageService} />
                <Route exact path="/tts" component={LandingPageTTS} />
                <Route exact path="/stt" component={LandingPageSTT} />
                <Route exact path="/diktein" component={LandingPageNotulite} />
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/register" component={RegisterPage} />
                <Route exact path="/reset-password" component={ResetPasswordPage} />
                <Route exact path="/reset" component={NewPasswordPage} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/terms-service" component={TermsService} />
                <Route exact path="/verify" component={VerifyEmailPage} />
                <Route path="/redirector?" component={Redirector} />
                <Redirect from="/:someroute" to="/" />
            </Switch>
		</>
	)
}

export default PublicRoutes;
