import React, { useEffect, useState } from 'react'
import './Faq.scss'
import minus from '../../img/landing/-.svg'
import plus from '../../img/landing/+.svg'

function Faq(props){
    const { type } = props;

    const [selectedI, setI] = useState([]);
    const [data, setData] = useState([]);
    const [toggle, setToggle] = useState(false);
    
    useEffect(() => {
        let file_path = `/faq-${type}.json`
        fetch(file_path)
            .then((res) => res.json())
            .then((data) => {
                setData(data.faq);
            })
    }, []);

    function addIndex(i) {
        const arr = selectedI.slice();
        arr.push(i);
        setI(arr);
        setToggle(!toggle);
        // console.log(arr)
    }

    function delIndex(i) {
        const arr = selectedI.slice();
        const idx = arr.indexOf(i);

        // console.log(arr);
        if (idx > -1) {
            arr.splice(idx, 1);
        }
        setI(arr);        
        setToggle(!toggle);

    }
    return (
        <div className="faq-container">
            <h3>Frequently Asked Questions</h3>
            <div className="faq-list-wrapper">
                {data.map((item, i) => {
                    return (
                        <FaqItem
                            key={`${item}-${i}`}
                            question={item.q}
                            answer={item.a}                        
                        />
                    )
                })}
            </div>
        </div>
    )
}

function FaqItem(props) {
    const [toggle, setToggle] = useState(false);
    return (
        <div className="faq-item-cont" onClick={()=>setToggle(!toggle)}>
            <div className="faq-ask">
                {props.question}
                {toggle ?
                    <img src={minus} alt="icon" id="icon-faq"/>
                    :
                    <img src={plus} alt="icon" id="icon-faq"/>
                }
                </div>
            {
                toggle ? 
                <div className="faq-answer">
                    {props.answer}
                </div>
                : null
            }            
        </div>
    )
}

export default Faq;