import React from 'react'
import "./FooterLanding.scss"
// import { Link } from "react-router-dom"
import { Image } from 'react-bootstrap'
import goverment from "../../img/landing/goverment.svg"
import healthcare from "../../img/landing/healthcare.svg"
import retail from "../../img/landing/retail.svg"
import linkedin from "../../img/landing/linkedin.svg"
import youtube from "../../img/landing/youtube.svg"
import instagram from "../../img/landing/instagram.svg"
import logoblack from "../../img/landing/logo-black.svg"

export class FooterLanding extends React.Component {

    contentFooterTop = () => {
        return (
            <div className="landingpage-footer-content">
                <div className="footer-content-teks">
                    <Image src={goverment} />
                    <h3>Goverment</h3>
                    <p>
                        Tingkatkan pelayanan sector pemerintahan dengan menggunakan kecerdasan buatan Indonesia
                    </p>
                </div>
                <div className="footer-content-teks">
                    <Image src={healthcare} />
                    <h3>Healtcare</h3>
                    <p>
                        Tingkatkan pelayanan sector pemerintahan dengan menggunakan kecerdasan buatan Indonesia
                    </p>
                </div>
                <div className="footer-content-teks">
                    <Image src={retail} />
                    <h3>Retail</h3>
                    <p>
                        Tingkatkan pelayanan sector pemerintahan dengan menggunakan kecerdasan buatan Indonesia
                    </p>
                </div>
            </div>
        )
    }

    contentFooterLink = () => {
        return (
            <div className="landingpage-footer-content menu">
                <div className="footer-content-teks-menu">
                    <h5>Bahasa Kita</h5>
                    <p>
                        <a href={window._link_.about}>
                            Tentang Kami
                        </a>
                    </p>
                    <p>
                        <a href={window._link_.contact}>
                            Kontak Kami
                        </a>
                    </p>
                </div>
                <div className="footer-content-teks-menu">
                    <h5>Meeting </h5>
                    <p>
                        <a href={window._link_.minit}>
                            Minit
                        </a>
                    </p>
                    <p>
                        <a href={window._link_.notulite}>
                            Notulite
                        </a>
                    </p>
                    {/* <p>
                        <a href={window._link_.diktein}>
                            Diktein
                        </a>
                    </p> */}
                    {/* <p>
                        <a href={window._link_.notulite}>
                            Notulite
                        </a>
                    </p>
                    <p>
                        <a href={window._link_.notula}>
                            Notula
                        </a>
                    </p> */}
                </div>
                <div className="footer-content-teks-menu">
                    <h5>Lifestyle </h5>
                        <p>
                            <a href={window._link_.tman}>T-Man (Prototipe)</a>
                        </p>
                    
                        <p>
                            <a href={window._link_.smartspeaker}>
                                Smart Speaker (Prototipe)
                            </a>
                        </p>
                    
                </div>
                <div className="footer-content-teks-menu">
                    <h5>AI Engine</h5>
                    <p>
                        <a href={window._link_.tts_landing}>
                            Text to Speech
                        </a>
                    </p>
                    <p>
                        <a href={window._link_.stt_landing}>
                            Speech  to Text
                        </a>
                    </p>
                </div>
                <div className="footer-content-teks-menu">
                    <h5>Follow Us </h5>
                    <p>
                        <a href={window._link_.instagram} target="_blank" rel="noopener noreferrer">
                            <Image src={instagram} />
                            Instagram
                        </a>
                    </p>
                    <p>
                        <a href={window._link_.linkedin} target="_blank" rel="noopener noreferrer">
                            <Image src={linkedin} />
                            Linkedin
                        </a>
                    </p>
                    <p>
                        <a href={window._link_.youtube} target="_blank" rel="noopener noreferrer">
                            <Image src={youtube} />
                            Youtube
                        </a>
                    </p>
                </div>
            </div>
        )
    }

    contentFooterSosmed = () => {
        return (
            <div className="landingpage-footer-sosmed">
                <p><Image src={logoblack} alt=""/> PT Bahasa Kinerja Utama</p>
            </div>
        )
    }

    render() {
        return (
            <div id="landingpage-footer">
                {/* <h1>Industry Solutions</h1> */}
                {/* {this.contentFooterTop()} */}
                {this.contentFooterLink()}
                {this.contentFooterSosmed()}
            </div>
        )
    }
}

export default FooterLanding
