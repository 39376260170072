import React, { Component } from 'react';
import { useSetState } from 'react-use';
import { withRouter } from 'react-router'; 
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';

import store, { history } from "../../../../store";

// FUNCTIONS
import { toastNotification } from '../../../../modules/ToastNotification';
import { 
    fetchUserPackageDetail, 
    fetchGenerateSTTApiKey,
    fetchGenerateTTSApiKey,
    fetchGenerateTextTranslateApiKey,
    fetchGenerateAudioTranslateApiKey,
} from "../../../../reducers/UserPackageReducers";
import { fetchUserDetail } from '../../../../reducers/UserManagementReducers';

// COMPONENTS
import { Container, Row, Col, OverlayTrigger, Tooltip, Button, Badge, Spinner } from "react-bootstrap";
import Loading from "../../../../components/Loading"
import UsageHistory from './UsageHistory';
import ModalInfoCreatePassword from '../../../../components/Account/ModalInfoCreatePassword';

// STYLES
import "../Board.scss"
import "./YourServiceDetail.scss"

// ASSETS
import back from "../../../../img/back.svg"
import a from "../../../../img/icon-a.svg"
import icon_error from "../../../../img/icon-error.svg"
import copy from "../../../../img/copy.svg"
import eye from "../../../../img/eye-white.svg"
import eye_closed from "../../../../img/eye-closed-white.svg"
import ModalVerifyEmail from '../../../../components/Account/ModalVerifyEmail';
import ModalChangeEmail from '../../../../components/Account/ModalChangeEmail';
import { useRef } from 'react';


function YourServiceDetail(props) {
    const initialState = {    
        id_user_package : parseInt(props.match.params.id_user_package),
        name : props.match.params.name,
        service_name : props.match.params.service_name,

        userpackage_detail : null,

        unit: "",

        isLoading : true,
        isLoadingGenerateToken : false,

        copy_tooltip: "Copy to clipboard",
        show_api_key: false,

        apiKeyExpiresIn: "-"
        // apiKeyExpiresIn: "expired"
    };

    const dispatch = useDispatch();

	const { id_token, userDetail } = useSelector(state => state.UserManagementReducers);
    
    const [state, setState] = useSetState({...initialState});
    const stateRef = useRef();

    const [showModalInfoCreatePassword, setShowModalInfoCreatePassword] = useState(false);
    const [showModalVerifyEmail, setShowModalVerifyEmail] = useState(false);
    const [showModalChangeEmail, setShowModalChangeEmail] = useState(false);
   
    let copyTooltipTimeout      = null;
    let apiKeyExpiredCountDown  = null;

    // componenDidMount
    useEffect(() => {
        window.scrollTo(0,0);

        getUserPackageDetail();

        startApiKeyExpiredCountDown();

        // componenWillUnmount
        return () => {
            stopApiKeyExpiredCountDown();

        }
    }, [])

    useEffect(() => {
        stateRef.current = state;
    }, [state])
    
    const updateUnit = (val) => {
        setState({
            unit: val
        })
    }

    const startApiKeyExpiredCountDown = () => {
        // console.log("startApiKeyExpiredCountDown")
        apiKeyExpiredCountDown = setInterval(() => {
            // console.log("apiKeyExpiredCountDown")

            let userpackage_detail  = stateRef.current.userpackage_detail;
            // console.log(userpackage_detail)
            if ( userpackage_detail                     !== null    && 
                userpackage_detail.api_key.key          !== ""      &&
                userpackage_detail.api_key.expire_date  !== "" 
            ) {
                let current_date        = new Date();
                let api_key_expire_date = new Date(userpackage_detail.api_key.expire_date);
                // let api_key_expire_date = new Date();
                // console.log(api_key_expire_date)

                const expiredInSeconds  = (api_key_expire_date.getTime() - current_date.getTime()) / 1000;
                // console.log(expiredInSeconds)
                let apiKeyExpiresIn     = "-";

                if ( expiredInSeconds <= 0 ) {
                    apiKeyExpiresIn = "expired"                

                } else {
                    const minInSec  = 60;
                    const hourInSec = 3600;
                    const dayInSec  = 86400;

                    let days    = Math.floor(expiredInSeconds/dayInSec);
                    let hours   = Math.floor((expiredInSeconds % dayInSec) / hourInSec);
                    let minutes = Math.floor((expiredInSeconds % hourInSec) / minInSec);
                    let seconds = Math.floor(expiredInSeconds % minInSec);

                    apiKeyExpiresIn = `${days} Days ${hours} Hours ${minutes} Minutes ${seconds} Seconds`;
                }

                // console.log(apiKeyExpiresIn)
                setState({
                    ...stateRef.current, 
                    apiKeyExpiresIn: apiKeyExpiresIn
                })
            };
        }, 1000)
    }
    
    const stopApiKeyExpiredCountDown = () => {
        clearInterval(apiKeyExpiredCountDown)
        apiKeyExpiredCountDown = null;
    }

    const getUserPackageDetail = () => {
        dispatch(fetchUserPackageDetail(id_token, state.id_user_package))
            .then((res) => {
                setState({
                    userpackage_detail: res,
                    isLoading: false
                })
            })
            .catch((err) => {
                setState({
                    isLoading: false
                })
            });
    }

    const onCopyApiKeysClick = (api_key) => {
        // console.log("onCopyApiKeysClick")

        clearTimeout(copyTooltipTimeout);
        copyTooltipTimeout = null;

        navigator.clipboard.writeText(api_key);

        setState({
            copy_tooltip: "Copied!"
        })

        copyTooltipTimeout = setTimeout(() => {
            setState({
                ...stateRef.current, 
                copy_tooltip: "Copy to clipboard"
            })
        }, 3000)
    }

    const handleGenerateClick = async () => {
        setState({
            isLoadingGenerateToken: true
        });

        let password_created, verified = false;

        await dispatch(fetchUserDetail(id_token))
			.then((res) => {
                if ( res.status === "success" ) {
                    password_created = res.data.password_created;
                    verified = res.data.verified;

                    // if ( !password_created ) {
                    //     toastNotification("error", "You need to create account password before generate token", "Go to account page to create password")
                    // } else if ( !verified ) {
                    //     toastNotification("error", "You need to verify your email before generate token", "Go to account page to verify your email")
                    // }
                }
                // console.log(res)
			})
			.catch(() => {
			})
        
        if ( !password_created || !verified ) {
            setState({
                isLoadingGenerateToken: false
            });

            if ( !password_created ) {
                setShowModalInfoCreatePassword(true)
            } else if ( !verified ) {
                setShowModalChangeEmail(false);
                if ( !userDetail.verified ) {
                    setShowModalVerifyEmail(true);
                }
            }
            return
        };

        if ( state.service_name.toLowerCase().indexOf("stt") > -1 ) {
            dispatch(fetchGenerateSTTApiKey())
                .then((api_key) => {
                    if ( state.userpackage_detail !== null ) {
                        setState({
                            userpackage_detail: {
                                ...state.userpackage_detail,
                                api_key: api_key
                            },
                            isLoadingGenerateToken: false
                        })
                        toastNotification("success", "Speech to Text Api Key Updated")
                    }
                })
                .catch((err) => {
                    setState({
                        isLoadingGenerateToken: false
                    });
                }) ;

        } else if ( state.service_name.toLowerCase().indexOf("tts") > -1 ) {
            dispatch(fetchGenerateTTSApiKey())
                .then((api_key) => {
                    if ( state.userpackage_detail !== null ) {
                        setState({
                            userpackage_detail: {
                                ...state.userpackage_detail,
                                api_key: api_key
                            },
                            isLoadingGenerateToken: false
                        })
                        toastNotification("success", "Text to Speech Api Key Updated")
                    }
                })
                .catch((err) => {
                    setState({
                        isLoadingGenerateToken: false
                    });
                }) ;
        } else if ( state.service_name.toLowerCase().indexOf("text-translate") > -1 ) {
            dispatch(fetchGenerateTextTranslateApiKey())
                .then((api_key) => {
                    if ( state.userpackage_detail !== null ) {
                        setState({
                            userpackage_detail: {
                                ...state.userpackage_detail,
                                api_key: api_key
                            },
                            isLoadingGenerateToken: false
                        })
                        toastNotification("success", "Text to Speech Api Key Updated")
                    }
                })
                .catch((err) => {
                    setState({
                        isLoadingGenerateToken: false
                    });
                }) ;
        } else if ( state.service_name.toLowerCase().indexOf("audio-translate") > -1 ) {
            dispatch(fetchGenerateAudioTranslateApiKey())
                .then((api_key) => {
                    if ( state.userpackage_detail !== null ) {
                        setState({
                            userpackage_detail: {
                                ...state.userpackage_detail,
                                api_key: api_key
                            },
                            isLoadingGenerateToken: false
                        })
                        toastNotification("success", "Text to Speech Api Key Updated")
                    }
                })
                .catch((err) => {
                    setState({
                        isLoadingGenerateToken: false
                    });
                }) ;
        } 
    }

    const handleChangeEmailClick = () => {
        if ( !userDetail.verified ) {
            setShowModalVerifyEmail(false);
        }
        setShowModalChangeEmail(true);
    }
    const handleCancelChangeEmailClick = () => {
        setShowModalChangeEmail(false);
        if ( !userDetail.verified ) {
            setShowModalVerifyEmail(true);
        }
    }

    
    let quota, error_count, remaining_days;

    if ( state.userpackage_detail !== null ) {
        quota = state.userpackage_detail.volume_base.kuota + state.userpackage_detail.time_base.kuota;
        if ( !quota ) quota = 0; 
        else if ( quota < 0 ) quota = "Unlimited"; 
        
        error_count = state.userpackage_detail.error_count;
        
        remaining_days = state.userpackage_detail.remaining_days;
        // console.log(remaining_days)
        if ( !remaining_days ) remaining_days = 0; 
        else if ( remaining_days < 0 ) quota = "Unlimited"; 
        
        if ( state.name === "volume-based" ) {
            remaining_days = "-";
        }
    }
    
    let api_key         = "";
    let api_key_masked  = "";
    let expire_date     = "";

    if (state.userpackage_detail !== null && state.userpackage_detail.api_key.key !== "" ) {
        api_key     = state.userpackage_detail.api_key.key;
        expire_date = state.userpackage_detail.api_key.expire_date;

        const len = api_key.length;
        const first_chars = api_key.substring(0, Math.floor(len/2));
        api_key_masked = api_key.replace(first_chars, ("*").repeat(first_chars.length))
    };

    // console.log(state.service_name)

    return (
        <>
            <ModalInfoCreatePassword 
                closeButton={true}
                show={showModalInfoCreatePassword}
                onHide={() => setShowModalInfoCreatePassword(false)}
            />
            <ModalVerifyEmail 
                closeButton={true}
                show={showModalVerifyEmail}
                onHide={() => setShowModalVerifyEmail(false)}
                handleChangeEmailClick={handleChangeEmailClick}
            />
            <ModalChangeEmail 
                show={showModalChangeEmail}
                onHide={() => setShowModalChangeEmail(false)}
                onCancel={handleCancelChangeEmailClick}
            />

            <div id="your-service-detail-container">
                <Container>
                    <Row>
                        <Col xs={12} className="head">
                            <button className="back-btn" id="dashboardDetailServices_backButton" onClick={() =>history.goBack()}>
                                <img src={back} alt="back-icon"/>
                            </button>
                            {/* <h3>Text to Speech 1 Engine</h3> */}
                            <h3>
                                <span>{state.service_name}</span>
                                {state.name.split('-').join(' ')}
                            </h3>
                            
                            <>
                                { ( state.service_name === "notula" ) &&
                                    <>
                                        <a className="btn-app" href={window._link_["notula_landing"]}>
                                            <Button className="btn-secondary add-quota-btn" id="dashboardDetailServices_addQuota">
                                                Tambah Quota <span className="icon">+</span> 
                                            </Button>
                                        </a>
                                        <a className="btn-app" href={window._link_["notula"]} target="_blank" rel="noopener noreferrer">
                                            <Button id="dashboardDetailServices_goToApp">
                                                Go to Notula
                                            </Button>
                                        </a>
                                    </>
                                }
                                { ( state.service_name === "notulite" ) &&
                                    <>
                                        <a className="btn-app" href={window._link_["notulite_landing"]}>
                                            <Button className="btn-secondary add-quota-btn" id="dashboardDetailServices_addQuota">
                                                Tambah Quota <span className="icon">+</span> 
                                            </Button>
                                        </a>
                                        <a className="btn-app" href={window._link_["notulite"]} target="_blank" rel="noopener noreferrer">
                                            <Button id="dashboardDetailServices_goToApp">
                                                Go to Notulite
                                            </Button>
                                        </a>
                                    </>
                                }
                                { ( state.service_name === "dikte.in" || state.service_name === "diktein" ) &&
                                    <>
                                        <a className="btn-app" href={window._link_["diktein_landing"]}>
                                            <Button className="btn-secondary add-quota-btn" id="dashboardDetailServices_addQuota">
                                                Tambah Quota <span className="icon">+</span> 
                                            </Button>
                                        </a>
                                        <a className="btn-app" href={window._link_["diktein"]} target="_blank" rel="noopener noreferrer">
                                            <Button id="dashboardDetailServices_goToApp">
                                                Go to Dikte.in
                                            </Button>
                                        </a>
                                    </>
                                }
                                { state.service_name.toLowerCase().indexOf("stt") > -1 &&
                                    <>
                                        <a className="btn-app" href={window._link_.stt_landing}>
                                            <Button className="btn-secondary add-quota-btn" id="dashboardDetailServices_addQuota">
                                                Tambah Quota <span className="icon">+</span> 
                                            </Button>
                                        </a>
                                        {/* <a className="btn-app" href={window._link_.stt_api_docs} target="_blank" rel="noopener noreferrer">
                                            <Button id="dashboardDetailServices_seeDoc">
                                                Dokumentasi API
                                            </Button>
                                        </a> */}
                                    </>
                                }
                                { state.service_name.toLowerCase().indexOf("tts") > -1 &&
                                    <>
                                        <a className="btn-app" href={window._link_.tts_landing}>
                                            <Button className="btn-secondary add-quota-btn" id="dashboardDetailServices_addQuota">
                                                Tambah Quota <span className="icon">+</span> 
                                            </Button>
                                        </a>
                                        {/* <a className="btn-app" href={window._link_.tts_api_docs} target="_blank" rel="noopener noreferrer">
                                            <Button id="dashboardDetailServices_seeDoc">
                                            Dokumentasi API
                                            </Button>
                                        </a> */}
                                    </>
                                }
                                { state.service_name.toLowerCase().indexOf("audio-translate") > -1 &&
                                    <>
                                        <a className="btn-app" href={window._link_.audioTranslate_landing}>
                                            <Button className="btn-secondary add-quota-btn" id="dashboardDetailServices_addQuota">
                                                Tambah Quota <span className="icon">+</span> 
                                            </Button>
                                        </a>
                                        {/* <a className="btn-app" href={window._link_.tts_api_docs} target="_blank" rel="noopener noreferrer">
                                            <Button id="dashboardDetailServices_seeDoc">
                                            Dokumentasi API
                                            </Button>
                                        </a> */}
                                    </>
                                }
                                { state.service_name.toLowerCase().indexOf("text-translate") > -1 &&
                                    <>
                                        <a className="btn-app" href={window._link_.textTranslate_landing}>
                                            <Button className="btn-secondary add-quota-btn" id="dashboardDetailServices_addQuota">
                                                Tambah Quota <span className="icon">+</span> 
                                            </Button>
                                        </a>
                                        {/* <a className="btn-app" href={window._link_.tts_api_docs} target="_blank" rel="noopener noreferrer">
                                            <Button id="dashboardDetailServices_seeDoc">
                                            Dokumentasi API
                                            </Button>
                                        </a> */}
                                    </>
                                }
                            </>
                        </Col>
                    </Row>

                    { state.isLoading ? 
                        <Loading /> :
                        <>
                            { ( state.service_name.toLowerCase().indexOf("tts") > -1 || 
                                    state.service_name.toLowerCase().indexOf("stt") > -1 || 
                                    state.service_name.toLowerCase().indexOf("text-translate") > -1  ||
                                    state.service_name.toLowerCase().indexOf("audio-translate") > -1 ) &&
                                <Row>
                                    <Col xs={12} md={12} xl={12}>
                                        <div className="side-card" id="api-key">
                                            <div className="side-card-title">Token</div>
                                            <div className="keys-container">
                                                <div className="keys">
                                                    { api_key.replace(/\s+/g, '') !== "" && expire_date.replace(/\s+/g, '') !== "" ?
                                                        <>
                                                            { state.show_api_key ? api_key : api_key_masked}
                                                        </>
                                                        :
                                                        <>You haven't generate any token</>
                                                    }
                                                </div>
                                                {/* { api_key.replace(/\s+/g, '') !== "" ?
                                                    <textarea className="keys" value={state.show_api_key ? api_key : api_key_masked} />
                                                    :
                                                    <textarea className="keys" value="You haven't generate any token" />
                                                } */}
                                                <div className="tools">
                                                    { api_key.replace(/\s+/g, '') !== "" && expire_date.replace(/\s+/g, '') !== "" &&
                                                        <>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip>{state.show_api_key ? "Hide": "Show"}</Tooltip>}
                                                            >
                                                                <img alt="copy-keys"
                                                                    id="dashboardDetailServices_seeAPIkeys" 
                                                                    src={state.show_api_key ? eye_closed : eye}
                                                                    onClick={() => setState({ show_api_key: !state.show_api_key})}
                                                                />
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip key={state.copy_tooltip}>{state.copy_tooltip}</Tooltip>}
                                                            >
                                                                <img alt="copy-keys"
                                                                    id="dashboardDetailServices_copyAPIkeys" 
                                                                    src={copy}
                                                                    onClick={() => onCopyApiKeysClick(api_key)}
                                                                />
                                                            </OverlayTrigger>
                                                        </>
                                                    }
                                                    {/* { state.isLoadingGenerateToken ?
                                                        <Button variant="light">
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </Button>
                                                        :
                                                        <Button variant="light" onClick={handleGenerateClick}>Generate</Button>
                                                    } */}
                                                </div>
                                            </div>
                                            <div className="desc">
                                                Use this token to connect with our service
                                                <span className="api-key-expires-countdown">
                                                    { state.apiKeyExpiresIn === "expired" ?
                                                        <h5>
                                                            <Badge variant="danger">Token Expired</Badge>
                                                        </h5>
                                                        : 
                                                        <>Expires in: {state.apiKeyExpiresIn}</>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            } 
                            <Row>
                                <Col xs={12} md={12} xl={12} className="status-card" id="quota">
                                    <StatusCard
                                        titleStatus={"Kuota "+state.unit}
                                        statImg={a}
                                        status={quota}
                                    ></StatusCard>
                                </Col>
                                {/* <Col xs={12} md={6} xl={6} className="status-card">
                                    <StatusCard
                                        titleStatus="Error"
                                        statImg={icon_error}
                                        status={error_count}
                                    ></StatusCard>
                                </Col> */}
                                {/* <Col xs={12} md={4} xl={4} className="status-card">
                                    <StatusCard
                                        titleStatus="Sisa Hari"
                                        statImg={date}
                                        status={remaining_days}
                                        statusSpan=""
                                    ></StatusCard>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col xs={12} md={12} xl={12} id="usage-history">
                                    <UsageHistory 
                                        id_user_package={state.id_user_package} 
                                        updateUnit={updateUnit}
                                        // source=""
                                        // status=""
                                    />                           
                                </Col>
                            </Row>
                        </>
                    }
                    
                </Container>

            </div>
        </>
    )
}

class StatusCard extends Component {
    render() {
        return (
            <div className="status-card-container">
                <div className="top">
                    <div className="top-status">
                        {this.props.titleStatus}
                    </div>
                    <img src={this.props.statImg} className="status-img" alt="icon-status"/>
                </div>
                <div className="body">
                    {this.props.status} 
                    <div className="sub-body">{this.props.statusSpan}</div>                
                </div>
            </div>
        )
    }
}

export default YourServiceDetail;