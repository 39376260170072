import React from 'react'
import { useEffect, useState } from 'react';

import { Modal, Spinner } from 'react-bootstrap' 
import Input from '../../../../components/Input/Input';

import './Account.scss'


function ModalConfirmPassword(props) {
    const { show=false, onHide=()=>{}, onSubmit=()=>{}, loading=false } = props;

    const [password, setPassword] = useState("");

    useEffect(() => {
        if ( !show ) {
            setPassword("")
        }
    }, [show])

    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        onSubmit(password);
    }

    return (
        <>
            <Modal 
                className="modal-confirm-password" 
                show={show} 
                onHide={onHide}
                backdrop="static"
                centered
            >
                {/* <Modal.Header>
                    <div className="title">Enter your password to proceed</div>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="title">Enter your password to proceed</div>
                    <div className="content">
                            <form onSubmit={handleSubmit}>
                                <Input 
                                    size="md"
                                    alt="input-confirm-new-password" 
                                    type="password" 
                                    placeholder="Enter Password"
                                    value={password}
                                    onChange={handlePasswordChange} 
                                    showTogglePassword={true}
                                />
                                <div className="button-group">
                                    {/* { loading ?
                                        <button
                                            className="btn btn-primary submit-button-onboard"  
                                            id="submit"
                                        >
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </button>
                                        :
                                        <> */}
                                            <button type="submit" 
                                                className="btn btn-primary" 
                                                id="submit"
                                                disabled={loading || password.length === 0 ? true : false}
                                            >
                                                { loading ?      
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    : "Confirm"
                                                }
                                            </button>
                                            <button type="button"
                                                className="btn btn-secondary" 
                                                onClick={onHide}
                                                disabled={loading}
                                            >
                                                Cancel
                                            </button>
                                        {/* </>
                                    } */}
                                </div>
                            </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalConfirmPassword;