/** 
 * !README FIRST BEFORE EDITING THIS MODULE!
 * 
 * You can import actions from another modules into this nats middleware.
 * 1. If the action needs to send request from the client,
 *    please put a case within the return function inside the MIDDLEWARE SECTION.
 * 2. If the action is a server response, please put a case within onMessage 
 *    variable inside the  CONST VARIABLE SECTION.
 * 
 * further information about redux middleware =>
 * https://redux.js.org/advanced/middleware
 * 
*/
//JS IMPORTS
import {
    NATS_OPEN,
    NATS_DISCONNECT,
    NATS_MSG_SEND,
    natsConnOpen,
    natsConnConnected,
    natsConnConnecting,
    natsConnDisconnected,
    natsConnDisconnect,
    natsMessageSend
} from '../reducers/NATSDefaultReducers';
import { NATSRequester } from './NATSMethods';


let NATS = require('websocket-nats');

// MIDDLEWARE SECTION 
/** 
 * NATS Middleware 
 * */
export var nats = null 

const NATSMiddleware = (function () {
    /** CONFIGURATION */
    var url = process.env.REACT_APP_NATS_RELAY_URL;

    // console.log("NATS: " + url);
    
    const onConnected = (store) => {
        console.log("connected to server")
        // console.log(url);
        
        // Tell the store we're connected
        store.dispatch(natsConnConnected());
    }
    
    const onError = (store) => {
        console.log("NATS error")

        // Disconnect nats
        store.dispatch(natsConnDisconnect());
                
        // const target = document.querySelector('#popup-notification-container');
        // if ( target !== null ) {
        //     ReactDOM.render(
        //         <PopupNotification
        //             content={
        //                 <div>
        //                     <div>Tidak dapat terkoneksi dengan server. Coba <a onClick={() => { window.location.reload() }}>refresh halaman ini</a> beberapa saat lagi.</div>
        //                     <div>Jika kendala masih terjadi, harap segera hubungi admin.</div>
        //                 </div>
        //             } 
        //         />,
        //         target
        //     );
        // }
    }

    const onClose = (store) => {    
        console.log("NATS close")
            
        // Tell the store we've disconnected
        store.dispatch(natsConnDisconnected());

        // Try to reconnect in 30 seconds
        setTimeout(() => {
            store.dispatch(natsConnOpen());
        }, 30000);
    }

    // CORE MIDDLEWARE SECTION 
    return store => next => action => {
        switch (action.type) {
            // Create new connection to server
            case NATS_OPEN:
                if ( nats != null ) {
                    nats.close();
                }

                console.log("Connecting to NATS")
                // console.log(url);

                // Send an action that shows a "connecting..." status for now
                store.dispatch(natsConnConnecting());

                // Run nats API to connect to server
                // [begin connect_url]
                nats = NATS.connect(url);


                // connect callback provides a reference to the connection as an argument
                nats.on('connect', function(c) {
                    onConnected(store);
                });
                
                // emitted whenever there's an error. if you don't implement at least
                // the error handler, your program will crash if an error is emitted.
                nats.on('error', function(err) {
                    onError(store);
                });
                
                // emitted whenever the client disconnects from a server
                nats.on('disconnect', function() {
                    console.log('NATS disconnect');
                });
                
                // emitted whenever the client is attempting to reconnect
                nats.on('reconnecting', function() {
                    console.log('NATS reconnecting');
                });
                
                // emitted whenever the client reconnects
                // reconnect callback provides a reference to the connection as an argument
                nats.on('reconnect', function(nats) {
                    console.log('NATS reconnect');
                });
                
                // emitted when the connection is closed - once a connection is closed
                // the client has to create a new connection.
                nats.on('close', function() {
                    onClose(store);
                });
                
                // emitted whenever the client unsubscribes
                nats.on('unsubscribe', function(sid, subject) {
                    // console.log("unsubscribed subscription", sid, "for subject", subject);
                });
                
                // emitted whenever the server returns a permission error for
                // a publish/subscription for the current user. This sort of error
                // means that the client cannot subscribe and/or publish/request
                // on the specific subject
                nats.on("permission_error", function(err) {
                    console.error("NATS got a permissions error", err.message);
                });

                break;

            // Disconnection process
            case NATS_DISCONNECT:
                if (nats != null) {
                    nats.close();
                }
                nats = null;

                // Set state to disconnected
                store.dispatch(natsConnDisconnected());

                // Open websocket-nats connection
                store.dispatch(natsConnOpen());
                break;

            // Send message process
            case NATS_MSG_SEND:
                if ( store.getState().NATSDefaultReducers.nats_status !== "connected" ) {
                    setTimeout(() => {
                        store.dispatch(natsMessageSend(action.request_topic, action.message));
                    }, 100)
                    return;
                }
                NATSRequester(nats, action.request_topic, action.message);
                break;

            // This is mandatory for passing the state to the next middleware
            default:
                return next(action);
        }
    }
})();

export default NATSMiddleware;