import React, { Component } from 'react'
import store, { history } from "../../store"

import "./BillingStepHeader.scss"

import back from "../../img/back-black.svg"
import close from "../../img/close-black.svg"


class BillingStepHeader extends Component {
    render() {
        const totalStep = this.props.totalStep === undefined ? 0 : this.props.totalStep;
        const currentStep = this.props.currentStep === undefined ? 0 : this.props.currentStep;

        return (
            <div className="billing-step-header">
                { totalStep === currentStep?
                    null :
                    <img src={back} alt="navigation-close" id="chooseplan_back" className="btn-close" onClick={() => {history.goBack();}} />
                }
                <div className="step-dots">    
                    { Array(totalStep).fill().map((_, index) => {
                        const i = index+1;
                        return (
                            <div key={"billing-step-"+i} className={"step-dot " + (i <= currentStep ? "active" : "")}></div>
                        )
                    }) }
                </div>
                { totalStep === currentStep?
                    null :
                    <img src={close} alt="navigation-back" id="chooseplan_close" className="btn-close" onClick={() => {history.goBack();}} />
                }
            </div>
        )
    }
}

export default BillingStepHeader;