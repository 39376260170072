import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../store";

import { fetchOrderCreate } from "../../reducers/BillingReducers"
import { fetchAllPackagesOfAllServices, updateServicePackage } from '../../reducers/ServiceReducers';

import { Col, Row } from 'react-bootstrap'
import LoadingFullPage from "../../components/LoadingFullPage"
import OrderSummary from "../../components/payment/OrderSummary"
import { NavLink } from 'react-router-dom'
import "./Payment.scss"


function Cart() {
    const { id_token } = useSelector(state => state.UserManagementReducers);
    const { service_package_selected } = useSelector(state => state.ServiceReducers);

    const [idServicePayment, setIdServicePayment] = useState(7); // midtrans snap pay
    const [idPackage, setIdPackage] = useState(0);
    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState(0);

    const dispatch = useDispatch();

    const handleContinuePay = () => {
        console.log("handleContinuePay")

        if (quantity === 0) return;

        window.snap.show();

        dispatch(fetchOrderCreate(idServicePayment, idPackage, quantity))
            .then((res) => {
                // console.log(res)

                window.snap.pay(res.snap_token, {
                    onSuccess: (result) => {
                        // console.log('success');                        
                        const params = new URLSearchParams({ status: "success" });
                        history.push({ pathname: "/transactions", search: params.toString() });
                    },
                    onPending: (result) => {
                        // console.log('pending');                        
                        const params = new URLSearchParams({ status: "success" });
                        // const params = new URLSearchParams({status: "processing" });
                        history.push({ pathname: "/transactions", search: params.toString() });
                    },
                    onError: (result) => {
                        // console.log('error');                        
                        const params = new URLSearchParams({ status: "success" });
                        history.push({ pathname: "/transactions", search: params.toString() });
                    },
                    onClose: () => {
                        // console.log("onClose")
                        history.goBack();
                        // console.log('customer closed the popup without finishing the payment');
                    }
                })
                window.location.hash = "snappay"

            })
            .catch((err) => {
                window.snap.hide();
                history.goBack();
            });
    }

    useEffect(() => {
        // console.log("componentDidMount")

        window.onhashchange = handleHashChange;
        // console.log(window.location.hash)
        if (window.location.hash === "#snappay") {
            //     // handleContinuePay();
            window.location.hash = ""
        }

        // componentWillUnmount
        return () => {
            window.onhashchange = () => { }

            window.snap.hide();
        }
    }, [])

    useEffect(() => {
        let saved_id_package = null;
        let saved_quantity = 1;

        //get cart data that has been saved in localStorage
        let cart_data = localStorage.getItem("cart");
        if (cart_data !== null && cart_data !== undefined) {
            cart_data = JSON.parse(cart_data);

            if (cart_data.id_package !== null && cart_data.id_package !== undefined) {
                saved_id_package = cart_data.id_package;
            }
            if (cart_data.quantity !== null && cart_data.quantity !== undefined) {
                saved_quantity = cart_data.quantity;
            }
        }
        // console.log(saved_quantity)

        // console.log("MASUK LEWAT CLICK BELI DI CARD PACKAGE");
        if (service_package_selected !== null) {
            // console.log(service_package_selected)
            const { discount, price, id_package } = service_package_selected;

            setIdPackage(id_package);
            setPrice(discount !== 0 ? discount : price);
            if (id_package === saved_id_package) {
                // console.log(saved_quantity)        
                setQuantity(saved_quantity);
            } else {
                setQuantity(1);
            }

            // setTotalPrice(price);
        }
        // console.log("MASUK AFTER RELOAD")
        else {
            // if id_package empty, then redirect to home page
            if (saved_id_package === null) {
                // service_package_selected = 404;
                // console.log("404")
                history.push("/");
                return;
            }

            // console.log("ID_PACKAGE TERSIMPAN DI LOCAL STORAGE");

            // GET ALL SERVICE PACKAGE
            dispatch(fetchAllPackagesOfAllServices(id_token))
                .then((servicePackages) => {
                    // console.log("CARI PACKAGE DI  LIST SERVICE");
                    const index = servicePackages.findIndex((s) => s.id_package === saved_id_package);
                    if (index > -1) {
                        // console.log("ID_PACKAGE KETEMU DI SALAH SATU SERVICE");
                        dispatch(updateServicePackage(servicePackages[index]));
                    }
                    else {
                        // console.log("ID_PACKAGE YANG TERSIMPAN TIDAK TERDAFTAR");
                        history.push("/");
                    }

                })
                .catch((err) => {
                    // console.log(err.toString());
                    history.push("/");
                })
        }
    }, [service_package_selected])

    useEffect(() => {
        if (quantity === 0) return;

        // save cart data to localStorage
        const dataOrderCreate = {
            id_package: idPackage,
            quantity: quantity
        }
        localStorage.setItem("cart", JSON.stringify(dataOrderCreate))
    }, [quantity])

    const handleHashChange = (e) => {
        // console.log("handleHashChange")
        // console.log(window.location.hash)

        if (window.location.hash === "") {
            window.snap.hide();
        }
    }



    if (service_package_selected === null) {
        return (
            <LoadingFullPage info="Loading app" />
        );
    }

    return (
        <div id="payment-container">

            <div className="payment-header">
                <h3 style={{ margin: "auto" }}>Checkout</h3>
            </div>
            <div className="payment-content">

                <Row>
                    <Col className="payment-content-col" xs={12} xl={7} style={{ margin: "auto" }}>
                       
                        <div className="custom-card payment-card">
                        <NavLink activeClassName='active' to='/diktein'> &laquo; kembali</NavLink>

                            <OrderSummary
                                service_package_selected={service_package_selected}
                                price={price}
                                quantity={quantity}
                                setQuantity={setQuantity}
                            />
                            <button
                                className="btn btn-payment"
                                id="ordersummary_nextpayment"
                                onClick={handleContinuePay}
                                disabled={quantity <= 0 ? true : false}
                            >
                                Pilih Metode Pembayaran
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default withRouter(Cart);