//JS IMPORTS
// import { toastNotification } from "../components/ToastNotification/ToastNotification";
import { clearLocalStorage, clearCookie } from "../modules/utils";

/**
 * NOTICE
 * ONLY PUT ACTIONS AND REDUCERS THAT DEAL WITH NATS API 
 * IN THIS MODULE
 */

/** 
Declare the type of action as constant.
WHY?
    - Help with reducing typos 
    - Help with reducing bugs and mistakes
    - If you make typos and dispatch an undefined constant,
      the app will throw an error to alert the mistake. 
*/

// IMPORT ACTIONS FROM OUTSIDE SECTION
/** Put your imported actions here */

// ACTION TYPES SECTION
export const NATS_OPEN              = "NATS_OPEN";
export const NATS_CLOSE             = "NATS_CLOSE";
export const NATS_ONOPEN            = "NATS_ONOPEN";
export const NATS_ONCLOSE           = "NATS_ONCLOSE";
export const NATS_ONERROR           = "NATS_ONERROR";
export const NATS_CONNECTING        = "NATS_CONNECTING";
export const NATS_CONNECTED         = "NATS_CONNECTED";
export const NATS_DISCONNECT        = "NATS_DISCONNECT";
export const NATS_DISCONNECTED      = "NATS_DISCONNECTED";
export const NATS_MSG_SEND          = "NATS_MSG_SEND";
export const NATS_NO_DATA_RECEIVED  = "NATS_NO_DATA_RECEIVED";


// DEFAULT ACTION NATS SECTION

/** Call this to open NATS connection */
export const natsConnOpen = () => ({
    type: NATS_OPEN,
    nats_status: 'open'
});

/** Will be called when connecting to NATS */
export const natsConnConnecting = () => ({
    type: NATS_CONNECTING,
    nats_status: 'connecting'
});

/** Will be called when connected to NATS */
export const natsConnConnected = () => ({
    type: NATS_CONNECTED,
    nats_status: 'connected'
});

/** Call this to close NATS connection */
export const natsConnDisconnect = () => ({
    type: NATS_DISCONNECT,
    nats_status: 'disconnecting'
});

/** Will be called when disconnected from NATS */
export const natsConnDisconnected = () => ({
    type: NATS_DISCONNECTED,
    nats_status: 'disconnected'
});

// SIDE EFFECTS 

export const natsConnDisconnectAndLogout = () => (dispatch) => {
    // remove user's data from local storage
    clearCookie();
    clearLocalStorage();
    
    return dispatch(natsConnDisconnect());
}

/** Call this to send message via NATS */
export const natsMessageSend = (topic, msg) => {
    // console.log("====== OUTGOING DATA ======");
    // console.log(msg);
    // console.log("===========================");
    return {
        type: NATS_MSG_SEND,
        request_topic: topic,
        message: msg
    };

};

// INITIAL STATE SECTION
const initialState = {
    nats_status     : 'disconnected',
    request_topic   : '',
    message         : {}
};


// REDUCERS SECTION

// You must only write pure function when trying to build the reducer! 

export default function NATSDefaultReducers(state = initialState, action) {
    switch (action.type) {
        case NATS_OPEN:
            return {
                ...state,
                nats_status: action.nats_status
            }
        case NATS_CONNECTING:
            return {
                ...state,
                nats_status: action.nats_status
            }
        case NATS_CONNECTED:
            return {
                ...state,
                nats_status: action.nats_status
            }
        case NATS_DISCONNECT:
            return {
                ...state,
                nats_status: action.nats_status
            }
        case NATS_DISCONNECTED:
            return {
                ...state,
                nats_status: action.nats_status
            }
        case NATS_CLOSE:
            return {
                ...state,
                nats_status: action.nats_status
            }
        case NATS_MSG_SEND:
            return {
                ...state,
                request_topic   : action.request_topic,
                message         : action.message
            }
        case NATS_NO_DATA_RECEIVED:
            return {
                ...state
            }
        default:
            return { ...state }
    }
}
