import React, { Component, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import store, { history } from "../../store"

// FUNCTIONS
import { fetchServiceList, fetchServicePackage, updateServicePackage, reqServicePackage } from "../../reducers/ServiceReducers"
import { updateContinueToCheckoutFlag } from '../../reducers/BillingReducers';

// COMPONENTS
import { Row, Col, Button } from 'react-bootstrap'
import Loading from '../Loading';
import BillingStepHeader from '../BillingStepHeader/BillingStepHeader';

// STYLES
import "./BilingPackage.scss"

// Asset
import bg from '../../img/landing/card-bg.svg'


function BilingPackage(props) {
    const { landing } = props;

    const dispatch = useDispatch();

    const { id_token } = useSelector(state => state.UserManagementReducers);
    const { service_package, service_list } = useSelector(state => state.ServiceReducers)

    const [idService, setIdService] = useState(null);
    const [serviceName, setServiceName] = useState("");

    useEffect(() => {
        if (landing) {

            // // if (window.location.pathname === "/tts") {
            // //     id_service = "2";
            // //     this.setState({ service_name: "Text to Speech" })
            // // }
            // // else if (window.location.pathname === "/stt") {
            // //     id_service = "3";
            // //     this.setState({ service_name: "Speech To Text" })
            // // }
            // // else if (window.location.pathname === "/notulite") {
            // //     id_service = "4";
            // //     this.setState({ service_name: "Notulite" })
            // // }
            // // else if (window.location.pathname === "/notula") {
            // //     id_service = "4";
            // //     this.setState({ service_name: "Notula" })
            // // }
            // // else if (window.location.pathname === "/diktein") {
            // //     id_service = "4";
            // //     this.setState({ service_name: "Diktein" })
            // // }

            let service_name = history.location.pathname.replace('/', '');
            if (service_name.indexOf("notula") > -1) {
                service_name = "notula";
            } else if (service_name.indexOf("notulite") > -1) {
                service_name = "notulite";
            } else if (service_name.indexOf("diktein") > -1 || service_name.indexOf("dikte.in") > -1 || service_name == "") {
                service_name = "dikte.in";
            }
            // } else if ( service_name.indexOf("stt") > -1 ) {
            //     service_name = "stt";
            // } else if ( service_name.indexOf("tts") > -1 ) {
            //     service_name = "tts";
            // }

            setServiceName(service_name);

        } else {
            let id_service = parseInt(props.match.params.id_service);
            setIdService(id_service)
        }


        return () => {
            dispatch(reqServicePackage([]));
        }

    }, [])

    useEffect(() => {

        if (!Array.isArray(service_list))
            return;

        // console.log(serviceName)
        // console.log(service_list)

        if (landing) {
            const index = service_list.findIndex(s => s.name.toLowerCase() === serviceName.toLowerCase());
            if (index > -1) {
                let id_service = service_list[index].id_service;
                setIdService(id_service)
            }

        } else {
            const index = service_list.findIndex(s => s.id_service === idService);
            if (index > -1) {
                let service_name = service_list[index].name;
                // if ( service_name.toLowerCase() === "stt" ) {
                //     service_name = "Speech to Text";
                // } else if ( service_name.toLowerCase() === "tts" ) {
                //     service_name = "Text to Speech";
                // } else {
                //     service_name = service_name.charAt(0).toUpperCase() + service_name.slice(1);;
                // } 

                if (service_name.indexOf("notula") > -1) {
                    service_name = "notula";
                } else if (service_name.indexOf("notulite") > -1) {
                    service_name = "notulite";
                } else if (service_name.indexOf("diktein") > -1 || service_name.indexOf("dikte.in") > -1) {
                    service_name = "dikte.in";
                } else if (service_name.indexOf("stt") > -1) {
                    service_name = "stt";
                } else if (service_name.indexOf("tts") > -1) {
                    service_name = "tts";
                }

                setServiceName(service_name)
            }
        }

    }, [service_list])

    useEffect(() => {
        store.dispatch(fetchServiceList(id_token))
            .then(() => { })
            .catch(() => { });
    }, [idService, serviceName])

    useEffect(() => {
        if (idService !== null) {
            // console.log("idService: "+idService)

            // dispatch(reqServicePackage([]))
            store.dispatch(fetchServicePackage(idService))
                .then(() => { })
                .catch(() => { });
        }
    }, [idService])

    return (
        <div id="biling-menu-container">

            {!landing && <BillingStepHeader currentStep={1} totalStep={5} />}

            <Row className={service_package.length > 1 ? "landing-wrapper-2" : "landing-wrapper"} >
                {landing &&
                    <Col className="title-desc-col" xs={12} md={service_package.length > 1 ? 12 : 6} lg={service_package.length > 1 ? 12 : 6}>
                        <div className="package-title" id={service_package.length > 1 ? "center" : "side"}>
                            <h2 id="header">Harga yang tepat untuk Anda</h2>
                            <span id="desc">{serviceName} dapat diandalkan untuk proyek kecil dan besar</span>
                        </div>
                    </Col>
                }

                {service_package.length === 0 ?
                    <Loading />
                    :
                    (service_package.map((packages, i) => {
                        if (packages.name !== "Free") {
                            return (

                                <div key={i} className={landing && packages.length === 1 ? "margin-left" : "margin-auto"}>
                                    {/* <Fade> */}
                                    <CardBillingPackage
                                        key={i}
                                        id_token={id_token}
                                        id_package={packages.id_package}
                                        title={packages.name}
                                        services={(packages.description) ? packages.description : []}
                                        price={parseInt(packages.price)}
                                        discount={parseInt(packages.discount)}
                                        minimal={parseInt(packages.minimal)}
                                        unit={packages.unit_name !== null && packages.unit_name !== undefined ? packages.unit_name : "Unit"}
                                        selected_packages={packages}
                                        single={service_package.length > 1 || !landing ? false : true}
                                    />
                                    {/* </Fade> */}
                                </div>
                            )
                        }
                    }))
                }
            </Row>
        </div>
    )
}

class CardBillingPackage extends Component {
    redirectToUrl = async () => {
        await store.dispatch(updateContinueToCheckoutFlag(true));
        await store.dispatch(updateServicePackage(this.props.selected_packages));

        if (this.props.id_token === null || this.props.id_token === '') {
            history.push("/login");
        } else {
            history.push("/cart");
        }
    }

    priceFormat = (value) => {
        let price = value;
        // Trilyun
        if (price / 1000000000000 >= 1) {
            price = parseFloat((price / 1000000000000).toFixed(2)).toString() + " Trilyun";
        }
        // Milyar
        else if (price / 1000000000 >= 1) {
            price = parseFloat((price / 1000000000).toFixed(2)).toString() + " Milyar";
        }
        // Juta
        else if (price / 1000000 >= 1) {
            price = parseFloat((price / 1000000).toFixed(2)).toString() + " Juta";
        }
        // Ribu
        else if (price / 1000 >= 1) {
            price = parseFloat((price / 1000).toFixed(2)).toString() + " Ribu";
        }
        return price;
    }

    priceFormat2 = (value) => {
        let price = Math.floor(value/100)*100
        return new Intl.NumberFormat("id-UD", {
            style: "currency",
            currency: "IDR"
        }).format(price);

    }

    render() {
        let { title, price, unit, services, discount, minimal } = this.props;

        return (
            <div className={services.length < 5 ? "price-card" : "price-card extend"} id={this.props.single ? "single" : ""} style={{ backgroundImage: `url(${bg})` }}>
                {unit.toLocaleLowerCase().includes("callus") ? null : discount !== 0 ? <div className="discount-label">Harga diskon !</div> : null}
                <div className="title">{title}</div>
                {unit.toLocaleLowerCase().includes("callus") ? null :
                    discount !== 0 ?
                        <>
                            <div className="price">
                                {this.priceFormat2(discount) } <span>/ {unit.toLocaleLowerCase().includes("callus") ? unit.replace("_callus", "") : unit}</span>
                            </div>
                            <div className="price-stroke">
                                { this.priceFormat2(price)} (Harga Normal)
                            </div>
                        </>
                        :
                        <div className="price">
                            {this.priceFormat2(price * minimal)} <span>/ {unit.toLocaleLowerCase().includes("callus") ? unit.replace("_callus", "") : unit}</span>
                        </div>
                }
                <ul className="service-list-cont">
                    {services.map((value, i) => {
                        return <li key={i} className="service-list-item"><span>{value}</span></li>
                    })}
                </ul>
                {
                    unit.toLocaleLowerCase().includes("callus") ?
                        <a href={window._link_.contact} target="_blank" id="link-btn"><Button id="buy-btn">Hubungi Kami</Button></a>
                        :
                        <Button onClick={this.redirectToUrl} id="buy-btn">Beli Sekarang</Button>
                }

            </div>
        )
    }
}

export default BilingPackage;

