// JS IMPORTS
import React from 'react';

// Components
import { Pagination } from 'react-bootstrap';

// CSS IMPORTS
import './NcloudPagination.scss';


class NcloudPagination extends React.Component {
    constructor(props) {
		super(props);

		this.state = {
			goToPageVal: 1
		}
    }
    
    componentDidMount(){
        this.setState({
            goToPageVal: this.props.activePage
        })
    }
    
    componentWillReceiveProps(nextProps) {
        if ( this.props.activePage !== nextProps.activePage ) {
            this.setState({
                goToPageVal: nextProps.activePage
            })
        }
    }

    onArrowClick = (name) => {
        if ( name === "next" ) {
            this.setState({
                goToPageVal: (this.props.activePage+1)
            }, () => {
                this.props.onChange(this.state.goToPageVal);
            })
            
        } else if ( name === "prev" ) {
            this.setState({
                goToPageVal: (this.props.activePage-1)
            }, () => {
                this.props.onChange(this.state.goToPageVal);
            })
        } 
    }

    onInputChange = (event) => {
        let itemPerPage = 10;

        if ( typeof this.props.itemPerPage !== 'undefined' ) {
            itemPerPage = this.props.itemPerPage;
        }

        var totalPage = Math.ceil(this.props.totalItems/itemPerPage);

        if ( parseInt(event.target.value) === 0 ) {
            this.setState({
                goToPageVal: 1
            })
        } else if ( parseInt(event.target.value) > totalPage ) {
            this.setState({
                goToPageVal: totalPage
            })
        } else {
            this.setState({
                goToPageVal: parseInt(event.target.value)
            })
        }
    }

    onEnter = (event) => {
        if ( event.keyCode === 13 ) {
            this.props.onChange(this.state.goToPageVal);
        }
    } 

    onGoButtonClick = () => {
        if ( this.state.goToPageVal === 0 ) {
            this.setState({
                goToPageVal: 1
            }, () => {
                this.props.onChange(this.state.goToPageVal);
            })
        } else {
            this.props.onChange(this.state.goToPageVal);
        }
        
    }

    render() {
        let itemPerPage = 10;

        if ( typeof this.props.itemPerPage !== 'undefined' ) {
            itemPerPage = this.props.itemPerPage;
        }

        var activePage = this.props.activePage;
        var totalPage = Math.ceil(this.props.totalItems/itemPerPage);
        if ( isNaN(totalPage) ) {
            totalPage = 0;
        }

        const totalPageToShow = 3;

        var pages = [];
        for ( let i = 0; i < totalPage; i++ ) {
            pages.push(i+1);
        }
        if ( pages.length > totalPageToShow ) {
            var start = (activePage-Math.ceil(totalPageToShow/2));
            var end = (start+totalPageToShow);
            
            if ( start < 0 ) {
                start = 0;
            }

            if ( end > totalPage ) {
                start = totalPage-totalPageToShow;
            }

            pages = pages.splice(start, totalPageToShow)
        }

        return (
            <Pagination size="sm" className="ncloud-pagination">
                <Pagination.First disabled={activePage===1 || totalPage === 0}
                    onClick={() => this.props.onChange(1)}
                />
                <Pagination.Prev disabled={activePage===1 || totalPage === 0} 
                    onClick={() => this.props.onChange(activePage-1)}
                />
                { pages.map((i) => {
                    return (
                        <Pagination.Item key={"page-"+i} 
                            active={i===activePage}
                            onClick={() => this.props.onChange(i)}
                        >{i}</Pagination.Item>
                    )
                })}
                <Pagination.Next 
                    disabled={activePage===totalPage || totalPage === 0}
                    onClick={() => this.props.onChange(activePage+1)} 
                />
                <Pagination.Last disabled={activePage===totalPage || totalPage === 0}
                    onClick={() => this.props.onChange(totalPage)} 
                />
            </Pagination>

            // {/* <a
            //     className={
            //         "gotopage-arrow" + " " +
            //         ( totalPage === 0 || isNaN(totalPage) || activePage === 1 ?
            //         "disabled" : "" )
            //     }
            //     onClick={() => this.onArrowClick("prev")}
            // >
            //     <KeyboardArrowLeftIcon />
            // </a>
            // <input type="number" 
            //     className={
            //         "gotopage-input" + " " +
            //         ( totalPage === 0 || isNaN(totalPage) ?
            //         "disabled" : "" )
            //     }
            //     value={this.state.goToPageVal} 
            //     min="1"
            //     max={totalPage}
            //     onKeyUp={this.onEnter.bind(this)}
            //     onChange={this.onInputChange.bind(this)} 
            // />
            // <a
            //     className={
            //         "gotopage-arrow" + " " +
            //         ( totalPage === 0 || isNaN(totalPage) || activePage === totalPage ?
            //         "disabled" : "" )
            //     }
            //     onClick={() => this.onArrowClick("next")}
            // >
            //     <KeyboardArrowRightIcon />
            // </a>
            // <button
            //     className={
            //         "btn notula-btn-primary gotopage-button" + " " +
            //         ( totalPage === 0 || isNaN(totalPage) ?
            //         "disabled" : "" )
            //     }
            //     onClick={this.onGoButtonClick}>
            //     GO
            // </button> */}
        )
    }
}

export default NcloudPagination;