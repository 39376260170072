import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useKeycloak } from "@react-keycloak/web";
import { history } from "../../../store";

// Functions
import { clearCookie, clearLocalStorage } from '../../../modules/utils';
import { updateContinueToCheckoutFlag } from "../../../reducers/BillingReducers";
import { getServiceNameByAcronym, getServicePathByAcronym } from '../../../utils/services';
import { setAllTutorialState } from '../../../utils/tutorials';

// Components
import { Dropdown, Accordion, Card } from 'react-bootstrap';
import HeadMenu from './HeadMenu';
import SideMenu from './SideMenu';

// Styles
import "./SideMenu.scss"

// ETC
import logo from "../../../img/logo_diktein.svg"
import ava from "../../../img/ava.svg"
import arrowDown from "../../../img/arrow-down.svg"


function HeaderOrSidebar({isMobile, isDesktop, ...props}) {
    return (
        <>
            { isMobile &&
                <HeadMenu {...props} Logo={Logo} Content={Content} />
            }
            { isDesktop && 
                <SideMenu {...props} Logo={Logo} Content={Content} />
            }
        </>
    )
}

function Logo() {
    return (            
        <div className="logo-header" style={{cursor:"pointer"}} onClick={() => history.push("/")}>
            <img src={logo} alt="logo-bahasakita" />
            {/* <div>CONSOLE</div> */}
        </div>
    )
}

function Content({isMobile, isDesktop, callbackTutorialStateChange=()=>{}}) {
	const { userDetail } = useSelector(state => state.UserManagementReducers);
	const { service_list } = useSelector(state => state.ServiceReducers);
    
    const dispatch = useDispatch();

	const { keycloak } = useKeycloak();

    const [currentEventKey, setCurrentEventKey] = useState(null);

    const handleShowTutorialClick = () => {
        // console.log("handleShowTutorialClick")
        setAllTutorialState(true);
        callbackTutorialStateChange(true)        
    }

    const handleLogout = () => {
        var logoutOptions = { redirectUri : process.env.REACT_APP_DIKTEIN_URL }
        keycloak.logout(logoutOptions);

        // keycloak.logout();
        
        clearCookie();
        clearLocalStorage();

        dispatch(updateContinueToCheckoutFlag(false));
        // window.location.reload();
    }

    // console.log(currentEventKey)

    let service_path = history.location.pathname;
    // console.log(service_path)

    // get username from userDetail data
    let username = "";
    let email = "";
    if ( userDetail !== null ) {
        if ( userDetail.id_user !== undefined ) {
            username = userDetail.id_user;
        }if ( userDetail.email !== undefined ) {
            email = userDetail.email;
        }
    }

    let platforms   = [];
    let developers  = [];
    
    if ( Array.isArray(service_list) ) {
        platforms = service_list.filter(({name}) => !name.toLowerCase().includes("stt") && !name.toLowerCase().includes("tts") && !name.toLowerCase().includes("text-translate")&& !name.toLowerCase().includes("audio-translate"));
        platforms = platforms.map(({name, ...restprops}) => ({...restprops, name: getServiceNameByAcronym(name), path: getServicePathByAcronym(name)}));

        // developers = service_list.filter(({name}) => name.toLowerCase().includes("stt") || name.toLowerCase().includes("tts")|| name.toLowerCase().includes("text-translate")|| name.toLowerCase().includes("audio-translate"));
        developers = service_list.filter(({name}) => name.toLowerCase().includes("tts")|| name.toLowerCase().includes("text-translate")|| name.toLowerCase().includes("audio-translate"));   
        developers = developers.map(({name, ...restprops}) => ({...restprops, name: getServiceNameByAcronym(name), path: getServicePathByAcronym(name)}));
    }

    // console.log(service_list)
    // console.log(platforms)
    // console.log(developers)

    return (
        <>
            <div className="menu">
                <Accordion onSelect={(eventKey) => setCurrentEventKey(eventKey)}>
                    {/* <Card>
                        <Card.Header>
                            <button>
                                <NavLink activeClassName='active' exact to="/">● Home</NavLink>
                            </button>
                        </Card.Header>
                    </Card> */}
                    <Card className={currentEventKey === "1" ? "card-active" : ""}>
                        {/* <Card.Header>
                            <Accordion.Toggle as="button" eventKey="1">
                                <a className={(platforms.findIndex(({path}) => service_path === path) > -1 ? "active" : "")}>
                                    ● Platform <img className="arrow-icon" src={arrowDown} />
                                </a>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body> */}
                                { platforms.map((service, i) => 
                                    <button key={service.name}><NavLink activeClassName='active' to={service.path}> ● Beli Paket Utama</NavLink></button>
                                ) }
                            {/* </Card.Body>
                        </Accordion.Collapse> */}
                    </Card>
                    <Card className={currentEventKey === "2" ? "card-active" : ""}>
                        <Card.Header>
                            <Accordion.Toggle as="button" eventKey="2">
                                <a className={(developers.findIndex(({path}) => service_path === path) > -1 ? "active" : "")}>
                                    ● Beli Paket Lain <img className="arrow-icon" src={arrowDown} />
                                </a>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                { developers.map((service, i) => 
                                    <button key={service.name}><NavLink activeClassName='active' to={service.path}> ● {service.name}</NavLink></button>
                                ) }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    {/* <Card>
                        <Card.Header>
                            <button>
                                <NavLink activeClassName='active' to="/documentation">● Documentation</NavLink>
                            </button>
                        </Card.Header>
                    </Card> */}
                    <Card>
                        <Card.Header>
                            <button>
                                <NavLink activeClassName='active' to="/your-services">● Layanan Anda</NavLink>
                            </button>
                        </Card.Header>
                    </Card>
                </Accordion>
            </div>
            <div className="account">
                <Dropdown drop={isMobile?"down":"up"}>
                    <Dropdown.Toggle as="button" id="profile-btn">
                        <img src={ava} alt="user"/>
                        <div className="profile-info">
                            { username.replace(/\s+/g, '') !== "" &&
                                <span className="username">{username}</span>
                            }
                            { email.replace(/\s+/g, '') !== "" &&
                                <span className="email">{email}</span>
                            }
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item as={NavLink} to="/account">Profil</Dropdown.Item>
                        <Dropdown.Item as={NavLink} to="/transactions">Riwayat Transaksi</Dropdown.Item>
                        {/* <Dropdown.Item onClick={handleShowTutorialClick}>Lihat Tutorial </Dropdown.Item> */}
                        {/* <Dropdown.Item as={NavLink} to="/feedback">Send Feedback</Dropdown.Item> */}
                        <Dropdown.Item onClick={handleLogout}>Keluar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default HeaderOrSidebar;