import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk';
// import logger from 'redux-logger';
import { 
  createBrowserHistory, 
  // createHashHistory 
} from 'history';

import createRootReducer from './reducers';

import NATSMiddleware from './middleware/NATSMiddleware';
import WebSocketMiddleware from './middleware/WebSocketMiddleware';

export const history = createBrowserHistory()
// Use this if you want to use routing with hash
// export const history = createHashHistory();

const initialState = {}
const enhancers = []
const middleware = [
  thunk,
  // logger,
  routerMiddleware(history),
  NATSMiddleware,
  WebSocketMiddleware
]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.devToolsExtension

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(
  createRootReducer(history),
  initialState,
  composedEnhancers
)

export default store