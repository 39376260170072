import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col, ProgressBar } from "react-bootstrap";
import './Usecase.scss'
import arright from '../../img/landing/arrow-right.svg'
import BKAudioPlayer from '../../page/landingpage/BKAudioPlayer/BKAudioPlayer';
// import news from './Audio/berita.wav'
// import pidato from './Audio/pidato.wav'
// import cerita from './Audio/cerita.wav'
// import asisten from './Audio/asisten.wav'
import percakapan from './Audio/percakapan.mp3'

function UsecaseSTT() {
    const [indexselected, setIndexSelected] = useState(0);
    const [idx, setIdx] = useState(0);
    const [textresult, setLongtext] = useState([]);
    const [audioEnd, setAudioEnd] = useState(false);
    const [play, setPlay] = useState(false);
    const ttslist = [
        {
            title: "Percakapan",
            text: "Melakukan transkripsi otomatis dari percakapan",
            longtext: ["","[cs] Bapak. ", "dengan saya"," mustika, ", "dengan"," bapak? ", "[pelanggan] saya"," Bayu ", " [cs] Apakah ada yang ", "bisa saya bantu pak? ", "[pelangan] Saya ingin ", "membuat ", "rekening ","tabungan."," [cs] baik bapak ", "silakan mengisi ", "formulir ini ", "terlebih dahulu"," ya pak ", "[pelanggan] baik ", "ini dia ", "[cs] baik ", "terima kasih pak ", "boleh ", "saya lihat ", "kartu identitasnya ", "pak? ", "[pelanggan] boleh ", "tentu saja ", "[cs] bisakah ", "anda tolong"," ketikkan ","kata sandi yang " ,"anda inginkan ", "[pelanggan] ya ", "[cs] tolong sekali lagi ", "ya pak", " dan tolong tanda tangan ","di sini"," juga pak","[pelanggan] sudah ", "[cs] baik"," bapak ", "rekening tabungannya ", "sudah siap ", "untuk penggunaannya ", "dapat ", "dimulai dari ", "hari ini, ", "ini buku tabungan ", "dan kartu atm anda ", "apakah ada yang ", "ingin ditanyakan untuk lebih lanjut pak? ", "[pelanggan] tidak ", "terima kasih ", "saya rasa saya sudah", " paham cara menggunakannya ", "[cs] baik bapak, terima kasih ", "telah menggunakan ","layanan kami ", "semoga hari anda menyenangkan"],
            audio: percakapan
        },
    ];

    //timer
    const [time, setTime] = useState(0);
    const timer = useRef(null);
    // const startStopTimer = (e) => {

    //     if (!timer.current && e) {
    //     timer.current = setInterval(
    //         () => { 
    //             setTime(currentTime => currentTime + 1);                
    //         }, 
    //         1000);
    //     } else {
    //     clearInterval(timer.current);
    //     timer.current = null;
    //     console.log("timer pause")
    //     }
    // };

    useEffect(() => {
        // console.log(idx);
        // console.log(time);
        // console.log(ttslist[indexselected].longtext.length);
        pushText(time);
        setIdx(time);      
    }, [time]);

    useEffect(() => {    
        if (play) {
            if (!timer.current) {
                timer.current = setInterval(
                    () => { 
                        setTime(currentTime => currentTime + 1);                
                    }, 
                    1000);
                } 
        } 
        else {
            // pause interval when play false
            clearInterval(timer.current);
            timer.current = null;
            // console.log("timer pause")
        } 
    }, [play]);

    function playAnimation(e) { 
        //set play true animation when audio play true       
        if (e) {
            // console.log(e);
            setPlay(e);
        }
        //set play false animation when audio play false      
        if (!e) {
            // console.log(e);
            setPlay(e);
        }
        // reset longtext and time when audio end and play false
        if (e && audioEnd) {
            setAudioEnd(false);
            setLongtext([]);
            setTime(0);     
            // console.log("masuk time reset");
            // startStopTimer(e);
        }
    }

    function pushText(e) {
        textresult.push(ttslist[indexselected].longtext[e]);
        setLongtext(textresult);
    }
    function audioEnded(e) {
        setAudioEnd(true);
     }

    return (
        <div className="side-u-wrapper">
            <div className="side-u-title">Usecase Penggunaan STT</div>
            <Row className="landing-wrapper">
                <Col xs={12} md={6}>
                    <div className="side-u-container">
                        {
                            ttslist.map((item, i) => {
                                return (
                                    <div key={item.title + i} className={i + 1 === ttslist.length ? "list-item" : "list-item border-b"} onClick={() => setIndexSelected(i)}>
                                        {i === indexselected ? <img src={arright} alt="arrow" id="arrow" /> : null}
                                        <h5 className={i === indexselected ? "title active" : "title"}>{item.title}</h5>
                                        <p className="text-p">{item.text}</p>
                                        <div className="audio-play-wrap">
                                            {
                                                i === indexselected ?
                                                    <BKAudioPlayer
                                                        demo={true}
                                                        audioFile={item.audio}
                                                        onAudioPlayStateChange={(e) => playAnimation(e)}
                                                        onAudioEnded={(e) => audioEnded(e)}
                                                    /> : null
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Col>
                <Col xs={12} md={6}>
                    <div className="side-ur-container">
                        <div className="side-ur-textarea-wrapper">
                            {/* <textarea
                                id="side-ur-textarea"
                                type="text"  
                                value={ttslist[indexselected].longtext}        
                            />                             */}
                            {/* <textarea> */}
                            {/* {time} */}
                            {
                                textresult.map((item, i) => {
                                    return (
                                        <span key={i}>{item}</span>
                                    )
                                })
                            }
                            {/* </textarea> */}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>

    )
}
export default UsecaseSTT;