import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import NATSDefaultReducers from './NATSDefaultReducers';
import WebSocketDefaultReducers from './WebSocketDefaultReducers';
import UserManagementReducers from './UserManagementReducers';
import TTSReducers from './TTSReducers';
import STTReducers from './STTReducers';
import TutorialReducers from './TutorialReducers';
import BillingReducers from './BillingReducers';
import ServiceReducers from './ServiceReducers';
import UserPackageReducers from './UserPackageReducers';

const createRootReducer = (history) => combineReducers({
	router: connectRouter(history),
	NATSDefaultReducers,
	WebSocketDefaultReducers,
	UserManagementReducers,
	TTSReducers,
	STTReducers,
	BillingReducers,
	ServiceReducers,
	TutorialReducers,
	UserPackageReducers
});

export default createRootReducer