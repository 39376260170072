import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Pagination } from 'react-bootstrap';
import { useTable, useSortBy, usePagination } from 'react-table'
import { UnfoldMore, ExpandMore, ExpandLess } from "@material-ui/icons"


import '../NcloudPagination/NcloudPagination.scss';

const Styles = styled.div`
    table {
        width: 100%;
        border-spacing: 0;
        border: none;
        margin-bottom: 20px;

        .sort-icon-container {
            position: relative;
            padding-right: 26px;

            .sort-icon {
                position: absolute;
                top: 2px;
                font-size: 16px;
                margin-left: 10px;
            }
        } 

        .more-btn {
            background-color: transparent;
            border: none;

            .icon {
                color: #2689ff;
            }
        }

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }

            &:hover {
                td {
                    background-color: rgba(255, 57, 57, 0.05);
                }
            }
        }

        tbody {
            tr {
                cursor: pointer;
            }
        }

        th,
        td {
            margin: 0;
            padding: 20px;
            border: none;

            :last-child {
                border-right: 0;
            }
        }
        th {
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.25px;
            text-align: left;
            color: rgba(24, 24, 26, 0.7);
        }

        td {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #7b818c;
        }
    }

    .pagination {
        display: flex;
        align-items: center;
        padding: 0.5rem;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.25px;
        text-align: left;
        color: rgba(24, 24, 26, 0.7);
    }
`

function Table({ columns, data, limit, activePage, onRowClick }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
    
        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: limit }
        },
        useSortBy,
        usePagination
    )

    useEffect(() => {  
        gotoPage(activePage-1)
    }, [activePage])

    // We don't want to render all 2000 rows for this example, so cap
    // it at 20 for this use case
    // const firstPageRows = rows.slice(0, 20)

    // console.log(columns)
    // console.log(data.length)
    // console.log(pageSize)

    return (
        <>
            <table {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => { 
                        return (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            { column.canSort &&
                                <span className="sort-icon-container">
                                    { column.isSorted ? 
                                        column.isSortedDesc ? 
                                            <ExpandMore className="sort-icon" />
                                            : 
                                            <ExpandLess className="sort-icon" />
                                        : 
                                        <UnfoldMore className="sort-icon" style={{opacity: 0.25}} />
                                    }
                                </span>
                            }
                            </th>
                        )
                    })}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    { page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}
                                onClick={() => onRowClick(row.original.id_transaction)}
                            >
                                { row.cells.map(cell => 
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ) }
                            </tr>
                        )
                    }) }
                </tbody>
            </table>
            {/* 
                Pagination can be built however you'd like. 
                This is just a very basic UI implementation:
            */}
            {/* <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        min={1} max={pageCount}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                        </option>
                    ))}
                </select>
            </div> 
            <div>Showing the first 20 results of {rows.length} rows</div> */}
            {/* <Pagination size="sm" className="ncloud-pagination">
                <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                { Array(pageCount).fill().map((i, index) => {
                    const pageNum = index+1;
                    return (
                        <Pagination.Item key={"page-"+index} 
                            active={index===pageIndex}
                            onClick={() => gotoPage(index)}
                        >{pageNum}</Pagination.Item>
                    )
                })}
                <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
            </Pagination> */}
            <br />
        </>
    )
}

function CustomTable(props) {
    const { columns, data } = props;

    const columns2 = React.useMemo(
        () => columns, []
    )

    const data2 = React.useMemo(
        () => data, []
    )

    return (
        <Styles>
            <Table {...props} columns={columns2} data={data2} />
        </Styles>
    )
}

export default CustomTable
