/**
 * NOTICE! 
 * ONLY PUT ACTIONS AND REDUCERS THAT DEAL WITH THE INCOMING MESSAGES 
 * FROM SERVER IN THIS MODULE
 */

import ReactDOM from 'react-dom';
import store from '../store';

// import { toastNotification } from '../components/ToastNotification/ToastNotification';

// IMPORT ACTIONS TO BE DISPATCHED FROM OUTSIDE SECTION
// import {
//     updateFetchingData
// } from '../reducers/GlobalReducers';

import {
    updateFetchingDataStream,
    reqInitSendBlobChange,
    rcvStartStream,
    rcvSendAudioStream,
    rcvStopStream,
    updateStreamStatus
} from '../reducers/STTReducers';

import {
    clearStreamResponseTimeout
} from '../modules/StreamResponseTimeout';

// import {
//     removeResponseTimeout
// } from '../modules/ResponseTimeout';

import { clearAudioBlobs, stopStreaming } from '../page/landingpage/StreamingRecorder';

import { NC_RECORD_START, NC_RECORD_POST, NC_RECORD_STOP } from './NATSTopics';
// import { NATSRecordConnectionMonitorUnsubscribers } from './NATSMethods';

// import { toastNotification } from '../components/ToastNotification/ToastNotification';

// IMPORT ACTIONS TO BE DISPATCHED FROM OUTSIDE SECTION

import { NC_TTS_REQ } from './NATSTopics';
import { fetchRcvTts } from '../reducers/TTSReducers';

// Received data from nats

// THE SWITCHER
export var NATSProtocolIncomingSwitcher = (topic, response) => {
    if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {    
        console.log("====== INCOMING DATA ======");
        console.log(topic)
        console.log(response)
        console.log("===========================");
    }

    var msg = JSON.parse(response);

    const target = document.getElementById('popup-notification-container');
    if ( target !== null ) {
        if ( target.innerHTML !== "" ) {    
            ReactDOM.unmountComponentAtNode(target);
        }
    }

    clearStreamResponseTimeout();
    store.dispatch(updateFetchingDataStream("receive_" + topic, msg));
    
    switch (topic) {            
        case NC_TTS_REQ:
            // if ( msg.audio !== undefined && msg.audio !== null && msg.audio !== "" ) {
            //     msg.status = "success";
            // } else {
            //     msg.status = "failed";
            // }
            store.dispatch(fetchRcvTts(msg))
                .then(()=>{})
                .catch(()=>{});
            break;
            
        case `${NC_RECORD_START}`:
            // {
            //     "status": "",
            //     "error": ""
            // }
            
            switch (msg.status) {
                case 'success':
                    store.dispatch(rcvStartStream(msg));
                    break;
                    
                case 'failed':
                    store.dispatch(updateStreamStatus(""));

                    clearAudioBlobs();
                    // stopStreaming();
                    
                    store.dispatch(reqInitSendBlobChange(true));

                    // toastNotification(
                    //     'error',
                    //     'Gagal memulai transkrip meeting. Silakan refresh halaman ini dan ikuti ulang meeting.',
                    //     msg.error
                    // );
                    break;

                default:
                    // status undefined
                    break;
            }
            break;
        
        // case `app.notula.${process.env.REACT_APP_SYSTEM_STATUS}.record.audio.post.${store.getState().STTReducers.meetingTopic}`:
        case `${NC_RECORD_POST}`: //.${store.getState().STTReducers.key}`:
            // {
            //     "status": "",
            //     "error": ""
            // }
            
            switch (msg.status) {
                case 'success':
                    // Send next audio chunk
                    store.dispatch(rcvSendAudioStream());
                    
                    // NATSRecordConnectionMonitorUnsubscribers();
                    break;
                    
                case 'failed':
                    store.dispatch(updateStreamStatus(""));

                    clearAudioBlobs();
                    stopStreaming();
    
                    store.dispatch(reqInitSendBlobChange(true));
                
                    // toastNotification(
                    //     'error',
                    //     'Gagal mengirim audio untuk transkrip. Silakan refresh halaman ini dan ikuti ulang meeting.',
                    //     msg.error
                    // );
                    
                    // NATSRecordConnectionMonitorUnsubscribers();
                    break;

                default:
                    // status undefined
                    break;
            }
            break;

        // case `app.notula.${process.env.REACT_APP_SYSTEM_STATUS}.record.audio.stop`:
        case `${NC_RECORD_STOP}`: //.${store.getState().STTReducers.key}`:
            // {
            //     "status": "",
            //     "error": ""
            // }
            
            switch (msg.status) {
                case 'success':
                    store.dispatch(rcvStopStream());
                    break;
                    
                case 'failed':
                    store.dispatch(updateStreamStatus(""));

                    clearAudioBlobs();
                    // stopStreaming();
    
                    store.dispatch(reqInitSendBlobChange(true));
                    
                    break;

                default:
                    // status undefined
                    break;
            }
            break;

        default:
            // console.log("!----UNKNOWN COMMAND----!");
            // console.log("!-----------------------!");
            break;
    }
};
