export function setTutorialState(_path, _state) {
    localStorage.removeItem("all_tutorials");

    let tutorials = localStorage.getItem("tutorials");
    // console.log(tutorials)
    if ( tutorials !== null && tutorials !== undefined ) {
        tutorials = JSON.parse(tutorials);
        const index = tutorials.findIndex(({path}) => path === _path);
        if ( index > -1 ) {
            tutorials[index] = {path: _path, state: _state};
        } else {
            tutorials.push({path: _path, state: _state});
        }
    } else {
        tutorials = [{path: _path, state: _state}]
    }
    // console.log(tutorials)
    localStorage.setItem("tutorials", JSON.stringify(tutorials));
}

export function getTutorialState(_path) {
    let all_tutorials = localStorage.getItem("all_tutorials");
    if ( all_tutorials !== null && all_tutorials !== undefined ) {
        return all_tutorials === "true";
    }

    let tutorials = localStorage.getItem("tutorials");
    // console.log(tutorials)
    if ( tutorials !== null && tutorials !== undefined ) {
        tutorials = JSON.parse(tutorials);
        const index = tutorials.findIndex(({path}) => path === _path);
        if ( index > -1 ) {
            return tutorials[index].state;
        } else {
            return true;
        }
    } else {
        return true;
    }

}

export function setAllTutorialState(_state) {
    localStorage.removeItem("tutorials");

    if ( _state ) {
        localStorage.removeItem("all_tutorials");
    } else {
        localStorage.setItem("all_tutorials", false);
    }
}