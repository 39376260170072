import React from 'react';
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'

import NotificationBell from '../../components/NotificationBell/NotificationBell';
import HeaderOrSidebar from './Menu/HeaderOrSidebar';
import YourServices from "./Board/YourServices/YourServices"
import Services from "./Board/Services/Services"
import Tutorial from '../Tutorial/Tutorial';
import YourServiceDetail from "./Board/YourServiceDetail/YourServiceDetail"
import TransactionHistory from '../TransactionHistory/TransactionHistory';
import Documentation from './Board/Documentation/Documentation';
import ServiceDetail from './Board/ServiceDetail/ServiceDetail';
import Notification from './Notification/Notification';

import "./Dashboard.scss"
import WalkthroughServiceDetail from '../../components/Walkthrough/WalkthroughServiceDetail';
import WalkthroughYourServices from '../../components/Walkthrough/WalkthroughYourServices';
import WalkthroughYourServiceDetail from '../../components/Walkthrough/WalkthroughYourServiceDetail';
import WalkthroughServices from '../../components/Walkthrough/WalkthroughServices';
import { useState } from 'react';
import { getTutorialState, setAllTutorialState, setTutorialState } from '../../utils/tutorials';
import { useEffect } from 'react';
import Account from './Board/Account/Account';


function Dashboard() {
    const location = useLocation();
    const pathname = location.pathname;

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
    const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })

    const [_tutorialState, _setTutorialState] = useState(false)
    const [isFirstNotif, setIsFirstNotif] = useState(false);
    // const isFirstNotif = true

    const [isNotifDropdownShown, setIsNotifDropdownShown] = useState(false);


    useEffect(() => {
        _setTutorialState(getTutorialState(pathname));
    }, [pathname])

    const callbackTutorial = (data) => {
        // console.log(data)
        const { action, index, status, type, step } = data;
        // Tutorial ended
        if (type === "tour:end") {
            setTutorialState(pathname, false);
            _setTutorialState(false);
        }

        // Tutorial skipped
        if (status === "skipped") {
            setAllTutorialState(false);
            _setTutorialState(false);
        }
    }

    const callbackNotificationBell = ({ show, isFirstNotif }) => {
        setIsNotifDropdownShown(show)
        setIsFirstNotif(isFirstNotif)
    }

    const callbackTutorialStateChange = (bool) => {
        setAllTutorialState(true);
        _setTutorialState(bool);
    }

    const routes = [
        // {
        //     path: "/",
        //     Component: Services,
        //     Tutorial: WalkthroughServices,
        // }, 
        {
            path: "/",
            Component: ServiceDetail,
            Tutorial: WalkthroughServiceDetail,
        }, 
        {
            path: "/notula",
            Component: ServiceDetail,
            Tutorial: WalkthroughServiceDetail,
        }, {
            path: "/notulite",
            Component: ServiceDetail,
            Tutorial: WalkthroughServiceDetail,
        }, {
            path: "/diktein",
            Component: ServiceDetail,
            Tutorial: WalkthroughServiceDetail,
        }, {
            path: "/stt",
            Component: ServiceDetail,
            Tutorial: WalkthroughServiceDetail,
        }, {
            path: "/tts",
            Component: ServiceDetail,
            Tutorial: WalkthroughServiceDetail,
        }, {
            path: "/text-translate",
            Component: ServiceDetail,
            Tutorial: WalkthroughServiceDetail,
        }, {
            path: "/audio-translate",
            Component: ServiceDetail,
            Tutorial: WalkthroughServiceDetail,
        },{
            path: "/your-services",
            Component: YourServices,
            Tutorial: WalkthroughYourServices,
        }, {
            path: "/your-services/:id_user_package/:service_name/:name",
            Component: YourServiceDetail,
            Tutorial: WalkthroughYourServiceDetail,
        }, {
            path: "/transactions",
            Component: TransactionHistory,
            Tutorial: null,
        }, {
            path: "/documentation",
            Component: Documentation,
            Tutorial: null,
        }, {
            path: "/tutorial",
            Component: Tutorial,
            Tutorial: null,
        }, {
            path: "/notification",
            Component: Notification,
            Tutorial: null,
        }, {
            path: "/account",
            Component: Account,
            Tutorial: null,
        },
    ];

    return (
        <div className={"dashboard " + (isMobile ? "mobile" : "")}>
            <HeaderOrSidebar
                isMobile={isMobile}
                isDesktop={isDesktop}
                callbackTutorialStateChange={callbackTutorialStateChange}
            />

            <div className="main">

                {/* <ModalVerifyEmail 
                    closeButton={true}
                    show={showModalVerifyEmail}
                    onHide={() => setShowModalVerifyEmail(false)}
                    handleChangeEmailClick={handleChangeEmailClick}
                />
                <ModalChangeEmail 
                    show={showModalChangeEmail}
                    onHide={() => setShowModalChangeEmail(false)}
                    onCancel={handleCancelChangeEmailClick}
                />
                <ModalConfirmPassword 
                    show={showModalConfirmPassword} 
                    // show={true} 
                    onHide={() => setShowModalConfirmPassword(false)} 
                    onSubmit={onConfirmPassword}
                    loading={loading}
                /> */}

                <NotificationBell callback={callbackNotificationBell} />

                <Switch>
                    {routes.map(({ path, Component, Tutorial }) =>
                        <Route key={path} exact path={path}
                            // component={component} 
                            render={(props) =>
                                <>
                                    <Component {...props} />
                                    {Tutorial !== null && _tutorialState && getTutorialState(pathname) &&
                                        // tutorial    
                                        <Tutorial
                                            {...props}
                                            callback={callbackTutorial}
                                            isNotifDropdownShown={isNotifDropdownShown}
                                            showTutorialNotif={isFirstNotif}
                                            service_acr={props.match.params.service_name}
                                        />
                                    }
                                </>
                            }
                        />
                    )}
                    <Redirect from="/:someroute" to="/" />
                </Switch>

            </div>
        </div>
    )
}

export default Dashboard;