import React, { Component } from "react";
import store, { history } from "../../../store";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// Functions

// components
import { Col, Row, Spinner } from "react-bootstrap";
import ParticlesBackground from '../../../components/ParticlesBackground/ParticlesBackground';

// Styles
import '../OnBoard.scss'

// ETC
import eye from "../../../img/eye.svg"
import eyeoff from "../../../img/eye-closed.svg"
import warning from "../../../img/warning.svg"
import { fetchRegister } from "../../../reducers/UserManagementReducers";
import { updateContinueToCheckoutFlag } from "../../../reducers/BillingReducers";


class RegisterPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            username: "",
            firstname: "",
            lastname: "",
            password: "",
            password_confirm: "",

            show_password: false,
            show_password_confirm: false,

            isLoading: false,
            showError: false,
            errorMessage: "",
            showRegister: true,

            registerSuccess: false
        };

        this.ref1 = React.createRef();
        this.ref2 = React.createRef();
        this.ref3 = React.createRef();
        this.ref4 = React.createRef();
        this.ref5 = React.createRef();
        this.ref6 = React.createRef();

    }

    componentWillUnmount() {
        // store.dispatch(updateContinueToCheckoutFlag(false));
    }

    validateEmail = () => {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([a-zA-Z0-9-\\.]+)$/;
        if (reg.test(this.state.email) === false) {
            // alert('Invalid Email Address');
            this.setState({
                isLoading: false,
                showError: true,
                errorMessage: "alamat email tidak sesuai"
            });
            return false;
        }

        return true;
    }

    validatePassword = () => {
        if (this.state.password !== this.state.password_confirm) {
            this.setState({
                isLoading: false,
                showError: true,
                errorMessage: "password tidak sama"
            });
            return false;
        }

        return true;
    }

    validateUsername = () => {
        /* 
            Usernames can only have: 
            - Lowercase Letters (a-z) 
            - Numbers (0-9)
            - Dots (.)
            - Underscores (_)
        */
        const res = /^[a-z0-9_\.]+$/.exec(this.state.username);
        const valid = !!res;
        // return valid;

        if (!valid) {
            // alert('Invalid Email Address');
            this.setState({
                isLoading: false,
                showError: true,
                errorMessage: "Usernames can only have: Lowercase Letters (a-z), Numbers (0-9), Dots (.), Underscores (_)"
            });
            return false;
        }

        return true;
    }

    handleUsernameChange = ({ target }) => {
        const username = target.value.replace(/\s+/g, '').toLowerCase();

        this.setState({
            showError: false,
            isLoading: false,
            username: username
        }, () => {
            this.validateUsername();
        });

        // if ( username.length > 0 ) {
        //     this.setState({ 
        //         showError: false,            
        //         isLoading: false,
        //     });
        // }
    };

    handleInputChange = ({ target }) => {
        // console.log("handleInputChange")

        let value = target.value.replace(/\s/g, ''); //.toLowerCase();

        if (target.name === "email") {
            value = value.toLowerCase();
        }

        this.setState({
            showError: false,
            isLoading: false,
            [target.name]: value
        });

        // if ( value.length > 0 ) {
        //     this.setState({           
        //         isLoading: false,
        //         showError: false,  
        //     });
        // }
    };

    toggleShowPass = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            [e.target.name]: !this.state[e.target.name]
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.state.isLoading) return;

        if (!this.validateEmail()) return;
        if (!this.validateUsername()) return;
        if (!this.validatePassword()) return;

        let email = this.state.email;
        // let username         = (this.state.firstname+" "+this.state.lastname).trim();
        let username = this.state.username;
        let firstname = this.state.firstname;
        let lastname = this.state.lastname;
        let password = this.state.password;
        let password_confirm = this.state.password_confirm;

        if (
            email.replace(/\s+/g, '') === "" ||
            username.replace(/\s+/g, '') === "" ||
            firstname.replace(/\s+/g, '') === "" ||
            lastname.replace(/\s+/g, '') === "" ||
            password.replace(/\s+/g, '') === "" ||
            password_confirm.replace(/\s+/g, '') === ""
        ) {
            this.setState({
                isLoading: false,
                showError: true,
                errorMessage: "harap isi semua kolom"
            });
            return;
        }

        if (this.state.password.length < 6) {
            this.setState({
                isLoading: false,
                showError: true,
                errorMessage: "kata sandi minimal 6 karakter"
            });
            return;
        }

        this.setState({
            isLoading: true,
            showError: false
        });

        const data = {
            email,
            username,
            firstname,
            lastname,
            password
        }

        store.dispatch(fetchRegister(data))
            .then((res) => {
                if (res.status === "success") {
                    // console.log(res)
                    // window.location.reload()

                    // if ( this.props.continue_to_checkout_flag ) {
                    //     history.push("/cart")
                    // } else {
                    //     history.push("/");
                    // }

                    this.setState({
                        registerSuccess: true
                    })

                }
            })
            .catch((err) => {
                this.setState({
                    isLoading: false,
                    showError: true,
                    errorMessage: err.toString()
                });
            })
    }

    AlertDismissibleError() {
        let showError = this.state.showError;

        if (showError) {
            return (
                <p className="error-alert" dismissible="true">
                    <img src={warning} alt="error-dismissible" />  {this.state.errorMessage}
                </p>
            );
        }
    }

    RegisterForm() {
        let showRegister = this.state.showRegister;

        if (showRegister) {
            return (
                <>
                    <div className="form-wrapper">
                        <p className="onboard-header"><b className="text-style-1">Register to <br />Diktein</b></p>

                        {this.AlertDismissibleError()}

                        <p className="onboard-span">Buat Akun</p>

                        <form onSubmit={this.handleSubmit}>
                            <div className="form-input-onboard">
                                <input
                                    ref={this.ref1}
                                    type="email"
                                    id="email"
                                    name="email"
                                    autoComplete="off"
                                    placeholder=" "
                                    value={this.state.email}
                                    onChange={this.handleInputChange}
                                    onBlur={this.validateEmail}
                                />
                                <label onClick={() => this.ref1.current.focus()}>email</label>
                            </div>
                            <div className="form-input-onboard">
                                <input
                                    ref={this.ref6}
                                    type="text"
                                    id="username"
                                    name="username"
                                    autoComplete="off"
                                    placeholder=" "
                                    value={this.state.username}
                                    onChange={this.handleUsernameChange}
                                    onBlur={this.validateUsername}
                                />
                                <label onClick={() => this.ref6.current.focus()}>nama pengguna</label>
                            </div>
                            <Row>
                                <Col xs={12} md={6}>
                                    <div className="form-input-onboard">
                                        <input
                                            ref={this.ref2}
                                            type="text"
                                            id="firstname"
                                            name="firstname"
                                            autoComplete="off"
                                            placeholder=" "
                                            value={this.state.firstname}
                                            onChange={this.handleInputChange}
                                        />
                                        <label onClick={() => this.ref2.current.focus()}>nama depan</label>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="form-input-onboard">
                                        <input
                                            ref={this.ref3}
                                            type="text"
                                            id="lastname"
                                            name="lastname"
                                            autoComplete="off"
                                            placeholder=" "
                                            value={this.state.lastname}
                                            onChange={this.handleInputChange}
                                        />
                                        <label onClick={() => this.ref3.current.focus()}>nama akhir</label>
                                    </div>
                                </Col>
                            </Row>
                            <div className="form-input-onboard">
                                <input
                                    ref={this.ref4}
                                    type={this.state.show_password ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    autoComplete="off"
                                    placeholder=" "
                                    value={this.state.password}
                                    onChange={this.handleInputChange}
                                />
                                <label onClick={() => this.ref4.current.focus()}>kata sandi</label>
                                <span className="password-show" name="show_password" onClick={this.toggleShowPass}>
                                    {this.state.show_password ?
                                        <img src={eye} className="" alt="show-password" name="show_password" />
                                        :
                                        <img src={eyeoff} className="" alt="hide-password" name="show_password" />
                                    }
                                </span>
                            </div>
                            <div className="form-input-onboard">
                                <input
                                    ref={this.ref5}
                                    type={this.state.show_password_confirm ? "text" : "password"}
                                    id="password-confirm"
                                    name="password_confirm"
                                    autoComplete="off"
                                    placeholder=" "
                                    value={this.state.password_confirm}
                                    onChange={this.handleInputChange} />
                                <label onClick={() => this.ref5.current.focus()}>ulang kata sandi</label>
                                <span className="password-show" name="show_password_confirm" onClick={this.toggleShowPass}>
                                    {this.state.show_password_confirm ?
                                        <img src={eye} className="" alt="show-password" name="show_password_confirm" />
                                        :
                                        <img src={eyeoff} className="" alt="hide-password" name="show_password_confirm" />
                                    }
                                </span>
                            </div>

                            <button type="submit"
                                className="submit-button-onboard"
                                id="submit"
                                disabled={
                                    this.state.email.length !== 0 &&
                                        this.state.firstname.length !== 0 &&
                                        this.state.lastname.length !== 0 &&
                                        this.state.password.length !== 0 &&
                                        this.state.password_confirm.length !== 0 ?
                                        false : true
                                }
                            >
                                {this.state.isLoading ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    : "Create Account"
                                }
                            </button>
                        </form>
                        <p className="onboard-span-tns">By signing up, I agree to the <Link to="/privacy-policy">Privacy Policy</Link> and <Link to="/privacy-policy">Terms of Service.</Link></p>
                        <p className="onboard-span">Punya Akun? <Link to="/login">Masuk</Link></p>
                    </div>
                </>
            )
        }
    }

    RegisterSuccess() {
        return (
            <React.Fragment>
                {/* <img className="button-back" src={close} alt="icon-back" onClick={() => this.props.history.push("/")} /> */}
                {/* <div className="onboard-wrapper onboard-form"> */}
                <div className="form-wrapper">
                    <div className="onboard-img-sent">
                        {/* <img src={sent} alt="sent-email-image"/> */}
                    </div>
                    {/* <p className="onboard-header">Registration success!</p> */}
                    <p className="onboard-header">Verify your email</p>
                    <p className="onboard-header-span">We've sent an email to <b>{this.state.email}</b>. Follow the link in the email to verify your email address and activate your account.</p>
                    <form>
                        {/* <Link to="/login">
                                <button type="submit" className="submit-button-onboard" id="submit">
                                    sign in
                                </button>
                            </Link> */}
                    </form>
                </div>
                {/* </div> */}
            </React.Fragment>
        )
    }

    render() {
        return (
            <div className="onboard-container">
                <Col xs={12} sm={7} md={5} xl={5} className="onboard-wrapper onboard-form">
                    {this.state.registerSuccess ? this.RegisterSuccess() : this.RegisterForm()}
                </Col>
                <Col xs={0} sm={5} md={7} xl={7} className="onboard-information onboard-slider">
                    <ParticlesBackground />
                    <p>Platform terpadu multibahasa yang mudah digunakan, akurat, cepat dan murah untuk transkripsi, translasi, sintesa suara otomatis dengan fitur lengkap, seperti speaker diarization, closed captioning, subtitling dan summary.

                        <br /><br /><br /> Powered by PT. BahasaKita</p>
                </Col>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        continue_to_checkout_flag: state.BillingReducers.continue_to_checkout_flag,
    };
}

export default connect(mapStateToProps)(RegisterPage);
