import React, { Component } from "react";
import store from "../../store";

// Functions
import { fetchTutorialList } from "../../reducers/TutorialReducers";

// Components
import { Row, Col } from "react-bootstrap";

// Style
import './Tutorial.scss'

// ETC
import tts from "../../img/tutorial/tts.svg"
import stt from "../../img/tutorial/stt.svg"
import download from "../../img/tutorial/download.svg"
import Loading from "../../components/Loading";


class Tutorial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tutorialList: [],
            
            isLoading: true,
            showError: false,
            errorMessage: "",
        }
    }

    componentDidMount() {
        store.dispatch(fetchTutorialList())
            .then((res) => {
                this.setState({
                    tutorialList: res.services,
                    isLoading: false
                })
            })
            .catch((err) => {
                this.setState({
                    isLoading: false,
                    showError: true,
                    errorMessage: err.toString()
                })
            }) 
    }

    render() {
        return (
            <div className="tutorial-page-main-container">
                <div className="tutorial-top-bg"></div>
                <div className="tutorial-page-content-container">
                    <Row className="head">
                        <Col xs={12}>
                            <div className="title">Dokumentasi</div>
                            <div className="caption">Lihat dokumentasi dibawah ini untuk mempelajari lebih lanjut Speech to Text dan Text To Speech</div>
                        </Col>
                    </Row>
                    <Row className="body">
                        { this.state.tutorialList.map((tutorial, index) => {
                            var bgImg = stt;
                            if ( index%2 === 0 ) {
                                bgImg = tts;
                            }

                            return (
                                <Col key={"tutorial-"+tutorial.id_service} xs={12} md={6} xl={4}>
                                    <TutorialCard 
                                        title={tutorial.name}
                                        bgImg={bgImg}
                                        fileUrl={tutorial.file_url}
                                    />
                                </Col> 
                            )
                        }) }
                    </Row>
                    { this.state.isLoading &&
                        <Loading />
                    }
                </div>
            </div>
        )
    }
}

class TutorialCard extends Component {
    render() {
        return (
            <a href={this.props.fileUrl} target="_blank">
                <div className="tutorial-card-container" id={this.props.id}>
                    <div className="title">{this.props.title.replace("Tutorial", "Dokumentasi")}</div>
                    <img className="download-btn" src={download} id="tutorial_download"/>
                    <img className="bg-img" src={this.props.bgImg} />
                </div>
            </a>
        )
    }
}

export default Tutorial;