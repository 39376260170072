import React, { Component, useEffect, useRef, useState } from "react";
import store, { history } from "../../../store";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

// Functions
import { fetchLogin, fetchLoginKeycloak } from "../../../reducers/UserManagementReducers";

// Components
import { Button, Col, Spinner } from "react-bootstrap";
import ParticlesBackground from '../../../components/ParticlesBackground/ParticlesBackground';

// Style
import '../OnBoard.scss'

// ETC
import google from "../../../img/gmail.svg"
import eye from "../../../img/eye.svg"
import eyeoff from "../../../img/eye-closed.svg"
import warning from "../../../img/warning.svg"
import { useKeycloak } from "@react-keycloak/web";


function LoginPage(props) {
	const { continue_to_checkout_flag } = useSelector(state => state.BillingReducers);

    const dispatch = useDispatch()

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

	const { keycloak } = useKeycloak();

    const ref1 = useRef();
    const ref2 = useRef();

    useEffect(() => {
        keycloak.login();
    }, [])

    const validateEmail = () => {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([a-zA-Z0-9-\\.]+)$/;
        if ( reg.test(email) === false ) {   
            // alert('Invalid Email Address');
            
            setIsLoading(false)
            setShowError(true)
            setErrorMessage("alamat email tidak sesuai")

            return false;
        }

        return true;
    }

    const handleEmailChange = ({ target }) => {   
        // console.log("handleEmailChange")

        let value = target.value;

        setShowError(false)
        setIsLoading(false)
        setEmail(value) 
    };
      
    const toggleShowPass = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShowPassword(!showPassword)
    }
    
    const handlePasswordChange = ({ target }) => {
        setPassword(target.value)

        if (target.value.length > 0) {
            setShowError(false)
            setIsLoading(false)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if ( isLoading ) return;
        
        if ( 
            email.replace(/\s+/g, '')    === "" || 
            password.replace(/\s+/g, '') === "" 
        ) {
            setIsLoading(false)
            setShowError(true)
            setErrorMessage("silakan isi semua kolom")
            return;  
        }

        setIsLoading(true)
        setShowError(false)

        dispatch(fetchLogin(email, password))
            .then((res) => {
                if ( res.status === "success" ) {
                    // console.log(res)
                    window.location.reload()

                    // if ( continue_to_checkout_flag ) {
                    //     history.push("/cart")
                    // } else {
                    //     history.push("/");
                    // }

                }
            })
            .catch((err) => {
                // console.log(err)
                setIsLoading(false)
                setShowError(true)
                setErrorMessage(err.toString())
            }) 

        
        // dispatch(fetchLoginKeycloak(email, password))
        //     .then((res) => {
        //         console.log(res)
        //         window.location.reload()
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //         setIsLoading(false)
        //         setShowError(true)
        //         setErrorMessage(err.toString())
        //     }) 
    }

    function AlertDismissibleError() {      
        if (showError) {
          return (
            <p className="error-alert" dismissible="true">
              <img src={warning} alt="error-dismissible"/>  {errorMessage}
            </p>
          );
        }
    }        

    return null;

    return (
        <div className="onboard-container">
            <Col xs={12} sm={7} md={5} xl={5} className="onboard-wrapper onboard-form">
                <div className="form-wrapper">

                    <p className="onboard-header"><b className="text-style-1">Masuk ke<br />Web Console</b></p>

                    {AlertDismissibleError()}
                    
                    <>
                        <p className="onboard-span">
                        </p>
                        <div className="app-signin">
                            <button 
                                onClick={() => keycloak.login({
                                    idpHint: 'google',
                                    redirectUri: window.location.origin+"/redirector?"
                                })}
                            >
                                <img src={google}/> Lanjutkan dengan Google
                            </button>
                        </div>
                    </>

                    {/* <div className="onboard-span border">
                        <span>
                            atau
                        </span>
                    </div> */}
                    
                    <p className="onboard-span">atau</p>

                    <form onSubmit={handleSubmit}>
                        <div className={"form-input-onboard "+(showError?"error":"")}>
                            <input 
                                ref={ref1}
                                type="email" 
                                id="email" 
                                name="email" 
                                autoComplete="on"
                                placeholder=" "
                                value={email}
                                onChange={handleEmailChange}
                                onBlur={validateEmail}
                            />
                            <label onClick={() => ref1.current.focus()}>email</label>
                        </div>
                        <div className={"form-input-onboard "+(showError?"error":"")}>
                            <input 
                                ref={ref2}
                                type={showPassword ? "text" : "password"}
                                id="password" 
                                name="password" 
                                autoComplete="on"
                                placeholder=" "
                                value={password}
                                onChange={handlePasswordChange}/>
                            <label onClick={() => ref2.current.focus()}>kata sandi</label>
                            <span className="password-show" name="show_password" onClick={toggleShowPass}>
                                { showPassword ? 
                                    <img src={eye} className="" alt="show-password" name="show_password" />
                                    :
                                    <img src={eyeoff} className="" alt="hide-password" name="show_password" />
                                }
                            </span>
                        </div>
                        <div className="form-input-onboard forgot-pass">
                            <Link to="/reset-password" id="login_forgotpassword">lupa kata sandi?</Link>
                        </div>
                        <button type="submit" 
                            className="btn btn-primary submit-button-onboard" 
                            id="submit" 
                            disabled={
                                email.length !== 0 && 
                                password.length !== 0 ? 
                                false : true
                            }
                        >
                            { isLoading ? 
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> 
                                : "Masuk"
                            }
                        </button>
                    </form>

                    <br />
                    
                    <p className="onboard-span">Belum punya akun?
                        {/* <Link to="/register">Daftar GRATIS</Link> */}
                        <Link to="/register"><button className="btn btn-secondary submit-button-onboard register-btn">Daftar GRATIS</button></Link>
                    </p>
                    
                </div>
            </Col>
            <Col xs={0} sm={5} md={7} xl={7} className="onboard-wrapper onboard-slider">
                <ParticlesBackground />
                <p>Platform Percakapan <br />Kecerdasan Buatan Terbaik</p>
            </Col>
        </div>
    )
}

export default LoginPage;