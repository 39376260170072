import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./auth/Keycloak"
import store, { history } from './store';
import { natsConnOpen } from './reducers/NATSDefaultReducers'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { updateIdToken } from './reducers/UserManagementReducers';
import { wsConnOpen } from './reducers/WebSocketDefaultReducers';
import { fetchSttToken } from './reducers/STTReducers';

// console.log(window.snap)

// VERSION
console.log(`${process.env.REACT_APP_NAME} version ${process.env.REACT_APP_VER}`)

/*** Connect to NATS ***/
store.dispatch(natsConnOpen());
  
// /*** Connect to WebSocket ***/
// export function initTokenAndWS() {
// 	// store.dispatch(connOpen());
// 	store.dispatch(fetchSttToken())
// 		.then(token => {
// 			//store.dispatch(wsConnOpen());
// 		})
// 		.catch((err) => {
// 			setTimeout(() => {
// 				initTokenAndWS();
// 			}, 10000)
// 		});
// } 

// initTokenAndWS();
// /****************************/

// SET id_token from localStorage
let id_token = localStorage.getItem("id_token");
store.dispatch(updateIdToken(id_token));
	

let initOptions = {
	// onLoad: 'login-required',
	onLoad:'check-sso',
	// silentCheckSsoFallback: false,
	checkLoginIframe: false,
	promiseType: 'legacy'
}

let keycloakToken = localStorage.getItem("keycloakToken");
if ( keycloakToken !== null && keycloakToken !== undefined && keycloakToken !== "undefined" ) {
	keycloakToken = JSON.parse(keycloakToken);
	initOptions.token 		 = keycloakToken.access_token;
	initOptions.refreshToken = keycloakToken.refresh_token;
}

// console.log(keycloakToken)

// console.log("=== initOptions ===")
// console.log(initOptions)
		
const onTokens = (tokens) => {
	// console.log("=== onTokens ===")
	// console.log(tokens)

	const keycloakToken = {
		access_token: tokens.token,
		refresh_token: tokens.refreshToken
	}
	// console.log(JSON.stringify(keycloakToken))

	if ( tokens.token === undefined ) {
		store.dispatch(updateIdToken(null));
	}

	localStorage.setItem("keycloakToken", JSON.stringify(keycloakToken))
}

ReactDOM.render(
	<ReactKeycloakProvider 
		authClient={keycloak} 
		initOptions={initOptions}
		onTokens={onTokens}
		// onEvent={(e, err) => console.log(e, err)}
	>
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<App />
			</ConnectedRouter>
		</Provider>
	</ReactKeycloakProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();