import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { history } from "../../store";
import queryString from 'query-string';

// Functions
import { getAllTransactionHistory, updateDataTransactionHistory } from '../../reducers/BillingReducers';

// Components
import { Col } from "react-bootstrap";
import StatusNav from "./StatusNav"
import CustomTable from "../../components/CustomTable/CustomTable"
import ModalTransactionDetail from './ModalTransactionDetail/ModalTransactionDetail';

// Style
import './TransactionHistory.scss'

// ETC
import { MoreHoriz } from "@material-ui/icons"
import NcloudPagination from '../../components/NcloudPagination';
import Loading from '../../components/Loading';


function TransactionHistory() {
    const dispatch = useDispatch();

    const { transactionHistory, transactionHistoryTotal } = useSelector(state => state.BillingReducers);

    const statusList = ["success", "processing", "failed"];

    const [selectedStatus, setSelectedStatus] = useState("success");
    const [showTransactionDetail, setShowTransactionDetail] = useState(false);
	const [idTransaction, setIdTransaction] = useState(null);

    const [offset, setOffset] = useState(0);
    const limit = 10;

    const [activePage, setActivePage] = useState(1);

    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        parseSearchParams(history.location.search);

        dispatch(getAllTransactionHistory())
            .then(() => {
                setIsLoading(false)
            })
            .catch(() => {
                setIsLoading(false)
            });

        return () => {
            dispatch(updateDataTransactionHistory([]))
        }
    }, [])

    useEffect(() => {
        const params = new URLSearchParams({status: selectedStatus });

        history.push({ pathname: history.location.pathname, search: params.toString() });   
    }, [selectedStatus])

    useEffect(() => {
        if ( !showTransactionDetail ) {
            setIdTransaction(null)
        }
    }, [showTransactionDetail])

    useEffect(() => {
        // console.log(idTransaction)
        if ( idTransaction !== null ) {
            setShowTransactionDetail(true)
        }
    }, [idTransaction])

    const parseSearchParams = (search) => {
        const search_params = queryString.parse(search);
        const { status } = search_params;
        if ( status !== undefined ) {
            setSelectedStatus(status)
        } else {
            setSelectedStatus("success")
        }
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    }

    const columns = [
        {
            Header: 'Date',
            accessor: 'date',
            disableSortBy: true,
        },
        {
            Header: 'Service Type',
            accessor: 'serviceType',
            disableSortBy: true,
        },
        {
            Header: 'Payment Method',
            accessor: 'paymentMethod',
            disableSortBy: true,
        },
        {
            Header: 'Pay Before',
            accessor: 'payBefore',
            disableSortBy: true,
        },
        {
            Header: 'Payment Amount',
            accessor: 'paymentAmount',
            disableSortBy: true,
        },
        {
            Header: '',
            accessor: 'id_transaction',
            disableSortBy: true,
            Cell: ({ cell }) => (
                <button 
                    className="more-btn"
                    onClick={() => setIdTransaction(cell.row.values.id_transaction)}
                >
                    <MoreHoriz className="icon" />
                </button>
            )
        }
    ];
        
    const transactionHistorySorted = transactionHistory.sort((a,b) => {
        return new Date(b.date) - new Date(a.date);
    });

    var data = [];
    transactionHistorySorted.forEach((transaction) => {

        const midtrans_snap_token   = transaction.midtrans.snap_token;
        const isUsingMidtrans       = ( midtrans_snap_token !== "" );

        let payment_method  = transaction.payment.payment_method.toLowerCase();
        let payment_amount  = transaction.amount;
        let pay_before      = (transaction.due_date === "" ? "-" : moment(transaction.due_date).format("DD MMM YYYY, HH:mm"));
        
        if ( isUsingMidtrans ) {
            // console.log(transaction.midtrans)
            const midtrans_info = ( transaction.midtrans.info !== "" ? JSON.parse(transaction.midtrans.info) : null );

            if ( midtrans_info !== null ) {
                payment_method = midtrans_info.payment_type.replace(/_/g, ' ');

                if ( payment_method.toLowerCase() === "bank transfer" ) {

                    if ( midtrans_info.va_numbers !== null ) {
                        payment_method += " - "+midtrans_info.va_numbers[0].bank.toUpperCase();
                    } else {
                        for ( let key in midtrans_info ) {
                            if ( key.includes("_va_number") ) {
                                if ( midtrans_info[key] !== "" ) {
                                    payment_method += " - "+key.replace("_va_number", "").toUpperCase();
                                }
                            }
                        }

                        if ( midtrans_info.bill_key !== "" ) {
                            payment_method += " - MANDIRI";
                        }
                    }

                } else if ( payment_method === "echannel" ) {
                    if ( midtrans_info.bill_key !== "" ) {
                        payment_method = "Bank Transfer - MANDIRI"
                    }

                } else if ( payment_method === "cstore" ) {
                    payment_method =  midtrans_info.store;
                }

            } else {
                payment_method = "Belum dipilih" 
            }
        }


        if ( transaction.payment.acc_number !== undefined && transaction.payment.acc_number === "free") {
            payment_method  = "-"
            payment_amount  = "free";
            pay_before      = "-"
        }

        // console.log(payment_method)

        if ( transaction.status.toLowerCase() === selectedStatus.toLowerCase() ) {
            data.push({
                id_transaction  : transaction.id_transaction,
                date            : moment(transaction.date).format("DD MMM YYYY, HH:mm"),
                // date: transaction.date,
                serviceType     : transaction.service_type,
                // paymentMethod: transaction.payment.payment_method,
                paymentMethod   : payment_method,
                payBefore       : pay_before,
                paymentAmount   : payment_amount
            })
        }
    })

    const total = data.length;

    // console.log(data)

    // console.log(transactionHistoryTotal) 
    // console.log(transactionHistory)

    // console.log(selectedStatus)
    return (
        <div className="trx-history-page-main-container">
            <div className="trx-history-page-content-container">
                <div className="head">
                    <Col xs={12}>
                        <div className="title">Riwayat Transaksi</div>
                    </Col>
                </div>
                <div className="body">
                    <StatusNav
                        statusList={statusList} 
                        selectedStatus={selectedStatus} 
                        setSelectedStatus={setSelectedStatus} 
                    />
                    { isLoading || 
                        ( data.length === 0 && 
                            transactionHistoryTotal > 0 && 
                            transactionHistory.length !== transactionHistoryTotal
                        ) ?
                        <Loading />
                        :
                        <CustomTable 
                            // key={"table-"+selectedStatus+data.length} 
                            key={JSON.stringify(data)}
                            columns={columns} data={data}
                            limit={limit} 
                            activePage={activePage}
                            onRowClick={setIdTransaction}
                        />
                    }
                    <ModalTransactionDetail 
                        show={showTransactionDetail}
                        onHide={() => setShowTransactionDetail(false)}
                        idTransaction={idTransaction} 
                    />
                </div>
                <div className="footer">
                    <NcloudPagination
                        activePage={activePage}
                        itemPerPage={limit}
                        totalItems={total}
                        onChange={handlePageChange}
                    />
                </div>
            </div>
        </div>
    )
}
export default TransactionHistory;