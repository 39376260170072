import React from 'react'
import { Spinner } from 'react-bootstrap'

const LoadingFullPage = (props) => {
    const { info } = props;

    return (
        <div className="d-flex justify-content-center align-items-center"
            style={{height: "100vh", width: "100vw", flexDirection: "column"}}
        >
            <Spinner
                as="div"
                animation="border"
                size="md"
                role="status"
                aria-hidden="true"
                style={{display: "block", margin: "20px auto"}}
            />
            { info !== undefined && info.replace(/\s+/g, '') !== "" &&
                <div>{info}</div>
            }
        </div>
    )
}

export default LoadingFullPage
