// JS Imports
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import store from '../../store';

// Functions
import { 
    reqInitSendBlobChange, 
    reqReadAndSendBlobStream,
    fchStopRecording,
    fchPauseRecording,
    fchResumeRecording,
    reqStartStream,
    reqStopStream,
    fchStartRecording,
    updateRecordingDuration,
} from '../../reducers/STTReducers';



// Older browsers might not implement mediaDevices at all, so we set an empty object first
if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
}
  
// Some browsers partially implement mediaDevices. We can't just assign an object
// with getUserMedia as it would overwrite existing properties.
// Here, we will just add the getUserMedia property if it's missing.
if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function(constraints) {
  
        // First get ahold of the legacy getUserMedia, if present
        var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        // Some browsers just don't implement it - return a rejected promise with an error
        // to keep a consistent interface
        if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
        }

        // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
        return new Promise(function(resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
        });
    }
}

var mediaConstraints = {
    audio: true
};

var mediaRecorder;

var recordingDurationInterval = null;

/*** Below variables is used in LiveMeeting (streaming) ***/

// Variable to save audio blobs of LiveMeeting (streaming)
export var streamAudioBlob = new Blob([], { type: 'audio/wav' });
var fullAudioBlob = new Blob([], { type: 'audio/wav' });

// Variable to save audio offset of LiveMeeting (streaming)
export var lastOffsetStream = 0;

// To save audio blob to streamAudioBlob on LiveMeeting (streaming)
export var addAudioBlobs = function(new_blob) {
    streamAudioBlob = new Blob( [ streamAudioBlob, new_blob ], { type: 'audio/wav' });
    fullAudioBlob = new Blob( [ fullAudioBlob, new_blob ], { type: 'audio/wav' });
} 

// To remove first blob_length from streamAudioBlob on LiveMeeting (streaming)
export var spliceAudioBlobs = function(blob_length){
    streamAudioBlob = streamAudioBlob.slice(blob_length, streamAudioBlob.size, 'audio/wav');
}

// To clear streamAudioBlob
export function clearAudioBlobs(){
    streamAudioBlob = new Blob([], { type: 'audio/wav' });
    fullAudioBlob   = new Blob([], { type: 'audio/wav' });
}

export function getFullAudioUrl() {
    // get full wav
    let wavDataBlob = fullAudioBlob.slice();
    let wavHeader = buildWaveHeader(wavDataBlob.size)
    let wavHeaderBlob = new Blob([wavHeader]);
    let current_datetime = new Date();
    let file = new File(
        [ wavHeaderBlob, wavDataBlob ], 
        "recording_"+current_datetime.getTime()+".wav", 
        {type: 'audio/wav', lastModified: current_datetime}
    )

    let localAudio = URL.createObjectURL(file);
    return localAudio;
}

// To change audio offset of LiveMeeting (streaming)
export function setLastOffsetStream(value){
    lastOffsetStream = value;
}


/************ Media Stream Checker ************/
var checkIfMediaStreamActive = null;
// var recordingTimeout = null;

function stopCheckIfMediaStreamActive() {
    if ( checkIfMediaStreamActive !== null ) {
        clearInterval(checkIfMediaStreamActive);
        checkIfMediaStreamActive = null;
    }
}

function isMediaStreamActive(mediaStream) {
    // console.log(mediaStream)
    if ('active' in mediaStream) {
        if (!mediaStream.active) {
            stopStreaming();
            return false;
        }
    } else if ('ended' in mediaStream) { // old hack
        if (mediaStream.ended) {
            stopStreaming();
            return false;
        }
    }
    return true;
}
/*************************************************/



export function stopStreaming(){
    var size_or_length;
    size_or_length = streamAudioBlob.size;

    if (size_or_length === 0) {
        if ( store.getState().STTReducers.recordingStatus !== "pause" ) {
            store.dispatch(reqStopStream());
        }
    }
    
    stopRecording();

    stopCheckIfMediaStreamActive();
}


export function initRecorder() {
    // console.log("=== initRecorder ===")
    return new Promise((resolve, reject) => {
        navigator.mediaDevices.getUserMedia(mediaConstraints)
        .then((stream) => {
            window.localStream = stream;
            
            onMediaSuccess(stream);

            resolve("success")
        })
        .catch((err) => {
            onMediaError(err);
            
            reject(err.toString());
        });
    })
}

export async function startRecording() {
    await initRecorder();

    // console.log("=== START  RECORDING ===")
    if ( typeof mediaRecorder !== "undefined" ) {
        mediaRecorder.startRecording();

        store.dispatch(fchStartRecording());

        store.dispatch(updateRecordingDuration(0));

        recordingDurationInterval = setInterval(() => {
            const recordingDuration = store.getState().STTReducers.recordingDuration;
            store.dispatch(updateRecordingDuration((recordingDuration+1)));
        }, 1000)
    }
}

export function pauseRecording() {
    // console.log("=== PAUSE  RECORDING ===")
    if ( typeof mediaRecorder !== "undefined" ) {
        mediaRecorder.pauseRecording();

        store.dispatch(fchPauseRecording());
    }
}

export function resumeRecording() {
    // console.log("=== RESUME  RECORDING ===")
    if ( typeof mediaRecorder !== "undefined" ) {        
        mediaRecorder.resumeRecording();

        store.dispatch(fchResumeRecording());
    }
}

export function stopRecording() {
    // clearTimeout(recordingTimeout);
    // recordingTimeout = null;
    
    // console.log("=== STOP  RECORDING ===")
    if ( typeof mediaRecorder !== "undefined" ) {
        mediaRecorder.stopRecording();
        mediaRecorder.clearRecordedData();
        
        // var track = window.localStream.getTracks()[0];  // if only one media track
        // track.stop();
        var tracks = window.localStream.getTracks();
        tracks.forEach((track) => {
            track.stop();
        });

        store.dispatch(fchStopRecording());

        clearInterval(recordingDurationInterval);
        recordingDurationInterval = null;

        // // Baru mulai ngirim audio saat recording di stop
        // store.dispatch(reqStartStream());
    }
}

function onMediaSuccess(stream) {
    // console.log("onMediaSuccess")

    if ( checkIfMediaStreamActive === null ) {
        checkIfMediaStreamActive = setInterval(function() {
            isMediaStreamActive(stream);
        }, 1000)
    }

    var options = {
        type: 'audio',
        recorderType: StereoAudioRecorder,
        mimeType: 'audio/wav',
        numberOfAudioChannels: 1,
        desiredSampRate: 16 * 1000, // bits-per-sample * 1000
        timeSlice: 100,
        bufferSize: 0,
        ondataavailable: async function(blob){
            // console.log("ondataavailable")
            if ( 
                store.getState().STTReducers.recordingStatus === "start" ||
                store.getState().STTReducers.recordingStatus === "resume"
            ) {
                var wavCutBlob = blob.slice(44, blob.size, 'audio/wav');
                addAudioBlobs(wavCutBlob);
               
                if ( store.getState().STTReducers.initSendBlob === true ) {
                    // Mark if it's the first time sending live meeting audio
                    store.dispatch(reqInitSendBlobChange(false));
                    
                    store.dispatch(reqStartStream());
                }
            }

        }
    };

    
    mediaRecorder = new RecordRTC(stream, options);

    // startRecording();
}

function onMediaError(e) {
    // console.log("onMediaError")
    // console.log(e)
    console.error('media error', e);
    alert('Tidak ada mikrofon yang terdeteksi');

    stopStreaming();
}

// https://ccrma.stanford.edu/courses/422/projects/WaveFormat/
export function buildWaveHeader(dataSize) {
    var numChannels =1;
    var sampleRate = 16000;
    var bytesPerSample = 2;
    var blockAlign = numChannels * bytesPerSample;
    var byteRate = sampleRate * blockAlign;

    var buffer = new ArrayBuffer(44);
    var dv = new DataView(buffer);

    var p = 0;

    function writeString(s) {
        for (var i = 0; i < s.length; i++) {
            dv.setUint8(p + i, s.charCodeAt(i));
        }
        p += s.length;
    }

    function writeUint32(d) {
        dv.setUint32(p, d, true);
        p += 4;
    }

    function writeUint16(d) {
        dv.setUint16(p, d, true);
        p += 2;
    }

    writeString('RIFF');              // ChunkID
    writeUint32(dataSize + 36);       // ChunkSize
    writeString('WAVE');              // Format
    writeString('fmt ');              // Subchunk1ID
    writeUint32(16);                  // Subchunk1Size
    writeUint16(1);                   // AudioFormat
    writeUint16(numChannels);         // NumChannels
    writeUint32(sampleRate);          // SampleRate
    writeUint32(byteRate);            // ByteRate
    writeUint16(blockAlign);          // BlockAlign
    writeUint16(bytesPerSample * 8);  // BitsPerSample
    writeString('data');              // Subchunk2ID
    writeUint32(dataSize);            // Subchunk2Size

    return buffer;
}