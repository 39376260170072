import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";

import './Input.scss'

import eye from "../../img/eye.svg"
import eyeoff from "../../img/eye-closed.svg"


function Input(props) {
    const { 
        size="md",
        ref=undefined,
        placeholder="", 
        type="text", 
        showTogglePassword=false 
    } = props;

    const [inputRef, setInputRef] = useState(useRef());
    const [_type, setType] = useState("text");

    useEffect(() => {
        setType(type);
        if ( ref !== undefined ) {
            setInputRef(ref);
        }
    }, [])
    
    const focus = () => {
        inputRef.current.focus();
    }

    const togglePassword = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const new_type = _type === "password" ? "text" : "password";
        setType(new_type)
    }

    return (
        <div className={"console-input " + size}>
            <input 
                autoComplete="off"
                {...props}
                ref={inputRef}
                type={_type}
                placeholder=" "
            />
            <label onClick={focus}>{placeholder}</label>
            { type === "password" && showTogglePassword &&
                <span className="toggle-password-visibility" onClick={togglePassword}>
                    { _type === "password" ? 
                        <img src={eyeoff} className="" alt="hide-password"/>
                        :
                        <img src={eye} className="" alt="show-password"/>
                    }
                </span>
            }
        </div>
    )
}

export default Input;