import { useEffect } from 'react';
import { useState } from 'react';
import Joyride from 'react-joyride';

export var locale = {
    skip: <strong aria-label="skip">Skip</strong>,
    next: "Next",
    last: "OK"
}

export var basic_option = {
    locale,
    spotlightPadding: 10,
    // spotlightClicks: true,
    // disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
}


function BaseWalkthrough(props) {
    const { run=true, steps=[], stepIndex=null, callback=() => {} } = props;

    const handleJoyrideCallback = (data) => {
        // console.log(data)
        callback(data);
    }

    return (
        <Joyride
            callback={handleJoyrideCallback}
            continuous
            // hideCloseButton
            run={run}
            scrollToFirstStep
            showProgress={false}
            showSkipButton
            steps={steps}
            stepIndex={stepIndex}
            styles={{
                options: {
                    zIndex: 10000,
                    arrowColor: "#ffffff",
                    backgroundColor: "#ffffff",
                    primaryColor: "#153a82",
                    textColor: "#000000",
                },
            }}
        />
    )
}

export default BaseWalkthrough