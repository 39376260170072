import React, { Component } from 'react';

import "./Cards.scss"

class DashboardCard extends Component {

    handleClick = () => {
        this.props.onSeeDetail();
    }

    render() {
        return (
            <div className="custom-card dashboard-card" onClick={this.handleClick}>
                <div className={this.props.status === "Active" ? "card-status active" : "card-status pending"}>{this.props.status}</div>
                <div className="card-top-1">{this.props.service_name}</div>
                <div className="card-top">{this.props.planType}</div>
                <div className="card-body">
                    <h3>{this.props.title}</h3>
                    <p>{this.props.desc}</p>                 
                </div>    
                
                <button id="dashboard_see-detail">Lihat Detail</button>           
            </div>
        )
    }
}

export default DashboardCard;