import React, { Component } from "react"
import store, { history } from "../../../store";
import { Link } from "react-router-dom"

// Functions
import { fetchResetPassword } from "../../../reducers/UserManagementReducers";

// components
import { Col, Spinner } from "react-bootstrap";
import ParticlesBackground from '../../../components/ParticlesBackground/ParticlesBackground';

// ETC
import warning from "../../../img/warning.svg"
import back from "../../../img/back.svg"
import close from "../../../img/close.svg"
import sent from "../../../img/email-sent.svg"
import { useState } from "react";
import { useEffect } from "react";
import Loading from "../../../components/Loading";
import LoadingFullPage from "../../../components/LoadingFullPage";
import { useKeycloak } from "@react-keycloak/web";


function VerifyEmailPage(props) {
    const { keycloak } = useKeycloak();

    const [isVerifying, setIsVerifying] = useState(true);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const params = new URLSearchParams(history.location.search);
    const token = params.get('token');

    useEffect(() => {
        if (token === null || token === undefined) {
            history.push("/")
        } else {
            verifyToken(token);
        }
    }, [])

    const verifyToken = (token) => {
        // console.log("verify token")
        const body = {
            token: token
        };

        //   console.log(body);

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/" + process.env.REACT_APP_SYSTEM_STATUS + "/email/verification/verify/", {
            headers: {
                accept: "application/json",
                'Content-Type': 'application/json; charset=utf-8'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw (response.status + " " + response.statusText)
                }
            })
            .then(result => {
                //   console.log(result);
                if (result.status === "success") {
                    setIsVerifying(false);
                    // setResultVerify(result)

                } else {
                    const error = (result.error !== undefined && result.error !== "" ? result.error : "failed");
                    throw (error)
                }
            })
            .catch(err => {
                setIsVerifying(false);
                setShowError(true);
                setErrorMessage("ERROR: " + err.toString());
            });
    }

    function AlertDismissibleError() {
        if (showError) {
            return (
                <p className="error-alert" dismissible="true">
                    <img src={warning} alt="error-dismissible" />  {errorMessage}
                </p>
            );
        }

        return null;
    }

    function ForgotPass() {
        if (isVerifying) {
            return (
                <React.Fragment>
                    {/* <img className="button-back" src={back} alt="icon-back" onClick={() => this.props.history.go(-1)}/> */}
                    <div className="form-wrapper">
                        <p className="onboard-header">Verifying Account</p>
                        <p className="onboard-header-span">Please wait while we are verifying your account</p>
                        <Loading />
                    </div>
                </React.Fragment>
            );
        } else if (showError) {
            return (
                <React.Fragment>
                    {/* <img className="button-back" src={back} alt="icon-back" onClick={() => this.props.history.go(-1)}/> */}
                    <div className="form-wrapper">
                        <p className="onboard-header">Account not verified</p>
                        {/* <p className="onboard-header-span">We encounter some error</p> */}
                        <AlertDismissibleError />
                        {/* <Link to="/">
                            <button type="submit" className="submit-button-onboard" id="submit">
                                Back to home page
                            </button>
                        </Link> */}

                        {/*early adpater only*/}
                        <a href={`${process.env.REACT_APP_DIKTEIN_URL}`}>
                            <button type="submit" className="submit-button-onboard" id="submit">
                                Back to home page
                            </button>
                        </a>

                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    {/* <img className="button-back" src={close} alt="icon-back" onClick={() => this.props.history.push("/")} /> */}
                    <div className="form-wrapper">
                        <div className="onboard-img-sent"><img src={sent} alt="sent-email-image" /></div>
                        <p className="onboard-header">Account Verified!</p>
                        <p className="onboard-header-span">
                            Now you can login with your new email
                        </p>
                        {/* <Link to="/login" > */}
                        {/* <button className="submit-button-onboard" id="submit"
                            onClick={() =>
                                keycloak.logout({ redirectUri: window.location.origin + "/login" })
                            }
                        >
                            Login to your account
                        </button> */}
                        
                        {/*early adpater only*/}                        
                        <a href={`${process.env.REACT_APP_DIKTEIN_URL}`}>
                            <button type="submit" className="submit-button-onboard" id="submit">
                                Login to your account
                            </button>
                        </a>
                        {/* </Link> */}
                    </div>
                </React.Fragment>
            )
        }

        return null;
    }

    if (token === null || token === undefined) {
        return <LoadingFullPage />;
    }

    return (
        <React.Fragment>
            <div className="onboard-container">
                <Col xs={12} sm={7} md={5} xl={5} className="onboard-wrapper onboard-form">
                    <ForgotPass />
                </Col>
                <Col xs={0} sm={5} md={7} xl={7} className="onboard-wrapper onboard-slider">
                    <ParticlesBackground />
                    <p>Platform Percakapan <br />Kecerdasan Buatan Terbaik</p>
                </Col>
            </div>
        </React.Fragment>
    )
}

export default VerifyEmailPage