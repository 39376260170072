import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//FUNCTIONS
// import { fetchAuthUpdate } from '../../../../reducers/FetcherReducers';
// import { reqProfileData } from '../../../../reducers/AccountManagementReducers';

//Component
import { Badge, Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
// import NotifAlert from '../../NotifAlert/NotifAlert';

//CSS
import './Account.scss'

//ASSETS
import eye from "../../../../img/eye.svg"
import eyeoff from "../../../../img/eye-closed.svg"
import Input from '../../../../components/Input/Input';
import ModalConfirmPassword from './ModalConfirmPassword';
import InputCheckbox from '../../../../components/InputCheckbox/InputCheckbox';
import { toastNotification } from '../../../../modules/ToastNotification';
import { checkNameRegex, checkPasswordRegex } from '../../../../utils/utils';
import { fetchResetPassword, fetchUserDetail, fetchUserUpdate } from '../../../../reducers/UserManagementReducers';
import ModalChangeEmail from '../../../../components/Account/ModalChangeEmail';
import ModalVerifyEmail from '../../../../components/Account/ModalVerifyEmail';
import { useLocation } from 'react-router-dom';
import Loading from '../../../../components/Loading';


export default function ChangePass() {
    const dispatch = useDispatch();

	const { id_token, userDetail } = useSelector(state => state.UserManagementReducers);
    
    const [loading, setLoading] = useState(false);

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [photo, setPhoto] = useState(null);
    
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [newPass, setNewPass] = useState("");
    const [confirmNewPass, setConfirmNewPass] = useState("");

    const [showModalVerifyEmail, setShowModalVerifyEmail] = useState(false);
    const [showModalChangeEmail, setShowModalChangeEmail] = useState(false);
    const [showModalConfirmPassword, setShowModalConfirmPassword] = useState(false);

    useEffect(() => {
        dispatch(fetchUserDetail(id_token))
            .then(()=>{})
            .catch(()=>{});

        return () => {
        }
    }, []);

    useEffect(() => {
        // get username from userDetail data
        if ( userDetail !== null && userDetail !== undefined ) {
            // "avatar": "",
            // "email": "afarisya@gmail.com",
            // "firstname": "farisya",
            // "id_user": "afarisya",
            // "lastname": "adzkia"
            if ( userDetail.id_user !== undefined ) {
                setUsername(userDetail.id_user)
            }
            if ( userDetail.email !== undefined ) {
                setEmail(userDetail.email);
            }
            if ( userDetail.firstname !== undefined ) {
                setFirstname(userDetail.firstname);
            }
            if ( userDetail.lastname !== undefined ) {
                setLastname(userDetail.lastname);
            }
        }
    }, [userDetail])

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handleFirstnameChange = (e) => {
        setFirstname(e.target.value)
    }

    const handleLastnameChange = (e) => {
        setLastname(e.target.value)
    }

    const handleNewPassChange = (e) => {
        setNewPass(e.target.value);
    }

    const handleConfirmNewPassChange = (e) => {
        setConfirmNewPass(e.target.value);
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        if ( firstname.replace(/\s+/g, '') === "" || lastname.replace(/\s+/g, '') === "" ) {
            toastNotification("error", "please fill all columns");
            return;
        } else {
            const { result, error_info } = checkNameRegex(firstname+" "+lastname);
            if ( !result ) {
                toastNotification("error", error_info);
                return;
            }
        }

        if ( isChangePassword ) {
            if ( newPass !== confirmNewPass ) {
                toastNotification("error", "password tidak sama");
                return;
            } 
            else {
                const { result, error_info } = checkPasswordRegex(newPass);
                if ( !result ) {
                    toastNotification("error", error_info);
                    return;
                }
            }
        }
        
        setShowModalConfirmPassword(true);
    };

    const onConfirmPassword = (old_pass) => {

        setLoading(true);
        
        // const auth = profile_data.auth[0];

        // IdUser    string `json:"id_user"`
        // Email     string `json:"email"`
        // FirstName string `json:"firstname"`
        // LastName  string `json:"lastname"`
        // Avatar    string `json:"avatar"`
        // OldPassword string `json:"old_password"`
        // NewPassword string `json:"new_password"`

        const data = {
            id_user: username, 
            email: email, 
            firstname: firstname, 
            lastname: lastname,
            new_password: isChangePassword ? newPass : "",
            old_password: old_pass, 
        };

        dispatch(fetchUserUpdate(data))
            .then((res) => {
                setLoading(false);
                setShowModalConfirmPassword(false);
                setIsChangePassword(false);
                
                toastNotification("success", "Account updated");
            })
            .catch((err) => {
                setLoading(false);
        
                toastNotification("error", "Update account failed", err.toString());
            })
    }

    const handleChangeEmailClick = () => {
        if ( !userDetail.verified ) {
            setShowModalVerifyEmail(false);
        }
        setShowModalChangeEmail(true);
    }
    const handleCancelChangeEmailClick = () => {
        setShowModalChangeEmail(false);
        if ( !userDetail.verified ) {
            setShowModalVerifyEmail(true);
        }
    }

    const handleVerifyEmailClick = () => {
        setShowModalVerifyEmail(true);
    }

    const handleCreatePasswordClick = (e) => {
        e.preventDefault();
        toastNotification("warning", "Sending change password link to your email...")
        
        dispatch(fetchResetPassword(userDetail.email))
            .then((res) => {
                toastNotification("success", "Email Sent", "Please check your email")
            })
            .catch((err) => {     
                toastNotification("error", "Failed to send email", err.toString())    
            }) 
    }

    let button_submit_disabled = false;
    // if ( isChangePassword && ( newPass.length === 0 || confirmNewPass.length === 0 ) ) {
    //     button_submit_disabled = true;
    // }    
    

	// show loading page if userDetail empty
	if ( userDetail === null || userDetail === undefined ) { 
		return <Loading />;
	}

    return (
        <>
            <ModalVerifyEmail 
                closeButton={true}
                show={showModalVerifyEmail}
                onHide={() => setShowModalVerifyEmail(false)}
                handleChangeEmailClick={handleChangeEmailClick}
            />
            <ModalChangeEmail 
                show={showModalChangeEmail}
                onHide={() => setShowModalChangeEmail(false)}
                onCancel={handleCancelChangeEmailClick}
            />
            <ModalConfirmPassword 
                show={showModalConfirmPassword} 
                // show={true} 
                onHide={() => setShowModalConfirmPassword(false)} 
                onSubmit={onConfirmPassword}
                loading={loading}
            />
            
            <div className="account-page-main-container">
                <div className="account-page-content-container">
                    <div className="head">
                        <Col xs={12}>
                            <div className="title">Your Account</div>
                        </Col>
                    </div>
                    <div className="body">
                        <form onSubmit={handleFormSubmit}>
                            <Input 
                                size="md"
                                alt="input-username" 
                                type="text" 
                                placeholder="Username"
                                value={username}
                                onChange={handleUsernameChange}
                                disabled 
                            />
                            <div className="email">
                                <Input 
                                    size="md"
                                    alt="input-email" 
                                    type="email" 
                                    placeholder="Email"
                                    value={email}
                                    onChange={handleEmailChange} 
                                    disabled 
                                />
                                { userDetail.verified ? 
                                    <>
                                        <Badge variant="success">Verified</Badge>
                                        <a href="#" onClick={handleChangeEmailClick}>
                                            Change Email
                                        </a>
                                    </>
                                    :
                                    <>
                                        <Badge variant="danger">Not Verified</Badge>
                                        <a href="#" onClick={handleVerifyEmailClick}>
                                            Verify Now
                                        </a>
                                    </>
                                }
                            </div>
                            <Row>
                                <Col sm={12} md={6}>
                                    <Input 
                                        size="md"
                                        alt="input-firstname" 
                                        type="text" 
                                        placeholder="Firstname"
                                        value={firstname}
                                        onChange={handleFirstnameChange}
                                    />
                                </Col>
                                <Col sm={12} md={6}>
                                    <Input 
                                        size="md"
                                        alt="input-lastname" 
                                        type="text" 
                                        placeholder="Lastname"
                                        value={lastname}
                                        onChange={handleLastnameChange}
                                    />
                                </Col>
                            </Row>

                            { userDetail.password_created ?
                                <>
                                    <InputCheckbox 
                                        size="md" 
                                        label="Change password" 
                                        checked={isChangePassword} 
                                        onChange={(e) => setIsChangePassword(e.target.checked)}
                                    />
                                    { isChangePassword &&
                                        <>
                                            <Input 
                                                size="md"
                                                alt="input-new-password" 
                                                type="password" 
                                                placeholder="New Password"
                                                value={newPass}
                                                onChange={handleNewPassChange} 
                                                showTogglePassword={true}
                                            />
                                            <Input 
                                                size="md"
                                                alt="input-confirm-new-password" 
                                                type="password" 
                                                placeholder="Confirm New Password"
                                                value={confirmNewPass}
                                                onChange={handleConfirmNewPassChange} 
                                                showTogglePassword={true}
                                            />
                                        </>
                                    }
                                </>
                                :
                                <div className="create-password">
                                    <span>You haven't create password. </span>
                                    <a href="#" onClick={handleCreatePasswordClick}
                                        // className="btn btn-secondary" 
                                        // id="submit"
                                        // disabled={button_submit_disabled} 
                                    >
                                        Send link to create password
                                    </a>
                                </div>
                            }

                            <button type="submit" 
                                className="submit-button-onboard" 
                                id="submit"
                                disabled={button_submit_disabled} 
                            >
                                Save
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}