import React, { useEffect } from "react";
import { withRouter } from 'react-router'; 

function Redirector(props) {
    useEffect(() => {
        // console.log(props.match)
    }, [])

    return (
        <div>HAHAHAHAHA</div>
    )
}

export default withRouter(Redirector);