import { withRouter } from 'react-router'; 
import styled from 'styled-components';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';

import BaseWalkthrough, { basic_option } from './BaseWalkthrough';
import { useState } from 'react';
import { useEffect } from 'react';


const StepContent = styled.div`
	font-size: 20px;
`;

function WalkthroughNotification(props) {
	const { callback=()=>{} } = props;

    const _callback = (data) => {
		callback(data);
    }

	const steps = [
		{
			content: (
				<StepContent>
					Congratulation! you've got free quota for Diktein, Speech to Text and Text to Speech API. Click on the notification to check it now!
				</StepContent>
			),
			...basic_option,
			placement: 'left',
			disableBeacon: true,
			// title: 'Menu',
			target: ".notification-row",
		}
	];

	return (
        <>
            <BaseWalkthrough steps={steps} callback={_callback} />
        </>
    )
}

export default WalkthroughNotification;