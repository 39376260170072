import { NATSProtocolIncomingSwitcher } from "./NATSProtocolIncoming";
import { nats } from "./NATSMiddleware";
import { NC_TTS_REQ, NC_USER_NOTIFICATION } from "./NATSTopics";
import store from "../store";
import { fetchRcvTts } from "../reducers/TTSReducers";
import { rcvTranscriptResult } from "../reducers/STTReducers";

export var NATSRequester = (natss, topic, msg) => {     
    natss.request(topic, msg, {'max':1}, function(response) {
        NATSProtocolIncomingSwitcher(topic, response);
    });
}

var userNotificationListener = null;
var utteranceListener = null;

export var NatsUserNotificationSubcribers = (id_user) => {
    if ( userNotificationListener !== null ) return;

    const topic = `${NC_USER_NOTIFICATION}.${id_user}`
    
    // console.log("NatsUserNotificationSubcribers " + topic)

    userNotificationListener = nats.subscribe(topic, function(response) {
        // console.log(response)
        
        if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) { 
            console.log("====== INCOMING DATA ======");
            console.log(topic)
            console.log(response)
            console.log("===========================");
        }

        // store.dispatch(rcvNewUserNotification(JSON.parse(response)));
    });
}

export var NatsUserNotificationUnsubcribers = () => {
    if ( userNotificationListener === null ) return;

    // console.log("NatsUserNotificationUnsubcribers")
    nats.unsubscribe(userNotificationListener);

    userNotificationListener = null;
}

export var NatsSttSubcribers = (response_channel) => {
    // console.log("NatsSttSubcribers " + response_channel)
    utteranceListener = nats.subscribe(response_channel, function(response) {
        // console.log(response)
        
        if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) { 
            // console.log("====== INCOMING DATA ======");
            // console.log(response_channel)
            // console.log(response)
            // console.log("===========================");
        }

        store.dispatch(rcvTranscriptResult(JSON.parse(response)));
    });
}

export var NatsSttUnsubcribers = () => {
    // console.log("NatsSttUnsubcribers")
    nats.unsubscribe(utteranceListener);

    utteranceListener = null;
}