import React, { useEffect, useState } from "react";

const Icon = () => {
    return (
        <svg height="20" width="20" viewBox="0 0 20 20">
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </svg>
    );
};



const Dropdown = ({ id,placeHolder, options }) => {
    //var language = "";
    //const dispatch = useDispatch();
    
    const [showMenu, setShowMenu] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    //console.log(language)
    useEffect(() => {
        const handler = () => setShowMenu(false);
        window.addEventListener("click", handler);

        return () => {
            window.removeEventListener("click", handler);
        };
    });
    
    const handlerInputClick = (e) => {
        e.stopPropagation();
        setShowMenu(!showMenu);
    };

    const getDisplay = () => {
        if(selectedValue){

            //updateLanguage(language);
            return selectedValue.label;
        }        
        //updateLanguage("id");
        return placeHolder;
    };
    
    const getValue = () => {
        if(selectedValue){

            //updateLanguage(language);
            return selectedValue.value;
        }        
        //updateLanguage("id");
        return "id";
    };
    const onItemClick = (option) =>{
        setSelectedValue(option);
       
        };

    const isSelected = (option) =>{
        if(!selectedValue){
            return false;
        }
      
        return selectedValue.value === option.value;
    };

    //console.log(language)

    return (
        <div className="dropdown-container dropdown_lang"  >
            <div onClick={handlerInputClick} className="dropdown-input">
                <div className="dropdown-selected-value" id={id} value={getValue()}>{getDisplay()}</div>
                <div className="dropdown-tools">
                <div className="dropdown-tool">
                <Icon />
                </div>
                </div>                
                </div>
            {showMenu && ( <div className="dropdown-menu-language" style={{left:0}}>

                    {options.map((option) => (
                        <div 
                        onClick={() => onItemClick(option)}
                        key={option.value} 
                        className={`dropdown-item ${isSelected(option) && "selected" }`} >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>    
        
    );
};

export default Dropdown;