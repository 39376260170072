import { toastNotification } from "../modules/ToastNotification"
import { history } from "../store";
import { fetchRefreshToken } from "./UserManagementReducers";
import axios from 'axios';
import ava from '../img/ava.svg'

// import {service_packages_dummy} from "../dummydata"

// Actions
const REQ_SERVICE_LIST = "ServicesReducers/REQ_PAYMENT";
const REQ_SERVICE_PACKAGE = "ServicesReducers/REQ_SERVICE_PACKAGE";
const RCV_SERVICE_PACKAGE_SELECTED = "ServicesReducers/RCV_SERVICE_PACKAGE_SELECTED";
const RCV_TESTIMONY_DATA = "ServicesReducers/RCV_TESTIMONY_DATA";

// Action Creators
/* 
 *  !LEGEND! 
 *      req = request // set
 *      rcv = receive // get
 *      fch = fetch
 *      snd = send
 */

export const reqServiceList = data => ({
    type: REQ_SERVICE_LIST,
    payload: {
        service_list: data,
        status: "data_service_list"
    }
});

export const reqServicePackage = data => ({
    type: REQ_SERVICE_PACKAGE,
    payload: {
        service_package: data,
        status: "data_service_package"
    }
});

export const updateServicePackage = data => ({
    type: RCV_SERVICE_PACKAGE_SELECTED,
    payload: {
        service_package_selected: data,
        status: "data_service_package_selected"
    }
});

export const saveTestimonyData = data => ({
    type: RCV_TESTIMONY_DATA,
    data: data
});

export const captchaGoogle = (tokenCaptcha,token) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        //const url = `https://www.google.com/recaptcha/api/siteverify?secret=${window._env_.REACT_APP_SECRET_KEY}&response=${token}`
        const url = `${process.env.REACT_APP_API_URL}/v2/prod/checkCaptcha`;

        // console.log(url)
        // console.log(token)
        const body = {
            "bk": {
                "token_captcha": tokenCaptcha
            }
        };

        const config = {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                "Authorization": "Bearer " + token
            }
        }

        axios.post(url, JSON.stringify(body), config)
            .then(res => {
                //console.log(res);
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
};


export const fetchServiceList = (id_token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        const body = {
            id_token: id_token,
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/" + process.env.REACT_APP_SYSTEM_STATUS + "/service/list/", {
            headers: {
                "Content-Type": 'application/json'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw (response.status + " " + response.statusText)
                }
            })
            .then(result => {

                if (result.status === "success") {
                    let serviceList = (result.service) ? result.service : []
                    dispatch(reqServiceList(serviceList));

                    resolve(serviceList);

                } else {
                    if (result.error === "token expired") {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchServiceList(id_token))
                                    .then(() => { })
                                    .catch(() => { });
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                    }
                    else {
                        throw (result.error)
                    }
                }
            })
            .catch(err => {
                dispatch(reqServiceList([]));
                toastNotification(
                    'error',
                    'Gagal memuat daftar layanan',
                    err.toString()
                );
                reject(err)
            });
    });
};

export const fetchServicePackage = (id_service) => (dispatch) => {
    return new Promise((resolve, reject) => {
        const body = {
            id_service: parseInt(id_service)
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/" + process.env.REACT_APP_SYSTEM_STATUS + "/service/package/", {
            headers: {
                "Content-Type": 'application/json'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw (response.status + " " + response.statusText)
                }
            })
            .then(result => {
                // console.log(result)
                if (result.status === "success") {
                    dispatch(reqServicePackage(result.packages))

                    resolve(result.packages)

                }
                else {
                    if (result.error === "token expired") {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchServicePackage(id_service))
                                    .then(() => { })
                                    .catch(() => { });
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                    }
                    else {
                        throw (result.error)
                    }
                }
            })
            .catch(err => {
                // console.log(err)
                if (window.location.pathname !== "/tts" && window.location.pathname !== "/stt") {
                    toastNotification(
                        'error',
                        'Gagal memuat data paket layanan',
                        err.toString()
                    );
                }
                reject(err.toString())
            });
    });
};

export const fetchAllPackagesOfAllServices = (id_token) => (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(fetchServiceList(id_token))
            .then((serviceList) => {

                let count_fetchServicePackage = 0;
                let servicePackages = [];

                serviceList.forEach((s) => {
                    const id_service = s.id_service;

                    dispatch(fetchServicePackage(id_service))
                        .then((res) => {

                            count_fetchServicePackage += 1;

                            servicePackages = [...servicePackages, ...res]

                            if (count_fetchServicePackage === serviceList.length) {
                                resolve(servicePackages)
                            }

                        })
                        .catch((err) => {
                            reject(err)
                        })

                })

            })
            .catch((err) => {
                reject(err)
            })

    });
};

export const fetchTestimonyList = (type, offset, limit) => (dispatch) => {
    return new Promise((resolve, reject) => {
        // dummy
        let data = [
            {
                title: "Menyajikan transkrip wawancara secara cepat, tepat, dan akurat",
                name: "Satria Adi Putra, M.H.",
                company: "Pejabat Negara",
                position: "Kepala Bagian Informasi",
                statement: "Dengan menggunakan Notula, kami dapat menghemat waktu dan tenaga yang sangat banyak untuk menyajikan transkrip wawancara secara cepat, tepat, dan akurat.",
                img: ava
            },
        ];
        let res = {
            status: "success",
            error: "",
            data: data,
            type: type,
            offset: offset,
            limit: limit
        }
        // setTimeout(() => {
        dispatch(saveTestimonyData(res));
        resolve(res)
        // }, 1000)
        return

        const body = {
            type: type, // web/tts/stt/notula
            offset: offset,
            limit: limit
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/" + process.env.REACT_APP_SYSTEM_STATUS + "/testimoni/", {
            headers: {
                "Content-Type": 'application/json'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw (response.status + " " + response.statusText)
                }
            })
            .then(result => {
                if (result.status === "success") {
                    dispatch(saveTestimonyData(result));
                    resolve(result)

                } else {
                    throw (result.error)
                }
            })
            .catch(err => {
                dispatch(fetchTestimonyList(type, offset, limit))
                    .then(() => { })
                    .catch(() => { });
                // reject(err)
            });
    });
};

// Reducer's initial state
const initialState = {
    service_list: null,
    service_package: [],
    service_package_selected: null,
    testimony_data: [],
}

// Reducers
export default function ServiceReducers(state = initialState, action) {
    switch (action.type) {
        case REQ_SERVICE_LIST:
            return {
                ...state,
                status: action.payload.status,
                service_list: action.payload.service_list
            };
        case REQ_SERVICE_PACKAGE:
            return {
                ...state,
                status: action.payload.status,
                service_package: action.payload.service_package
            };
        case RCV_SERVICE_PACKAGE_SELECTED:
            return {
                ...state,
                status: action.payload.status,
                service_package_selected: action.payload.service_package_selected
            };
        case RCV_TESTIMONY_DATA:
            return {
                ...state,
                testimony_data: action.data
            };
        default:
            return state;
    }
}