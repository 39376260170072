import React, { useEffect, useState, useRef } from "react";
import { useSetState } from 'react-use';
import { history } from "../../../store";
import { useKeycloak } from '@react-keycloak/web';

// Functions

// Components
import { Link } from "react-router-dom";
import { Col, Spinner } from "react-bootstrap";
import LoadingFullPage from "../../../components/LoadingFullPage";
import Loading from "../../../components/Loading";
import ParticlesBackground from '../../../components/ParticlesBackground/ParticlesBackground';

// ETC
import eye from "../../../img/eye.svg"
import eyeoff from "../../../img/eye-closed.svg"
import warning from "../../../img/warning.svg"
import back from "../../../img/back.svg"
import close from "../../../img/close.svg"
import sent from "../../../img/email-sent.svg"


function NewPasswordPage(props) {
    const initialState = {
        token: null,
        isVerifyingToken: true,
        tokenVerified: false,

        showError: false,
        errorMessage: "",
        showChangeDone: false,
        isLoading: false,
    };

	const { keycloak } = useKeycloak();
    
    const [state, setState] = useSetState({...initialState});

    const params = new URLSearchParams(history.location.search);
    const token = params.get('token');

    useEffect(() => {
        if ( keycloak.authenticated ) {
            keycloak.logout();
        }

        if ( token === null || token === undefined ) {
            history.push("/")
            // setState({
            //     isLoading: false,
            //     showError: true,
            //     errorMessage: "token invalid"
            // });

        } else {
            setState({
                token: token
            })
            verifyToken(token);
        }
    }, [])

    const verifyToken = (token) => {
        // console.log("verify token")
        const body = {
            token: token
        };
        setState({
            isLoading: true,
            showError: false
        });
        //   console.log(body);

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/" + process.env.REACT_APP_SYSTEM_STATUS + "/user/reset/verifytoken/", {
            headers: {
                accept: "application/json",
                'Content-Type': 'application/json; charset=utf-8'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw (response.status + " " + response.statusText)
                }
            })
            .then(result => {
                //   console.log(result);
                if (result.status === "success") {
                    setState({
                        isLoading: false,
                        isVerifyingToken: false,
                        tokenVerified: true
                    });
                } else {
                    const error = (result.error !== undefined && result.error !== "" ? result.error : "failed");
                    throw (error)
                }
            })
            .catch(err => {
                setState({
                    isLoading: false,
                    isVerifyingToken: false,
                    tokenVerified: false,
                    showError: true,
                    errorMessage: "ERROR: " + err.toString()
                })
            });
    }

    const handleSubmit = (e, form_state) => {
        if (state.isLoading) return;

        e.preventDefault();

        setState({
            ...form_state
        }, () => {
            console.log("SUCCESS")
        })
        const token = state.token;
        const email = form_state.email;
        const password = form_state.password;
        const repassword = form_state.repassword;

        if (
            email.replace(/\s+/g, '') === "" ||
            password.replace(/\s+/g, '') === "" ||
            repassword.replace(/\s+/g, '') === ""
        ) {
            setState({ 
                showError: true,
                errorMessage: "Silakan isi semua kolom"
            })
            return;
        } else if (password !== repassword) {
            setState({ 
                showError: true,
                errorMessage: "Password tidak sama"
            })
            return;
        }

        const body = {
            token: token,
            email: email,
            password: password
        };
        setState({
            isLoading: true,
            showError: false
        });
        //   console.log(body);

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/" + process.env.REACT_APP_SYSTEM_STATUS + "/user/reset/newpassword/", {
            headers: {
                accept: "application/json",
                'Content-Type': 'application/json; charset=utf-8'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw (response.status + " " + response.statusText)
                }
            })
            .then(result => {
                //   console.log(result);
                if (result.status === "success") {
                    setState({
                        isLoading: false,
                        showForgotPass: false,
                        showChangeDone: true,
                    });
                } else {
                    const error = (result.error !== undefined && result.error !== "" ? result.error : "failed");
                    throw (error)
                }
            })
            .catch(err => {
                var errorMessage = "ERROR " + err.toString();
                if (errorMessage.indexOf("expired") > -1) {
                    errorMessage = "Link ini sudah expired. Silakan kirim ulang permintaan reset password."
                } else if (errorMessage.indexOf("account not exist") > -1) {
                    errorMessage = "Akun tidak sesuai atau tidak terdaftar"
                }

                setState({
                    isLoading: false,
                    showError: true,
                    errorMessage: errorMessage
                })
            });
    }

    function AlertDismissibleError() {
        let showError = state.showError;

        if (showError) {
            return (
                <p className="error-alert" dismissible="true">
                    <img src={warning} alt="error-dismissible" />  {state.errorMessage}
                </p>
            );
        }

        return null;
    }


    if ( keycloak.authenticated ) {
        return <LoadingFullPage />
    }

    return (
        <React.Fragment>
            <div className="onboard-container">
                <Col xs={12} sm={7} md={5} xl={5} className="onboard-wrapper onboard-form">
                { !state.tokenVerified ?
                    ( state.isVerifying ?
                        <React.Fragment>
                            <div className="form-wrapper">
                                <p className="onboard-header">Verifying Link</p>
                                <p className="onboard-header-span">Please wait while we are verifying your link</p>
                                <Loading />
                            </div>
                        </React.Fragment>
                        :
                        ( state.showError ?
                            <React.Fragment>
                                <div className="form-wrapper">
                                    <p className="onboard-header">Sorry!</p>
                                    <p className="onboard-header-span">Looks like we encounter some error</p>
                                    <AlertDismissibleError />
                                    <Link to="/">
                                        <button type="submit" className="submit-button-onboard" id="submit">
                                            Back to home page
                                        </button>
                                    </Link>
                                </div>
                            </React.Fragment>
                            :
                            null
                        )
                    )
                    :
                    ( !state.showChangeDone ?
                        <React.Fragment>
                            <img className="button-back" src={back} alt="icon-back" onClick={() => props.history.go(-1)} />
                            <div className="form-wrapper">
                                <p className="onboard-header">Change Password</p>
                                <p className="onboard-header-span">Enter your new password.</p>
                                {AlertDismissibleError()}
                                <FormChangePass
                                    handleSubmit={handleSubmit} 
                                    isLoading={state.isLoading}
                                />
                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <img className="button-back" src={close} alt="icon-back" onClick={() => props.history.push("/")} />
                            <div className="form-wrapper">
                                <div className="onboard-img-sent"><img src={sent} alt="sent-email" /></div>
                                <p className="onboard-header">Password updated!</p>
                                <p className="onboard-header-span">
                                    Now you can login with your new password
                                </p>
                                <Link to="/login">
                                    <button className="submit-button-onboard" id="submit">
                                    Login to your account
                                    </button>
                                </Link>
                            </div>
                        </React.Fragment>
                    )
                }
                </Col>
                <Col xs={0} sm={5} md={7} xl={7} className="onboard-wrapper onboard-slider">
                    <ParticlesBackground />
                    <p>Platform Percakapan <br />Kecerdasan Buatan Terbaik</p>
                </Col>
            </div>
        </React.Fragment>
    )
}

function FormChangePass(props) {
    const { 
        handleSubmit=()=>{},
        isLoading=false,
    } = props;
    
    const initialState = {
        email: "",
        password: "",
        repassword: "",
        passInputType: "password",
        repassInputType: "password",
    };
    const [state, setState] = useSetState({...initialState});
    
    const inputRef1 = useRef();
    const inputRef2 = useRef();
    const inputRef3 = useRef();
    
    const showHide = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setState({ 
            passInputType: state.passInputType === "text" ? "password" : "text"
        });
    }

    const showHideRe = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setState({ 
            repassInputType: state.repassInputType === "text" ? "password" : "text"
        });
    }

    const handleEmailChange = ({ target }) => {
        console.log("handleEmailChange")
        console.log(target.value)
        setState({ 
            email: target.value,
            showPassEmpty: false,
            showError: false
        });
    }

    const handlePasswordChange = ({ target }) => {
        setState({ 
            password: target.value,
            showPassEmpty: false,
            showError: false 
        });
    }

    const handleRePasswordChange = ({ target }) => {
        setState({ 
            repassword: target.value,
            showPassEmpty: false,
            showError: false 
        });
    }

    return (
        <form onSubmit={(e) => handleSubmit(e, state)}>
            <div className="form-input-onboard">
                <input
                    ref={inputRef1}
                    type="email"
                    id="email"
                    autoComplete="off"
                    placeholder=" "
                    value={state.email}
                    onChange={handleEmailChange} />
                <label onClick={() => inputRef1.current.focus()}>email</label>
            </div>
            <div className="form-input-onboard">
                <input
                    ref={inputRef2}
                    type={state.passInputType}
                    id="password"
                    autoComplete="off"
                    placeholder=" "
                    value={state.password}
                    onChange={handlePasswordChange} />
                <label onClick={() => inputRef2.current.focus()}>password</label>
                <span className="password-show" onClick={showHide}>
                    {state.passInputType === "text" ?
                        <img src={eye} className="" alt="show-password" />
                        :
                        <img src={eyeoff} className="" alt="hide-password" />
                    }
                </span>
            </div>
            <div className="form-input-onboard">
                <input
                    ref={inputRef3}
                    type={state.repassInputType}
                    id="repassword"
                    autoComplete="off"
                    placeholder=" "
                    value={state.repassword}
                    onChange={handleRePasswordChange}
                />
                <label onClick={() => inputRef3.current.focus()}>re-type password</label>
                <span className="password-show" onClick={showHideRe}>
                    {state.repassInputType === "text" ?
                        <img src={eye} className="" alt="show-password" />
                        :
                        <img src={eyeoff} className="" alt="hide-password" />
                    }
                </span>
            </div>

            <button type="submit" className="submit-button-onboard" id="submit">
                { isLoading ?
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> :
                    "Change Password"
                }
            </button>
        </form>
    )
}

export default NewPasswordPage;