import React, { useState, useEffect } from 'react'
import { Container, Row, Col, ProgressBar } from "react-bootstrap";
import './Usecase.scss'
import arright from '../../img/landing/arrow-right.svg'
import BKAudioPlayer from '../../page/landingpage/BKAudioPlayer/BKAudioPlayer';
import news from './Audio/berita.wav'
import pidato from './Audio/pidato.wav'
import cerita from './Audio/cerita.wav'
import asisten from './Audio/asisten.wav'

function UsecaseTTS() {
    const ttslist = [
        {
            title: "Berita",
            text: "Membacakan berita dengan tepat dan sesuai",
            longtext: "Gubernur Jawa Barat, Ridwan Kamil menyatakan siap mendukung penuh proses kegiatan digitalisasi aksara Sunda yang saat ini tengah diupayakan Pandi dan komunitas pegiat aksara Sunda lainnya. Dukungan ini dirasa perlu dalam menghadapi tantangan ke depan terutama terkait kekayaan budaya Nusantara yang beragam.",
            audio: news
        },
        {
            title: "Pidato",
            text: "Membacakan dokumen Pidato",
            longtext: "Hadirin sekalian, Jika kita menjaga kebersihan lalu ditiru oleh anak-anak kita dan orang-orang dekat kita, maka kita pun akan mendapat manfaatnya. Oleh karenanya, mari ajarkan sedini mungkin terhadap anak-anak tentang pentingnya menjaga kebersihan agar kelak mereka menjadi anak yang sehat, cerdas, dan suka berbuat kebaikan.",
            audio: pidato
        },
        {
            title: "Pembaca Buku",
            text: "Membacakan isi buku",
            longtext: "Pemuda itu hanya mengangguk sembari sang tuan beranjak pergi dan menghilang dalam kegelapan. Kepalanya tertunduk sambil tangannya di peti untuk menopang tubuhnya. Terlihat begitu berat beban yang dibawa pemuda itu. Dia mencoba mengangkat kepalanya dan menegakkan tubuhnya. Hela nafas diikuti langkah menuju kegelapan, meninggalkan peti mati tersebut di tengah kegelapan yang dingin.",
            audio: cerita
        },
        {
            title: "Asisten Suara",
            text: "Mengucapkan informasi yang anda inginkan",
            longtext: "Selamat pagi dan terima kasih telah menghubungi layanan panggilan Bahasa Kita. Saya Beka asisten suara yang akan membantu anda hari ini. Silahkan ucapkan apa yang ingin anda ketahui mengenai Bahasa Kita?",
            audio: asisten
        }
    ];
    const [indexselected, setIndexSelected] = useState(null);
    const [audioUrl, setAudioUrl] = useState(null);
    // const [progress, setProgress] = useState(60);

    // useEffect(() => {
    // setAudioUrl(ttslist[0].audio)
    // console.log("componentDidMount");
    // return () => { }
    //   }, []);


    const selectIndex = (i) => {
        // console.log(ttslist[i].audio)
        setIndexSelected(i);
        // setAudioUrl("");
        setAudioUrl(ttslist[i].audio);
    }

    const onAudioPlayStateChange = (state) => {
        //console.log(state)
    }

    return (
        <div className="side-u-wrapper">
            <div className="side-u-title">Usecase Penggunaan TTS</div>
            <Row className="side-u-mini-wrap landing-wrapper">
                <Col xs={12} md={5} lg={6}>
                    <div className="side-u-container">
                        {
                            ttslist.map((item, i) => {
                                return (
                                    <div key={item.title + i} className={i + 1 === ttslist.length ? "list-item" : "list-item border-b"} onClick={() => selectIndex(i)}>
                                        {i === indexselected && i !== null && i !== undefined ? <img src={arright} alt="arrow" id="arrow" /> : null}
                                        <h5 className={i === indexselected ? "title active" : "title"}>{item.title}</h5>
                                        <p className="text-p">{item.text}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Col>
                <Col xs={12} md={7} lg={6}>
                    <div className="side-ur-container">
                        <div className="side-ur-textarea-wrapper">
                            <textarea
                                id="side-ur-textarea"
                                type="text"
                                onChange={e => setIndexSelected(e.target.value)}
                                value={indexselected !== null && indexselected !== undefined ? ttslist[indexselected].longtext : ""}
                            />
                            <div className="audio-play-wrap-full">
                                <BKAudioPlayer key={"BKAudioPlayer-" + indexselected}
                                    demo={true}
                                    audioFile={audioUrl}
                                    onAudioPlayStateChange={(state) => onAudioPlayStateChange(state)}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>

    )
}
export default UsecaseTTS;