import React from 'react'
import { Spinner } from 'react-bootstrap'

const Loading = () => {
    return (
        <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <Spinner
                as="div"
                animation="border"
                size="md"
                role="status"
                aria-hidden="true"
                style={{display: "block", margin: "20px auto"}}
            />
        </div>
    )
}

export default Loading
