
import axios from 'axios';
import {
    req_tts
} from '../middleware/NATSProtocolOutgoing';
import store from '../store';
//action types
export const TTS_REQ = "TTSReducers/TTS_REQ";
export const TTS_RCV = "TTSReducers/TTS_RCV";
export const UPDATE_TTS_TOKEN = "STTReducers/UPDATE_STT_TOKEN";


export const reqTts = data => ({
    type: TTS_REQ,
    payload: {
        data_req_tts: data,
        status: "req_tts"
    }
});

export const rcvTts = data => ({
    type: TTS_RCV,
    payload: {
        data_rcv_tts: data,
        status: "rcv_tts"
    }
});

export const fetchReqTts = (data) => (dispatch) => {
    var text_req = data;
    req_tts(text_req);
    dispatch(reqTts(text_req));
}

export const fetchRcvTts = (msg) => (dispatch) => {
    // console.log(msg)
    // if (msg.length !== 0 || msg !== null ) {
    dispatch(rcvTts(msg));
    // }
}


// NEW API

export const fetchTTSToken = () => (dispatch) => {
    // console.log("fetchSttToken")
    return new Promise((resolve, reject) => {
        const email = process.env.REACT_APP_EMAIL;
        const password = process.env.REACT_APP_PASSWORD;

        const basicBase64 = Buffer.from(`${email}:${password}`).toString('base64');
        // console.log(basicBase64)

        const url = `${process.env.REACT_APP_API_URL}/v2/prod/getToken`;        
        //const url = `${process.env.REACT_APP_API_URL}/v2/prod/tts/getToken`;
        //const url = `${process.env.REACT_APP_SERVER_URL}/app/console/${process.env.REACT_APP_SYSTEM_STATUS}/service/gettoken/`
        // const url = `https://apidev.bahasakita.co.id/v2/prod/getToken`;

        // fetch(url, {
        //     headers: {
        //         "Authorization"	: "Basic "+basicBase64,
        //     },
        //     method: "POST"
        // })

        // const body = {
        //     id_token: store.getState().UserManagementReducers.id_token
        // };

        const body = {
            bk: { data: { service: "tts" } }
        };

        fetch(url, {
            headers: {
                "Authorization"	: "Basic "+basicBase64,
                "Content-Type": 'application/json'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw (response.status + " " + response.statusText)
                }
            })
            .then(result => {
                // console.log(result)
                const tts_token = result.bk.data.token;
                // console.log(stt_token)

                // console.log(result)
                // let tts_token;
                // for (let count in result.tokens) {
                //     console.log("datatoken :", result.tokens[count])

                //     if (result.tokens[count].type === "TTS") {
                //         tts_token = result.tokens[count].token;
                //         break;
                //     }
                // }
                console.log("tts_token :", tts_token)

                dispatch(updateTTSToken(tts_token))

                resolve(tts_token)


            })
            .catch((err) => {
                reject(err.toString())
            })
    })
}

export const updateTTSToken = (token) => ({
    type: UPDATE_TTS_TOKEN,
    ttsToken: token
});

export var cancelConvertTTSTokenSource = null;

export const cancelConvertTTS = () => () => {
    if (cancelConvertTTSTokenSource === null) return;

    // Cancel request
    cancelConvertTTSTokenSource.cancel();
    cancelConvertTTSTokenSource = null
}

export const convertTextToSpeech = (label, text) => (dispatch, getState) => {
    // console.log("convertTextToSpeech")

    return new Promise(async (resolve, reject) => {

        cancelConvertTTSTokenSource = axios.CancelToken.source();

        const basicBase64 = getState().TTSReducers.ttsToken;
        console.log(basicBase64)

        const url = `${process.env.REACT_APP_API_URL}/v2/prod/tts/sync`;
        // const url = `https://apidev.bahasakita.co.id/v2/prod/tts/sync`;

        const body = {
            "bk": {
                "data": {
                    "label": label,
                    "text": text
                },
                "config": {
                    "volume": 1,
                    "pitch": "normal",
                    "speed": 1.0
                }
            }
        };

        const config = {
            cancelToken: cancelConvertTTSTokenSource.token,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                "Authorization": "Basic " + basicBase64,
            }
        }

        axios.post(url, JSON.stringify(body), config)
            // fetch(url, {
            //     cancelToken: cancelConvertTTSTokenSource.token,
            //     headers: {
            //         'Content-Type': 'application/json; charset=utf-8',
            //         "Authorization": "Basic "+basicBase64,
            //     },
            //     method: "POST",
            //     body: JSON.stringify(body)
            // })
            //     .then(response => {
            //         if ( response.ok ) {
            //             return response.json();
            //         } else {
            //             throw(response.status+" "+response.statusText)
            //         }
            //     })
            .then((result) => {
                // console.log(result)

                resolve(result.data)
            })
            .catch((err) => {
                // console.log(err)

                reject(err.toString())
            })
    })
}

// Reducer's initial state
const initialState = {
    data_rcv_tts: {},
    ttsToken: ''
};



// Async tts
export const TTSASnyc = (data, typeData, language, label, volume, pitch, speed) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData()


        if (typeData === "File") {
            formData.append("file", data);
        } else if (typeData === "Link") {
            formData.append("url", data.trim());

        } else {
            formData.append("text", data.trim());

        }

        formData.append("language", language);
        formData.append("label", label);
        formData.append("volume", volume);
        formData.append("pitch", pitch);
        formData.append("speed", speed);


        const token = getState().TTSReducers.ttsToken;

        const url = `${process.env.REACT_APP_API_URL}/v2/prod/tts/async`;
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                //'Content-Type': 'application/json; charset=utf-8',
                "Authorization": "Bearer " + token
            }
        }
        // console.log('Data Post:',formData)
        //axios.post(url, JSON.stringify(body), config)
        axios.post(url, formData, config)
            .then((result) => {
                console.log('result:', result)
                resolve(result.data)
            })
            .catch((err) => {
                // console.log(err)
                reject(err)
            })
    })
}


/********** GET - RETRIEVE TRANSCRIPT **********/
export const retrieveTTS = (path) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {

        const token = getState().TTSReducers.ttsToken;

        // console.log("URL :", path);
        // const url = `https://apidev.bahasakita.co.id/v1/prod/stt/upload`;


        fetch(path, {
            headers: {
                "Authorization": "Bearer " + token,
            },
            method: "GET"
        })
            .then(response => {
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                // When no more data needs to be consumed, close the stream
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            })
            // Create a new response out of the stream
            .then((stream) => new Response(stream))
            // Create an object URL for the response
            .then((response) => response.blob())
            .then((blob) => resolve(blob))
            .catch((err) => reject(err))
    })
}

export const retrieveTTSUUID = (uuid) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {

        const token = getState().TTSReducers.ttsToken;
        // console.log("URL :", path);
        // const url = `https://apidev.bahasakita.co.id/v1/prod/stt/upload`;
        const config = {
            headers: {
                "Authorization": "Bearer " + token
            }
        }

        const url = `${process.env.REACT_APP_API_URL}/v2/prod/tts/async/content/${uuid}`;
        axios.get(url, config)
            .then((response) => {
                resolve(response)
            })
            .catch((err) => {
                reject(err)
            })
    }
)}


// Reducers

export default function TTSReducers(state = initialState, action) {
    switch (action.type) {
        case TTS_REQ:
            return {
                ...state,
                status: action.payload.status
            }
        case TTS_RCV:
            return {
                ...state,
                status: action.payload.status,
                data_rcv_tts: action.payload.data_rcv_tts
            }
        case UPDATE_TTS_TOKEN:
            return {
                ...state,
                ttsToken: action.ttsToken
            };
        default:
            return state;
    }
}