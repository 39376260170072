import mammoth from 'mammoth'
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { cancelConvertTTS, convertTextToSpeech, fetchReqTts, fetchTTSToken } from "../../reducers/TTSReducers"
import store, { history } from "../../store"

// COMPONENTS
import { Image, Button, Row, Col } from 'react-bootstrap'
import FooterLanding from '../../components/footerlanding/FooterLanding'
import BilingPackage from "../../components/BilingPackage/BilingPackage"
import NavbarService from '../../components/navbar/NavbarService';

//ASSETS
import bubble from "../../img/landing/bubble3.svg"
import video from "../../img/landing/videotts.svg"
import play from "../../img/landing/playvideo.svg"
import itemmenu1 from "../../img/landing/itemmenu1.svg"
import itemmenu2 from "../../img/landing/itemmenu2.svg"
import itemmenu3 from "../../img/landing/itemmenu3.svg"
import kontakkami from "../../img/landing/kontakkami.png"
import landing2 from "../../img/landing/TTS.png"
import home from '../../img/landing/home-icon.svg'
import down from '../../img/landing/c-down.svg'

//ANIMATION
import Fade from 'react-reveal/Fade'
import UsecaseTTS from '../../components/UsecaseLanding/UsecaseTTS';
import Testimony from '../../components/Testimony/Testimony';
import Faq from '../../components/Faq/Faq';
import TTSDemo from '../../components/TTSDemo/TTSDemo';

export class LandingPageTTS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input_text: "",
            convert: "",

            audioUrl: "",
            progress: 80,
            showListDownload: false,
            listDownload: []
        };

        this.textBoxRef = React.createRef();
        this.hiddenFileInput = React.createRef();
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    componentDidUpdate(prevProps) {
        // if (this.props.status !== prevProps.status) {
        //     if (this.props.status === "rcv_tts") {
        //         this.rcvConvertResult(this.props.data_rcv_tts);
        //     }
        // }
    }

    autoGrow = (element) => {
        if (element !== null) {
            element.style.height = "5px";
            element.style.height = (element.scrollHeight) + "px";
        }
    }

    updateInputText = (target, text) => {
        var txt = text;
        if (txt.length > 120) {
            txt = txt.substring(0, 120);
        }

        this.setState({
            input_text: txt
        }, () => {
            this.autoGrow(target)
        });
    }

    handleSelectFile = (e) => {
        let file = e.target.files[0];

        // get file extension
        var fileExt = file.name.split('.').pop();

        if (fileExt !== "txt" && fileExt !== "docx") {
            this.updateInputText(this.textBoxRef.current, "Unacceptable file extension. You can only upload txt and docx")
            return
        }

        if (fileExt === "txt") {
            // parse file content
            const reader = new FileReader()
            reader.onload = (e) => {
                // parsed text
                var text = e.target.result;
                // remove line breaks and multiple spaces
                text = text.replace(/(\r\n|\n|\r)/gm, " ").trim();
                // get only first 120 characters
                text = text.substring(0, 120);

                this.updateInputText(this.textBoxRef.current, text)
            };
            reader.readAsText(file)

        } else if (fileExt === "docx") {
            var reader = new FileReader();
            reader.onloadend = (e) => {
                var arrayBuffer = reader.result;

                try {
                    mammoth.extractRawText({ arrayBuffer: arrayBuffer })
                        .then((resultObject) => {
                            // parsed text
                            var text = resultObject.value;
                            // remove line breaks and multiple spaces
                            text = text.replace(/(\r\n|\n|\r)/gm, " ").trim();
                            // get only first 120 characters
                            text = text.substring(0, 120);

                            this.updateInputText(this.textBoxRef.current, text)
                        })
                        .done();
                } catch (e) {
                    this.updateInputText(this.textBoxRef.current, "Sorry, we couldn't read this file. Please use another file.")
                }
            };
            reader.readAsArrayBuffer(file);
        }

    }

    handleInputChange = ({ target }) => {
        this.updateInputText(target, target.value)
    };

    handleOnBlur = () => {
        if (this.state.input_text.length !== 0 || this.state.input_text.length > 0) {
            this.setState({
                convert: "1"
            })
        }
    }

    handleOnKey = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleConvert();
        }
    }

    handleConvert = () => {
        // console.log("handleConvert")
        // console.log(this.state.input_text)

        if (this.state.input_text.replace(/\s+/g, '').length === 0) {
            return;
        }

        var text_req = this.state.input_text;

        // store.dispatch(fetchReqTts(text_req));
        store.dispatch(fetchTTSToken()).then((token) => {
            console.log("tts_token :",token);
            
            store.dispatch(convertTextToSpeech("text", text_req))
                .then((res) => {
                    // console.log(res)
                    this.rcvConvertResult(res)
                })
                .catch((err) => {
                    // console.log(err)   
                    this.setState({
                        convert: "failed"
                    })
                })

            this.setState({
                convert: "converting",
                listDownload: []
            })
        }).catch((err) => {
            // console.log(err)   
            this.setState({
                convert: "failed"
            })
        })

    }

    rcvConvertResult = (msg) => {
        // if (msg.status === "success") {
        //     this.setState({
        //         convert: "converted",
        //         listDownload: msg.data
        //     })

        //     if (msg.data.length > 0) {
        //         this.setState({
        //             audioUrl: msg.data[0].audio
        //         })
        //     }

        // } else {
        //     this.setState({
        //         convert: "failed"
        //     })

        // }


        const audioBase64 = "data:audio/wav;base64," + msg.bk.data.audio;
        const audioFormat = msg.bk.data.audio_format.format;
        // const audioFile = new Audio("data:audio/wav;base64," + msg.bk.data.audio);
        // const audioUrl = URL.createObjectURL(audioFile);
        // console.log(audioBase64)

        this.setState({
            convert: "converted",
            listDownload: [{ audio: audioBase64, type: audioFormat }],
            audioUrl: audioBase64
        })
    }

    playResult = () => {
        const audio = new Audio(this.state.audioUrl);
        audio.play()
            .then(() => {
                // Audio is playing.
            })
            .catch(error => {
                // console.log(error);
            });
    }

    handleInputClear = () => {

        this.setState({
            input_text: ""
        });
    };

    contentPackage = () => {
        return (
            <div className="content-landing-package">
                <Fade>
                    <BilingPackage
                        landing={true}
                    />
                </Fade>
            </div>
        )
    }

    handleClick = event => {
        this.hiddenFileInput.current.click();
    };

    cancelUpload = () => {
        this.setState({
            convert: ""
        });

        store.dispatch(cancelConvertTTS());

        // //simulation
        // this.setState({ 
        //     convert: "converted"
        // });
    };

    handleListDownload = () => {
        this.setState({
            showListDownload: !this.state.showListDownload
        });
    };

    contentEngine = (props) => {
        return (
            <div id="content-engine">
                <div className="content-wrapper landing-wrapper">
                    <div className="content-paragraf">
                        <h2>
                            Yuk Konversikan Teks anda menjadi Ucapan
                        </h2>
                        <p>
                            Ketik kalimat anda di kotak yang tersedia dan teknologi kami akan mengubahnya menjadi suara seperti suara manusia
                        </p>
                    </div>
                    <TTSDemo />
                    
                </div>
            </div>
        )
    }

    contentServiceMenu = () => {
        return (
            <div id="content-service-menu">
                <div className="landing-wrapper">
                    <Fade>
                        <h2>
                            Teks menjadi Ucapan (Teknologi ini) memungkinkan konversi otomatis teks bahasa Indonesia menjadi ucapan. Proses sintesis ucapan akan menghasilkan ucapan seperti suara manusia
                        </h2>
                        <div className="content-service-menu-a">
                            <div className="content-service-menu-item">
                                <Image src={itemmenu1} />
                                <h3>Call Center</h3>
                                <p>
                                    Automasi call center dan asisten virtual
                                </p>
                            </div>
                            <div className="content-service-menu-item">
                                <Image src={itemmenu2} />
                                <h3>IoT Control</h3>
                                <p>
                                    Memberikan pengalaman interaktif dengan respon suara
                                </p>
                            </div>
                            <div className="content-service-menu-item">
                                <Image src={itemmenu3} />
                                <h3>Narator</h3>
                                <p>
                                    Membantu penyandang disabilitas untuk mendapatkan pesan atau informasi dalam bentuk suara
                                </p>
                            </div>
                            {/* <div className="content-service-menu-item">
                            <Image src={itemmenu4} />
                            <h3>Text and SSML Support</h3>
                            <p>
                                Optimasisasi penggunaan Internet of Things 
                                untuk control perangkat menggunakan suara
                            </p>
                        </div> */}
                        </div>
                    </Fade>
                </div>
            </div>
        )
    }

    contentFeatureTop = () => {
        return (
            <div id="content-top-landing-feature" className="landing-wrapper">
                <div className="content-landing-feature-paragraf landing-wrapper">
                    <Fade>
                        <h1>
                            Transkripsi Teks menjadi Ucapan
                        </h1>
                        <p>
                            Unggah teks atau dokumen dan konversi menjadi audio. Anda dapat mendengarkan teks atau dokumen seperti mendengarkan cerita di mana saja dan kapan saja
                        </p>
                        <Button
                            // disabled
                            id="landingpagetts-getstarted"
                            className="btn-landing-blue"
                            onClick={() => { history.push("/register") }}
                        >
                            {/* Coming Soon */}
                            Coba Sekarang
                        </Button>
                        {/* <Image src={bubble} className="span-bubble" /> */}
                    </Fade>
                </div>
            </div>
        )
    }

    contentVideo = () => {
        return (
            <div className="content-landingpage-video">
                <Image src={video} className="video-play-image" />
                <button className="btn-video-play" id="landingtts-playvideo">
                    <Image src={play} />
                </button>
                {/* <iframe src="">
                </iframe> */}
            </div>
        )
    }

    contentBottom = () => {
        return (
            <div id="content-bottom">
                <div className="content-item bg-gray">
                    <div className="landing-wrapper">
                        <Row>
                            <Col md={6}>
                                <Fade>
                                    <div className="content-item-wrapper">
                                        <h1>Tertarik dengan Produk kami ?</h1>
                                        <a href={window._link_.contact} target="_blank" rel="noopener noreferrer">
                                            <Button
                                                id="landingpageservice-kontakkami"
                                                className="btn-landing blue">
                                                Kontak Kami
                                            </Button>
                                        </a>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md={6}>
                                <Fade>
                                    <div className="">
                                        <Image src={kontakkami} alt="pic" className="content-item-img" />
                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }

    scrollToContent = () => {
        const element = document.getElementById('content-engine');
        element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }

    contentFeature = () => {
        return (
            <div id="landing-page-feature">
                <div className="content-bubble-2">
                    <Image src={bubble} />
                </div>
                <div id="landingpageservice-content-feature" className="landing-wrapper">
                    <div className="content-wrapper left">
                        <Fade>
                            <div className="content-paragraf medium ">
                                <h2>
                                    Transkripsi Teks menjadi Ucapan
                                </h2>
                                <p>
                                    Unggah teks atau dokumen dan konversi menjadi audio. Anda dapat mendengarkan teks atau dokumen seperti mendengarkan cerita di mana saja dan kapan saja
                                </p>
                                {/* <Button 
                                        onClick={() => this.scrollToContent()}
                                        id="landingpageservice-seedetailtts"
                                        className="btn-lihat-detail">
                                        Live Demo
                                    </Button> */}
                                <div className="btn-landing-wrapper">
                                    <Button
                                        onClick={() => history.push("/register")}
                                        id="landingpageservice-getstartedtts"
                                        className="btn-lihat-detail">
                                        Segera Mulai
                                    </Button>
                                    {/* <Button className="btn-lihat-detail secondary">
                                            <img src={playbtn} alt="icon" id="icon-play"/> Putar Video
                                        </Button> */}
                                    <Button
                                        onClick={() => this.scrollToContent()}
                                        id="landingpageservice-seedetailtts"
                                        className="btn-lihat-detail secondary">
                                        <img src={down} alt="icon" id="icon-play" /> Coba Demonya
                                    </Button>
                                </div>
                                {/* <Button 
                                        onClick={() => this.scrollToContent()}
                                        id="landingpageservice-seedetailtts"
                                        className="btn-lihat-detail demo">
                                        <img src={down} alt="icon" id="icon" /> Coba Demonya
                                    </Button>                            */}
                            </div>
                            <div className="landing-img-wrapper">
                                <Image src={landing2} id="landing-img-ilustration" />
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                <NavbarService
                    logo={home}
                    ttsActive={true}
                />
                <div id="landing-page-tts">

                    {this.contentFeature()}
                    {/* {this.contentFeatureTop()} */}
                    {/* {this.contentVideo()} */}
                    {this.contentEngine()}
                    {this.contentServiceMenu()}
                    <UsecaseTTS />
                    {this.contentPackage()}
                    <Testimony type={"tts"} />
                    <Faq type={"tts"} />

                </div>
                {this.contentBottom()}
                <FooterLanding />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        status: state.TTSReducers.status,
        data_rcv_tts: state.TTSReducers.data_rcv_tts,
    }
}

export default connect(mapStateToProps)(LandingPageTTS);
