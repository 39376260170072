import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { Badge, Overlay, Popover } from "react-bootstrap";

import store, { history } from "../../store";
import { nats } from "../../middleware/NATSMiddleware";
import { NC_USER_NOTIFICATION } from "../../middleware/NATSTopics";
import { fetchUserNotificationList, fetchUserNotificationRead } from "../../reducers/NotificationReducers";

import NotificationBellIcon from "./NotificationBellIcon";

import './NotificationBell.scss'
import WalkthroughNotification from "../Walkthrough/WalkthroughNotification";
import { getTutorialState, setTutorialState } from "../../utils/tutorials";


function NotificationBell(props) {
    const { callback=()=>{} } = props;

	const { id_token, userDetail } = useSelector(state => state.UserManagementReducers);

    

    const [showNotifIcon, setShowNotifIcon] = useState(true);

	const [idUser, setIdUser] = useState("");

    const [show, setShow] = useState(false);
    const [showTutorial, setShowTutorial] = useState(false);
	const [isFirstNotif, setIsFirstNotif] = useState(false);

    const [notifList, setNotifList] = useState([]);
    const [unseenTotal, setUnseenTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [offset, setOffset] = useState(0);
    const limit = 10;

    const popoverRef = useRef(null);

    const notifListRef = useRef([]);
    const unseenTotalRef = useRef(0);
    const totalRef = useRef(0);

    var userNotificationListener = null;
    
    useEffect(() => {        
        return () => {
            NatsUserNotificationUnsubcribers();
        }
    }, [])

    useEffect(() => {
        // console.log(history.location.pathname)
        if ( history.location.pathname === "/notification" ) {
            setShowNotifIcon(false)
        } else {
            setShowNotifIcon(true);
        }
    }, [history.location.pathname])
    
    useEffect(() => {
        if ( id_token == null || userDetail === null ) return;
        
        setOffset(0)

        store.dispatch(fetchUserNotificationList(id_token, 0, limit))
            .then((result) => {
                if ( result.offset === offset ) {
                    const data = result.data;
                    
                    if ( JSON.stringify(data) !== JSON.stringify(notifList) ) {
                        setNotifList(data)
                    }
                    
                    setUnseenTotal(result.unseen)
                    setTotal(result.total)
                }

            })
            .catch((err) => {
                
            })
    }, [id_token, userDetail])

	useEffect(() => {
        if ( userDetail === null ) return;

        const { id_user } = userDetail;

        if ( id_user !== idUser ) {
            setIdUser(id_user)
            NatsUserNotificationUnsubcribers();
            NatsUserNotificationSubcribers(id_user);
        }
	}, [userDetail])

	useEffect(() => {
        setIsFirstNotif(notifList.length === 1 && unseenTotal === 1);

        notifListRef.current = notifList
        unseenTotalRef.current = unseenTotal
        totalRef.current = total
	}, [notifList, unseenTotal, total])

    useEffect(() => {
        callback({
            show: show,
            isFirstNotif: isFirstNotif
        })

        if ( show && isFirstNotif ) {
            setTimeout(() => {
                setShowTutorial(true);
            }, 100)
        } else if ( !show && showTutorial ) {
            setShowTutorial(false)
            // setTutorialState("notification", false);
        }
    }, [show, isFirstNotif])

    const NatsUserNotificationSubcribers = (id_user) => {
        if ( userNotificationListener !== null ) return;
    
        const topic = `${NC_USER_NOTIFICATION}.${id_user}`
        
        // console.log(topic)

        // console.log("NatsUserNotificationSubcribers " + topic)
    
        userNotificationListener = nats.subscribe(topic, (response) => {
            const data = JSON.parse(response);

            rcvNewUserNotification(data);
        });
    }
    
    const NatsUserNotificationUnsubcribers = () => {
        // if ( userNotificationListener === null ) return;
    
        // console.log("NatsUserNotificationUnsubcribers")
        nats.unsubscribe(userNotificationListener);
    
        userNotificationListener = null;
    }

    const rcvNewUserNotification = (data) => {
        // console.log("rcvNewUserNotification")

        let newNotifList = notifListRef.current.slice();
        
        const index = newNotifList.findIndex(({id}) => id === data.id)
        
        if ( index === -1 ) {
            newNotifList.unshift(data)
            setNotifList(newNotifList)

            setUnseenTotal(unseenTotalRef.current+1)
            setTotal(totalRef.current+1)
        };

    }

    const handleClick = () => {
        setShow(!show);
    };

    const handleNotifRowClick = (notif) => {
        if ( !notif.seen ) {
            store.dispatch(fetchUserNotificationRead(id_token, notif.id))
                .then((result) => {
                    let newNotifList = notifList.slice();
                    
                    const index = newNotifList.findIndex(({id}) => id === notif.id)
                    if ( index === -1 ) return;

                    if ( newNotifList[index].seen ) return;
                    
                    setUnseenTotal(unseenTotal-1)
                    
                    newNotifList[index].seen = true;

                    setNotifList(newNotifList)

                })
                .catch((err) => {

                })
        }

        setShow(false);
        history.push(notif.url);
    }

    const callbackTutorial = (data) => {
        const { action, index, status, type, step} = data;
		// Tutorial ended
		if ( type === "tour:end" ) {
            setTutorialState("notification", false);
		}
    }
    
    if ( !showNotifIcon ) {
        return null;
    }

    return (
        <>

            { showTutorial && getTutorialState("notification") &&
                <WalkthroughNotification callback={callbackTutorial} />
            }

            <div id="notification-bell-container" required={true}>
                
                <div ref={popoverRef}
                    id="notification-bell-icon-container"
                    className={show?"active":""}
                    onClick={handleClick}
                >
                    <NotificationBellIcon 
                        width={"27px"} 
                        color={"#122C34"} 
                        count={unseenTotal} 
                    />
                </div>

                <Overlay
                    target={popoverRef.current}
                    rootClose={true}
                    show={show}
                    onHide={() => setShow(false)}
                    placement="bottom-end"
                    container={popoverRef.current}
                    containerPadding={20}
                >
                    <Popover id="notification-popover">
                        <Popover.Title as="h3">Notification</Popover.Title>
                        <Popover.Content>
                            { notifList.map((notif) => 
                                <div key={"notif-"+notif.id} 
                                    className={"notification-row "+(notif.seen?"seen":"unseen")} 
                                    onClick={() => handleNotifRowClick(notif)}
                                >
                                    <Badge 
                                    variant={(
                                        notif.type === "transaction" ? "success" :
                                        notif.type === "promo" ? "primary" :
                                        "info"
                                    )}
                                    >{notif.type}</Badge>
                                    {/* <div>{notif.info}</div> */}
                                    <div dangerouslySetInnerHTML={{ __html: notif.info }} />
                                    <div></div>
                                </div>
                            ) }
                        </Popover.Content>
                        {/* { total > notifList.length && */}
                            <footer className="popover-footer">
                                <NavLink to="/notification" onClick={() => setShow(false)}>See all notifications</NavLink>
                            </footer>
                        {/* } */}
                    </Popover>
                </Overlay>
                        
            </div>
        </>
    )
}

export default NotificationBell;