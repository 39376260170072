import React, { Component } from 'react'
import { Image, Button, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// COMPONENTS
import NavbarService from '../../components/navbar/NavbarService'
import FooterLanding from '../../components/footerlanding/FooterLanding'
// ASSETS
import "./LandingPage.scss"
import logo from "../../img/landing/logo.svg"
import landing1 from "../../img/landing/landing1.png"
import landing2 from "../../img/landing/TTS.png"
import landing3 from "../../img/landing/STT.png"
import paypal from "../../img/landing/paypal.svg"
import hsbc from "../../img/landing/hsbc.svg"
import slack from "../../img/landing/slack.svg"
import mandiri from "../../img/landing/mandiri.svg"
import gojek from "../../img/landing/gojek.svg"
import cocacola from "../../img/landing/cocacola.svg"
import bubble from "../../img/landing/bubble.svg"
// import rectangle from "../../img/landing/rectangle.svg"
import kontakkami from "../../img/landing/kontakkami.png"
//ANIMATION
import Fade from 'react-reveal/Fade'

export class LandingPageService extends Component {
    
    componentDidMount() {
        window.scrollTo(0,0)
    }

    scrollToElement = (name) => {
        const id = name;
        const yOffset = -150; 
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
        this.setState({showMenu:false})
    }

    // Content Rendered
    contentTopLanding = () => {
        return (
            <div id="landingpageservice-content-top" className="content-wrapper">
                <Fade>
                    <div className="content-paragraf">
                        <h1>
                            Platform API Speech to Text dan Text to Speech Terbaik<span className="span-style" />
                        </h1>
                        <p>
                        Platform yang dapat membantu meringankan dan menyelesaikan masalah anda dengan bantuan (memanfaatkan) teknologi kecerdasan buatan
                        </p>
                        <Fade>
                            <div className="btn-landing-wrapper">
                                <Button 
                                    id="landingpageservice-pelajari"
                                    onClick={()=>this.scrollToElement('landingpageservice-content-feature')}
                                    className="btn-lihat-detail">
                                    Pelajari
                                </Button>
                            </div>
                        </Fade>
                    </div>
                    <div className="content-image-wrapper">
                        <Fade>
                            <Image src={landing1} />
                        </Fade>
                    </div>
                    <div className="content-bubble">
                        <Image src={bubble} />
                    </div>
                </Fade>
            </div>
        )
    }

    contentPartner = () => {
        return (
            <Fade>
                <div id="landingpageservice-content-partner">
                    <Image src={paypal} />
                    <Image src={hsbc} />
                    <Image src={slack} />
                    <Image src={mandiri} />
                    <Image src={gojek} />
                    <Image src={cocacola} />
                </div>
            </Fade>
        )
    }

    contentFeature = () => {
        return (
            <div id="landingpageservice-content-feature">
                <div className="content-wrapper content-feature-container left">
                    <Fade>
                        <div className="content-paragraf medium ">
                            <h2>
                                Transkripsi Teks Menjadi Ucapan
                            </h2>
                            <p>
                            Mengubah informasi teks menjadi ucapan agar mempermudah pengguna menyerap informasi melalui suara
                            </p>
                            <div className="btn-landing-wrapper">
                                <Link to="/tts">
                                    <Button 
                                        id="landingpageservice-seedetailtts"
                                        className="btn-lihat-detail">
                                        Lihat Detail
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        <div className="content-image-wrapper">
                            <Image src={landing2} />
                        </div>
                    </Fade>
                </div>
                
                <div className="content-wrapper content-feature-container">
                    <Fade>
                        <div className="content-image-wrapper small">
                            <Image src={landing3} />
                        </div>
                        <div className="content-paragraf medium right">
                            <h2>
                                Transkripsi Ucapan Menjadi Teks
                            </h2>
                            <p>
                            Mengubah informasi ucapan di suatu rapat, seminar, atau percakapan customer dengan pelanggan menjadi teks
                            </p>
                            <div className="btn-landing-wrapper">
                                <Link to="/stt">
                                    <Button 
                                        id="landingpageservice-seedetailstt"
                                        className="btn-lihat-detail">
                                        Lihat detail
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        )
    }

    // contentAdditional = () => {
    //     return (
    //         <div className="landingpage-content-additional">
    //             <h1>
    //                 Tertarik dengan pelayanan kami?
    //             </h1>
    //             <a href={window._link_.contact}>
    //                 <Button 
    //                     id="landingpageservice-kontakkami"
    //                     className="btn-kontak-kami">
    //                     Kontak Kami
    //                 </Button>
    //             </a>
    //             <Image src={rectangle} className="img-rectangle" />
    //         </div>
    //     )
    // }

    
    contentBottom = () => {
        return (
            <div id="content-bottom">
                <div className="content-item bg-gray">
                    <div className="landing-wrapper">
                    <Row>
                    <Col md={6}>
                        <Fade>
                            <div className="content-item-wrapper">
                                <h1>Tertarik dengan Produk kami ?</h1>
                                <a href={window._link_.contact} target="_blank" rel="noopener noreferrer">
                                    <Button 
                                        id="landingpageservice-kontakkami"
                                        className="btn-landing blue">
                                        Kontak Kami
                                    </Button>
                                </a>
                            </div>
                        </Fade>
                    </Col>
                    <Col md={6}>
                        <Fade>
                            <div className="">
                                <Image src={kontakkami} alt="pic" className="content-item-img" />
                            </div>
                        </Fade>
                    </Col>
                    </Row>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                <NavbarService 
                    logo={logo}
                />
                <div id="landing-page-service">
                    <div className="landing-wrapper">

                        {this.contentTopLanding()}
                        {/* {this.contentPartner()} */}
                        {this.contentFeature()}
                        {/* {this.contentAdditional()} */}

                    </div>
                </div>
                {this.contentBottom()}
                <FooterLanding />
            </React.Fragment>
        )
    }
}

export default LandingPageService
