import FileSaver from 'file-saver';
import JSZip from 'jszip';

export function download_meeting_handler(type, text) {
    if ( type === "docx" ) {
        downloadAsDocx(text);
    } else if ( type === "txt" ) {
        downloadAsTxt(text);
    }
}

// function downloadAsDocx(text){    
//     var blob = new Blob([text], { encoding: "UTF-8", type: "text/docx;charset=UTF-8" });
        
//     FileSaver.saveAs(blob, "stt-result.docx");
// }

function downloadAsDocx(text) {
    // Other needed files
    const REQUIRED_FILES = {
        content_types_xml: `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
            <Types xmlns="http://schemas.openxmlformats.org/package/2006/content-types">
                <Default Extension="rels" ContentType="application/vnd.openxmlformats-package.relationships+xml"/>
                <Default Extension="xml" ContentType="application/xml"/>
                <Override PartName="/word/document.xml"
                        ContentType="application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml"/>
            </Types>`,
        rels: `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
            <Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">
                <Relationship Id="rId1" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument"
                                Target="word/document.xml"/>
            </Relationships>`,
        document_xml_rels: `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
            <Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">
            
            </Relationships>`
    };
    /// --
    const preHtml = `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
        <w:document xmlns:wpc="http://schemas.microsoft.com/office/word/2010/wordprocessingCanvas"
            xmlns:mc="http://schemas.openxmlformats.org/markup-compatibility/2006"
            xmlns:o="urn:schemas-microsoft-com:office:office"
            xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships"
            xmlns:m="http://schemas.openxmlformats.org/officeDocument/2006/math" xmlns:v="urn:schemas-microsoft-com:vml"
            xmlns:wp14="http://schemas.microsoft.com/office/word/2010/wordprocessingDrawing"
            xmlns:wp="http://schemas.openxmlformats.org/drawingml/2006/wordprocessingDrawing"
            xmlns:w10="urn:schemas-microsoft-com:office:word"
            xmlns:w="http://schemas.openxmlformats.org/wordprocessingml/2006/main"
            xmlns:w14="http://schemas.microsoft.com/office/word/2010/wordml"
            xmlns:wpg="http://schemas.microsoft.com/office/word/2010/wordprocessingGroup"
            xmlns:wpi="http://schemas.microsoft.com/office/word/2010/wordprocessingInk"
            xmlns:wne="http://schemas.microsoft.com/office/word/2006/wordml"
            xmlns:wps="http://schemas.microsoft.com/office/word/2010/wordprocessingShape" mc:Ignorable="w14 wp14">
            <w:body><w:p w:rsidR="005F670F" w:rsidRDefault="005F79F5">`;
    const postHtml = `<w:bookmarkStart w:id="0" w:name="_GoBack"/>
                <w:bookmarkEnd w:id="0"/>
                </w:p>
                <w:sectPr w:rsidR="005F670F">
                    <w:pgSz w:w="12240" w:h="15840"/>
                    <w:pgMar w:top="1440" w:right="1440" w:bottom="1440" w:left="1440" w:header="720" w:footer="720"
                            w:gutter="0"/>
                    <w:cols w:space="720"/>
                    <w:docGrid w:linePitch="360"/>
                </w:sectPr>
            </w:body>
        </w:document>`;
    const cvAsHTML = `<w:r><w:t>${text}</w:t></w:r>`;
    const html = preHtml + cvAsHTML + postHtml;
  

    let zip = new JSZip();
    // prerequisites: 
    zip.file("_rels/.rels", REQUIRED_FILES.rels);
    zip.file("[Content_Types].xml", REQUIRED_FILES.content_types_xml);
    zip.file("word/_rels/document.xml.rels", REQUIRED_FILES.document_xml_rels);
    //
    zip.file("word/document.xml", html);
    zip.generateAsync({type:"blob"}).then(function(content) {
        FileSaver.saveAs(content, "stt-result.docx");
    });
}

function downloadAsTxt(text){
    var blob = new Blob([text], { encoding: "UTF-8", type: "text/plain;charset=UTF-8" });

    FileSaver.saveAs(blob, "stt-result.txt");
}