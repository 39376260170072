import React from 'react';  
import Slider from 'react-dynamic-slider';

class BKAudioSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: 'single', // single or marker
        }

        this.handleAddMarker = this.handleAddMarker.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.duration !== this.state.duration) {
            this.setState({
                duration : nextProps.duration
            });
        }
    }

    handleAddMarker(arrays) {
        if (this.props.demo) {
        } else {
        this.props.setSliderStatus('mark');
        this.props.setAudioMarkersValue(arrays.markerValues);
        }
    }

    handleOnChange(arrays) {
        // console.log("handleOnChange")
        if (this.props.demo) {
        } else {        
        this.props.setSliderStatus('move');
        }
    }

    handleOnChangeComplete(arrays) {
        // console.log("handleOnChangeComplete")

        if (arrays.lock === true){
            return;
        }
        if (this.props.demo) {
        } else { 
        this.props.setThumbValue(arrays.mainThumbValue);
        }
    }

    sliderValuesToMillis() {

    }

    

    render() {
        return(
            <Slider
                clsName = "audio-player"
                sliderSize = {7}
                sliderColor = "#75e8e74d"
                trackColor = "#0189FF"
                thumbColor = "#dfdfdf"
                markerColor= "#f17300"
                markerCount = {2}
                value = {this.props.sliderValue}
                lockToMaxMark 
                dynamic = {this.props.dynamic}
                onChange = {(state) => {
                    this.handleOnChange(state)
                }}
                onChangeComplete = {(state) => {
                    this.handleOnChangeComplete(state)
                }}
                onAddMarker = {(state) => {
                    this.handleAddMarker(state)
                }}
            />
        )
    }
}

export default BKAudioSlider;