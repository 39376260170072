/**
 * NOTICE! 
 * ONLY PUT ACTIONS AND REDUCERS THAT DEAL WITH THE INCOMING MESSAGES 
 * FROM SERVER IN THIS MODULE
 */

import ReactDOM from 'react-dom';
import store from '../store';

// IMPORT ACTIONS TO BE DISPATCHED FROM OUTSIDE SECTION

import {
    updateFetchingDataStream,
    rcvStartStream,
    rcvStopStream,
    reqStartStream,
    updateStreamStatus,
    reqInitSendBlobChange,
    rcvTranscriptResult
} from '../reducers/STTReducers';

import {
    clearStreamResponseTimeout
} from '../modules/StreamResponseTimeout';

import { streamAudioBlob } from '../page/landingpage/StreamingRecorder';

// IMPORT ACTIONS TO BE DISPATCHED FROM OUTSIDE SECTION

// Received data from websocket

// THE SWITCHER
export var WebSocketProtocolIncomingSwitcher = (response) => {    
    var msg = JSON.parse(response);
    let service = msg.bk.service;
    let type = msg.bk.type;
    let status = msg.bk.status;
    if ( status === 200 ) {
        status = "success";
    } else if ( status === 400 ) {
        status = "failed";
    } else {
        status = ""
    };
    let error = msg.bk.message;
    let data = msg.bk.data;

    const target = document.getElementById('popup-notification-container');
    if ( target !== null ) {
        if ( target.innerHTML !== "" ) {    
            ReactDOM.unmountComponentAtNode(target);
        }
    }

    clearStreamResponseTimeout();
    store.dispatch(updateFetchingDataStream("receive_" + type, msg));
    
    if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {    
        console.log("====== INCOMING DATA ======");
        if ( status === "success" ) {
            console.log(service, type)
            console.log(data)
        } else {
            console.log(msg)
        }
        console.log("===========================");
    }

    switch (service) {    
        case `stt`:
            switch (type) {
                case `audioConn`:
                    switch (status) {
                        case 'success':
                            store.dispatch(rcvStartStream(data));
                            break;
                            
                        case 'failed':
                            const streamAudioBlobSize = streamAudioBlob.size;
                        
                            // if audio blob exist,
                            if ( streamAudioBlobSize > 0 ) {
                                // re-request start stream
                                store.dispatch(reqStartStream());
                            }
                            // if audio blob not exist,
                            else {
                                store.dispatch(updateStreamStatus(""));        
                                store.dispatch(reqInitSendBlobChange(true));
                            }

                            break;

                        default:
                            // status undefined
                            break;
                    }
                    break;
                
                case `audioStream`:
                    switch (status) {
                        case 'success':
                            // store.dispatch(rcvSendAudioStream());
                            store.dispatch(rcvTranscriptResult(data))
                            break;
                            
                        case 'failed':
                            // const streamAudioBlobSize = streamAudioBlob.size;
                        
                            // // if audio blob exist,
                            // if ( streamAudioBlobSize > 0 ) {
                            //     // re-request start stream
                            //     store.dispatch(reqStartStream());
                            // }
                            // // if audio blob not exist,
                            // else {
                            //     store.dispatch(updateStreamStatus(""));        
                            //     store.dispatch(reqInitSendBlobChange(true));
                            // }

                            break;

                        default:
                            // status undefined
                            break;
                    }
                    break;

                case `audioStop`:
                    switch (status) {
                        case 'success':
                            store.dispatch(rcvStopStream());
                            break;
                            
                        case 'failed':  
                            var streamAudioBlobSize = streamAudioBlob.size;
                        
                            // if audio blob not exist,
                            if ( streamAudioBlobSize === 0 ) {
                                store.dispatch(updateStreamStatus(""));        
                                store.dispatch(reqInitSendBlobChange(true));
                            }                  

                            break;

                        default:
                            // status undefined
                            break;
                    }
                    break;

                case `getWorker`:
                    store.dispatch(reqStartStream());
                    break;

                    default:
                        // console.log("!----UNKNOWN COMMAND----!");
                        // console.log("!-----------------------!");
                        break;
            }

        case `tts`:
            switch (type) {
                case `audioConn`:
                    switch (status) {
                        case 'success':
                            break;
                            
                        case 'failed':
                            break;

                        default:
                            // status undefined
                            break;
                    }
                    break;
                
                case `audioStream`:
                    switch (status) {
                        case 'success':
                            break;
                            
                        case 'failed':
                            break;

                        default:
                            // status undefined
                            break;
                    }
                    break;

                case `audioStop`:
                    switch (status) {
                        case 'success':
                            break;
                            
                        case 'failed':
                            break;

                        default:
                            // status undefined
                            break;
                    }
                    break;

                case `getWorker`:
                    break;

                default:
                    // console.log("!----UNKNOWN COMMAND----!");
                    // console.log("!-----------------------!");
                    break;
            } 

        default:
            // console.log("!----UNKNOWN COMMAND----!");
            // console.log("!-----------------------!");
            break;
    }
};
