import { toastNotification } from "../modules/ToastNotification"
import { history } from "../store"
import { getState } from "expect/build/jestMatchersObject";
import { fetchRefreshToken } from "./UserManagementReducers";
// Actions
const REQ_PAYMENT_ACC = "BillingReducers/REQ_PAYMENT_ACC";
const UPDATE_CONTINUE_TO_CHECKOUT_FLAG = "BillingReducers/UPDATE_CONTINUE_TO_CHECKOUT_FLAG";
const UPDATE_DATA_PAYMENT_SUBMIT = "BillingReducers/UPDATE_DATA_PAYMENT_SUBMIT"
const UPDATE_DATA_TRANSACTION_HISTORY = "BillingReducers/UPDATE_DATA_TRANSACTION_HISTORY"

// Action Creators
/* 
 *  !LEGEND! 
 *      req = request
 *      rcv = receive
 *      fch = fetch
 *      snd = send
 */

const reqPaymentAcc = data => ({
    type: REQ_PAYMENT_ACC,
    payload: {
        status_payment_acc: data,
        status: "data_payment_acc"
    }
});

export const updateContinueToCheckoutFlag = data => ({
    type: UPDATE_CONTINUE_TO_CHECKOUT_FLAG,
    payload: {
      continue_to_checkout_flag: data,
      status: "data_payment_flag"
    }
  });
  

export const updateDataPaymentSubmit = data => ({
    type: UPDATE_DATA_PAYMENT_SUBMIT,
    payload: {
        data_payment_submit: data,
        status: "data_payment_submit"
    }
});

export const updateDataTransactionHistory = (transactionHistory, transactionHistoryTotal) => ({
    type: UPDATE_DATA_TRANSACTION_HISTORY,
    payload: {
        transactionHistory: transactionHistory,
        transactionHistoryTotal: transactionHistoryTotal
    }
});



export const getAllTransactionHistory = (offset = 0) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const limit = getState().BillingReducers.transactionHistoryLimit;

        dispatch(fetchTransactionHistory(offset, limit))
            .then((res) => {

                let transactionHistory = getState().BillingReducers.transactionHistory.slice();

                res.data.forEach((transaction, index) => {
                    transactionHistory[offset+index] = transaction;
                })

                dispatch(updateDataTransactionHistory(transactionHistory, res.total));

                if ( res.data.length === limit ) {
                    const new_offset = offset+limit;
                    dispatch(getAllTransactionHistory(new_offset));
                }

                resolve(transactionHistory)

            })
            .catch((err) => {
                reject(err)
            }) 
    })
}

export const getTransactionDetail = (id_transaction) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        dispatch(fetchTransactionDetail(id_transaction))
            .then((res) => {
                const data = res.data[0];

                let transactionHistory = getState().BillingReducers.transactionHistory.slice();
                let transactionHistoryTotal = getState().BillingReducers.transactionHistoryTotal;

                const index = transactionHistory.findIndex((tr) => tr.id_transaction === id_transaction);
                // console.log(data)
                // console.log(index)
                // console.log(transactionHistory[index])

                if ( index > -1 ) {
                    if ( JSON.stringify(transactionHistory[index]) !== JSON.stringify(data) ) {
                        transactionHistory[index] = data;

                        dispatch(updateDataTransactionHistory(transactionHistory, transactionHistoryTotal));
                    }
                }

                resolve(data)
            })
            .catch((err) => {
                reject(err)
            }) 
    })
}

export const fetchPaymentAcc = (id_transaction) => (dispatch) => {
    return new Promise((resolve, reject) => {
        const body = {
            id_transaction: id_transaction
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/" + process.env.REACT_APP_SYSTEM_STATUS + "/billing/paymentacc/", {
            headers: {
                "Content-Type"  : 'application/json'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            })
            .then(result => {
                // console.log(result);
                if (result.status === "success") {
                    dispatch(reqPaymentAcc(result.status))
                }
                else {
                    if ( result.error === "token expired" ) {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchPaymentAcc(id_transaction))
                                    .then(()=>{})
                                    .catch(()=>{});
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                    } 
                    else {
                        throw(result.error)
                    }
                }
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchPaymentMethodList = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {    
        // Put outgoing requester here  

        const body = {
            id_token: getState().UserManagementReducers.id_token,
        };
        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/"+process.env.REACT_APP_SYSTEM_STATUS+"/payment/methodlist/", {
                headers: {
                    "Content-Type"  : 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            }).then(response => {
                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            }).then(result => {
                if ( result.status === "success" ) {
                    resolve(result);

                } else {
                    if ( result.error === "token expired" ) {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchPaymentMethodList())
                                    .then(()=>{})
                                    .catch(()=>{});
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                    } 
                    else {
                        throw(result.error)
                    }
                }

            }).catch(err => {
                reject(err)
            });
    });
};

export const fetchPaymentMethodDetailList = (id_payment_method) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        // Put outgoing requester here  

        const body = {
            id_token: getState().UserManagementReducers.id_token,
            id_payment_method: id_payment_method
        };
        
        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/"+process.env.REACT_APP_SYSTEM_STATUS+"/payment/methoddetaillist/", {
                headers: {
                    "Content-Type"  : 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            }).then(response => {
                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            }).then(result => {
                if ( result.status === "success" ) {
                    resolve(result);

                } else {
                    if ( result.error === "token expired" ) {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchPaymentMethodDetailList(id_payment_method))
                                    .then(()=>{})
                                    .catch(()=>{});
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                    } 
                    else {
                        throw(result.error)
                    }
                }

            }).catch(err => {
                reject(err)
            });
    });
};

export const fetchPaymentSubmit = (id_service_payment, id_package, count) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        // Put outgoing requester here  

        const body = {
            id_token: getState().UserManagementReducers.id_token,
            // id_service_payment: id_service_payment,
            id_service_payment: 7,
            id_package: id_package,
            count: count
        };
        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/"+process.env.REACT_APP_SYSTEM_STATUS+"/payment/submit/", {
                headers: {
                    "Content-Type"  : 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            }).then(response => {

                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            }).then(result => {
                if ( result.status === "success" ) {
                    resolve(result);

                } else {
                    if ( result.error === "token expired" ) {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchPaymentSubmit(id_service_payment, id_package, count))
                                    .then(()=>{})
                                    .catch(()=>{});
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                    } 
                    else {
                        throw(result.error)
                    }
                }

            }).catch(err => {
                // setTimeout(()=>history.push("/services"),3000)
                toastNotification(
                    'error',
                    'Transaksi gagal. Harap coba kembali beberapa saat lagi',
                    err.toString()
                );
                reject(err)
            });
    });
};

export const fetchOrderCreate = (id_service_payment, id_package, count) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        // Put outgoing requester here  

        const body = {
            id_token: getState().UserManagementReducers.id_token,
            // id_service_payment: id_service_payment,
            id_service_payment: 7,
            id_package: id_package,
            count: count
        };
        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/"+process.env.REACT_APP_SYSTEM_STATUS+"/order/create/", {
                headers: {
                    "Content-Type"  : 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            }).then(response => {

                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            }).then(result => {
                if ( result.status === "success" ) {
                    resolve(result);

                } else {
                    if ( result.error === "token expired" ) {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchOrderCreate(id_service_payment, id_package, count))
                                    .then(()=>{})
                                    .catch(()=>{});
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                    } 
                    else {
                        throw(result.error)
                    }
                }

            }).catch(err => {
                // setTimeout(()=>history.push("/services"),3000)
                toastNotification(
                    'error',
                    'Transaksi gagal. Harap coba kembali beberapa saat lagi',
                    err.toString()
                );
                reject(err)
            });
    });
};

export const fetchTransactionHistory = (offset, limit) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {    
        const body = {
            id_token: getState().UserManagementReducers.id_token,
            offset: offset,
            limit: limit
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/"+process.env.REACT_APP_SYSTEM_STATUS+"/transaction/history/", {
                headers: {
                    "Content-Type"  : 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            }).then(response => {
                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            }).then(result => {
                if ( result.status === "success" ) {
                    resolve(result);

                } else {
                    if ( result.error === "token expired" ) {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchTransactionHistory(offset, limit))
                                    .then(()=>{})
                                    .catch(()=>{});
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                    } 
                    else {
                        throw(result.error)
                    }
                }

            }).catch(err => {
                reject(err)
            });
    });
};

export const fetchTransactionDetail = (id_transaction) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {    
        const body = {
            id_token: getState().UserManagementReducers.id_token,
            id_transaction: id_transaction
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/"+process.env.REACT_APP_SYSTEM_STATUS+"/transaction/detail/", {
                headers: {
                    "Content-Type"  : 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            }).then(response => {
                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            }).then(result => {
                if ( result.status === "success" ) {
                    resolve(result);

                } else {
                    if ( result.error === "token expired" ) {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchTransactionDetail(id_transaction))
                                    .then(()=>{})
                                    .catch(()=>{});
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                    } 
                    else {
                        throw(result.error)
                    }
                }

            }).catch(err => {
                reject(err)
            });
    });
};


// Reducer's initial state
const initialState = {
    continue_to_checkout_flag: false,
    status_payment_acc : "",
    data_payment_submit: null,

    transactionHistory: [],
    transactionHistoryLimit: 20,
    transactionHistoryTotal: 0
}

// Reducers
export default function BillingReducers(state = initialState, action) {
    switch (action.type) {
        case REQ_PAYMENT_ACC:
            return {
                ...state,
                status: action.payload.status,
                status_payment_acc: action.payload.status_payment_acc
            };
        case UPDATE_CONTINUE_TO_CHECKOUT_FLAG:
            return {
                ...state,
                status: action.payload.status,
                continue_to_checkout_flag: action.payload.continue_to_checkout_flag
            };
        case UPDATE_DATA_PAYMENT_SUBMIT:
            return {
                ...state,
                status: action.payload.status,
                data_payment_submit: action.payload.data_payment_submit
            };
        case UPDATE_DATA_TRANSACTION_HISTORY:
            return {
                ...state,
                transactionHistory: action.payload.transactionHistory,
                transactionHistoryTotal: action.payload.transactionHistoryTotal,
            };
        default:
            return state;
    }
}