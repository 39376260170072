import moment from 'moment';
import React, { Component } from 'react'
import store, { history } from "../../../store"

import { getTransactionDetail } from '../../../reducers/BillingReducers';

import { Button, Modal } from 'react-bootstrap' 
import Loading from '../../../components/Loading';

import "./ModalTransactionDetail.scss"

import close from "../../../img/close-black.svg"


class ModalTransactionDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            isLoading: true,
            showError: false,
            errorMessage: "",

            transactionDetail: null
        }
    }

    componentDidUpdate(prevProps) {
        if ( this.props.show !== prevProps.show ) {          
            if ( this.props.show ) {
                // console.log(this.props.idTransaction)
                store.dispatch(getTransactionDetail(this.props.idTransaction))
                    .then((data) => {
                        // console.log(data)
                        this.setState({
                            transactionDetail: data,
                            isLoading: false
                        })
                    })
                    .catch((err) => {
                        this.setState({
                            isLoading: false,
                            showError: true,
                            errorMessage: err.toString()
                        })
                    }) 

            } else {
                this.setState({
                    transactionDetail: null
                })
            }
        }
    }

    handleContinuePay = (midtrans_snap_token) => {
        // console.log("handleContinuePay")

        window.snap.show();

        window.snap.pay(midtrans_snap_token, {
            onSuccess: (result) => {
                const params = new URLSearchParams({status: "success" });
                history.push({ pathname: "/transactions", search: params.toString() });   
            },
            onPending: (result) => {
                const params = new URLSearchParams({status: "success" });
                history.push({ pathname: "/transactions", search: params.toString() }); 
            },
            onError: (result) => {
                const params = new URLSearchParams({status: "success" });
                history.push({ pathname: "/transactions", search: params.toString() });
            },
            onClose: (result) => {
                // console.log('customer closed the popup without finishing the payment');
            }
        });

    }

    render() {
        const transaction = this.state.transactionDetail;
        // console.log(transaction)

        let id_transaction          = this.props.idTransaction;

        let isUsingMidtrans         = false;
        let midtrans_snap_token     = "";
        let midtrans_info           = null;

        let transaction_status      = "";
        let date                    = "";
        let info                    = "";
        let service_type            = "";
        let quota                   = "";
        let amount                  = "";
        let payment_method          = "";
        let payment_method_detail   = "";
        let payment_acc_number      = "";
        let mandiri_biller_code     = "";
        let mandiri_bill_key        = "";



        if ( transaction !== null ) {
            
            // console.log(transaction)

            midtrans_snap_token     = transaction.midtrans.snap_token;
            isUsingMidtrans         = ( midtrans_snap_token !== "" );

            transaction_status      = transaction.status;
            date                    = moment(transaction.date).format("DD MMM YYYY, HH:mm");
            info                    = transaction.info
            service_type            = transaction.service_type;
            quota                   = transaction.quota;
            amount                  = transaction.amount;
            payment_method          = transaction.payment.payment_method;
            payment_method_detail   = transaction.payment.payment_method_detail;
            payment_acc_number      = transaction.payment.acc_number;

            if ( isUsingMidtrans ) {
                // console.log("--- MIDTRANS ---")

                midtrans_info = ( transaction.midtrans.info !== "" ? JSON.parse(transaction.midtrans.info) : null );

                // console.log(midtrans_snap_token)
                // console.log(midtrans_info)

                if ( midtrans_info !== null ) {

                    // console.log(midtrans_info)

                    payment_method = midtrans_info.payment_type.replace(/_/g, ' ');

                    if ( payment_method.toLowerCase() === "bank transfer" ) {

                        if ( midtrans_info.va_numbers !== null ) {
                            payment_method_detail = midtrans_info.va_numbers[0].bank;
                            payment_acc_number = midtrans_info.va_numbers[0].va_number;

                        } else {
                            for ( let key in midtrans_info ) {
                                if ( key.includes("_va_number") ) {
                                    if ( midtrans_info[key] !== "" ) {
                                        payment_method_detail = key.replace("_va_number", "");
                                        payment_acc_number = midtrans_info[key];
                                    }
                                }
                            }

                            if ( payment_method_detail === "" && midtrans_info.bill_key !== "" ) {
                                payment_method_detail   = "mandiri";
                                mandiri_biller_code     = midtrans_info.biller_code;
                                mandiri_bill_key        = midtrans_info.bill_key;
                            }
                        }

                    } else if ( payment_method === "echannel" ) {
                        if ( midtrans_info.bill_key !== "" ) {
                            payment_method          = "bank transfer"
                            payment_method_detail   = "mandiri";
                            mandiri_biller_code     = midtrans_info.biller_code;
                            mandiri_bill_key        = midtrans_info.bill_key;
                        }

                    } else if ( payment_method === "cstore" ) {

                    }

                } else {
                    payment_method = "" 
                }
                
                // console.log("----------------")

            }

            if ( transaction.payment.acc_number !== undefined && transaction.payment.acc_number === "free") {
                payment_method  = "-"
                amount          = "free";
                // pay_before      = "-"
            }
        }

        // console.log(payment_method)
        // console.log(payment_method_detail)

        return (
            <>
                <Modal 
                    className="modal-transaction-detail" 
                    show={this.props.show} 
                    onHide={this.props.onHide}
                    centered
                >
                    <Modal.Header>
                        <img src={close} 
                            alt="navigation-close" 
                            id="howtopay_close" 
                            className="close-btn" 
                            onClick={this.props.onHide}
                        />
                        <div className="title">Detail Pesanan</div>
                    </Modal.Header>
                    <Modal.Body>
                        { transaction === null ?
                            <Loading />
                            :
                            <div className="content">
                                <div className="sub-content">
                                    <div className="row">
                                        <div className="left">ID Pesanan</div>
                                        <div className="right">{id_transaction}</div>
                                    </div>
                                    <div className="row">
                                        <div className="left">Tanggal Transaksi</div>
                                        <div className="right">{date}</div>
                                    </div>
                                    <div className="row">
                                        <div className="left">Status</div>
                                        <div className="right">{info}</div>
                                    </div>
                                </div>
                                <div className="sub-title">Pembelian</div>
                                <div className="sub-content">
                                    <div className="row">
                                        <div className="left">Jenis Service</div>
                                        <div className="right">{service_type}</div>
                                    </div>
                                    <div className="row">
                                        <div className="left">Durasi Pakai</div>
                                        <div className="right">{quota}</div>
                                    </div>
                                    <div className="row">
                                        <div className="left">Harga</div>
                                        <div className="right">{amount}</div>
                                    </div>
                                </div>
                                <div className="sub-title">Pembayaran</div>
                                <div className="sub-content">
                                    <div className="row">
                                        <div className="left">Metode Pembayaran</div>
                                        <div className="right">
                                            { isUsingMidtrans && payment_method === "" && transaction_status === "processing" ? 
                                                <Button 
                                                    variant="primary" 
                                                    className="btn-select-payment-method"
                                                    onClick={() => this.handleContinuePay(midtrans_snap_token)}
                                                >
                                                    Pilih Metode Pembayaran
                                                </Button>
                                                : 
                                                <>
                                                    { payment_method === "" ? 
                                                        ( transaction !== null ? "Belum dipilih" : "" ) 
                                                        : 
                                                        ( payment_method.toLowerCase() === "cstore" ? 
                                                            midtrans_info.store 
                                                            : payment_method
                                                        ) 
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    
                                    { payment_method.toLowerCase() === "bank transfer" &&
                                        <>
                                            <div className="row">
                                                <div className="left">Nama Bank</div>
                                                <div className="right">{payment_method_detail.toUpperCase()}</div>
                                            </div>
                                            
                                            { isUsingMidtrans && payment_method_detail.toLowerCase() === "mandiri" ?
                                                <>
                                                    <div className="row">
                                                        <div className="left">Biller Code</div>
                                                        <div className="right">{mandiri_biller_code}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="left">Bill Key</div>
                                                        <div className="right">{mandiri_bill_key}</div>
                                                    </div>
                                                </>
                                                :
                                                <div className="row">
                                                    <div className="left">Virtual Account</div>
                                                    <div className="right">{payment_acc_number}</div>
                                                </div>
                                            }
                                        </>
                                    }
                                    { payment_method.toLowerCase() === "cstore" &&
                                        <>
                                            {/* <div className="row">
                                                <div className="left">Nama Store</div>
                                                <div className="right">{midtrans_info.store}</div>
                                            </div> */}
                                            <div className="row">
                                                <div className="left">Kode Pembayaran</div>
                                                <div className="right">{midtrans_info.payment_code}</div>
                                            </div>
                                        </>
                                    }

                                    { isUsingMidtrans && payment_method !== "" &&  transaction_status === "processing" &&
                                        <div className="row">
                                            <Button 
                                                variant="primary" 
                                                className="btn-view-payment-method"
                                                onClick={() => this.handleContinuePay(midtrans_snap_token)}
                                            >
                                                Lihat cara pembayaran
                                            </Button>
                                        </div>
                                    }

                                </div>
                            </div>
                        }
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default ModalTransactionDetail;