import React, { Component } from 'react';
import { Row, Col, Button } from "react-bootstrap";
import { withRouter } from 'react-router';
import { connect } from "react-redux";

// FUNCTIONS
import { getServiceDataByAcronym, getServiceNameByAcronym, services, updateServices } from '../../../../utils/services';

// COMPONENTS
import BilingPackage from '../../../../components/BilingPackage/BilingPackage';
import STTDemo from '../../../../components/STTDemo/STTDemo';
import TTSDemo from '../../../../components/TTSDemo/TTSDemo';

// STYLES
import "../Board.scss"
import "./ServiceDetail.scss"

// ASSETS


class ServiceDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services: services,

            service_name: "",
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);

        let path = this.props.match.path.replace('/', '');

        this.setState({
            service_name: this.renderServiceName(path),
        })



        // console.log(this.state.services)
        // const diktein_json_file_path = `/products/diktein.json`;
        // const stt_json_file_path = `/products/stt.json`;
        // const tts_json_file_path = `/products/tts.json`;
        const infoFile = [
            { name: "diktein", pathname: "/products/diktein.json" },
            { name: "stt", pathname: "/products/stt.json" },
            { name: "tts", pathname: "/products/tts.json" },
            { name: "text-translate", pathname: "/products/text-translate.json" },
            { name: "audio-translate", pathname: "/products/audio-translate.json" }
        ]

        for (let i = 0; i < infoFile.length; i++) {
            console.log(infoFile[i].pathname)
            await fetch(infoFile[i].pathname)
                .then((res) => res.json())
                .then((data) => {
                    const index = this.state.services.findIndex(({ acronym }) => acronym.indexOf(infoFile[i].name) > -1);
                    if (index === -1) return;

                    let newServices = this.state.services.slice();
                    // console.log(newServices[index])
                    newServices[index] = {
                        ...newServices[index],
                        ...data
                    }

                    // console.log(newServices[index])

                    updateServices(newServices);

                    this.setState({
                        services: newServices
                    }, () => {
                        // console.log(this.state.services)
                    })

                })
                .catch((err) => {
                    // console.log(err)
                })

        }

        //     await fetch(diktein_json_file_path)
        //         .then((res) => res.json())
        //         .then((data) => {
        //             const index = this.state.services.findIndex(({ acronym }) => acronym.indexOf("diktein") > -1);
        //             if (index === -1) return;

        //             let newServices = this.state.services.slice();
        //             // console.log(newServices[index])
        //             newServices[index] = {
        //                 ...newServices[index],
        //                 ...data
        //             }

        //             // console.log(newServices[index])

        //             updateServices(newServices);

        //             this.setState({
        //                 services: newServices
        //             }, () => {
        //                 // console.log(this.state.services)
        //             })

        //         })
        //         .catch((err) => {
        //             // console.log(err)
        //         })

        //     await fetch(stt_json_file_path)
        //         .then((res) => res.json())
        //         .then((data) => {
        //             const index = this.state.services.findIndex(({ acronym }) => acronym.indexOf("stt") > -1);
        //             if (index === -1) return;

        //             let newServices = this.state.services.slice();
        //             // console.log(newServices[index])
        //             newServices[index] = {
        //                 ...newServices[index],
        //                 ...data
        //             }

        //             // console.log(newServices[index])

        //             updateServices(newServices);

        //             this.setState({
        //                 services: newServices
        //             }, () => {
        //                 // console.log(this.state.services)
        //             })

        //         })
        //         .catch((err) => {
        //             // console.log(err)
        //         })

        //     await fetch(tts_json_file_path)
        //         .then((res) => res.json())
        //         .then((data) => {
        //             const index = this.state.services.findIndex(({ acronym }) => acronym.indexOf("tts") > -1);
        //             if (index === -1) return;

        //             let newServices = this.state.services.slice();
        //             // console.log(newServices[index])
        //             newServices[index] = {
        //                 ...newServices[index],
        //                 ...data
        //             }

        //             // console.log(newServices[index])

        //             updateServices(newServices);

        //             this.setState({
        //                 services: newServices
        //             }, () => {
        //                 // console.log(this.state.services)
        //             })

        //         })
        //         .catch((err) => {
        //             // console.log(err)
        //         })
    }

    componentDidUpdate(prevProps) {
        let prev_service_name = prevProps.match.path.replace('/', '');
        let service_name = this.props.match.path.replace('/', '');

        if (service_name !== prev_service_name) {

            window.scrollTo(0, 0);

            this.setState({
                service_name: this.renderServiceName(service_name),
            })
        }
    }

    renderServiceName = (path) => {
        let service_name = path;

        if (service_name.indexOf("notula") > -1) {
            service_name = "notula";
        } else if (service_name.indexOf("notulite") > -1) {
            service_name = "notulite";
        } else if (service_name.indexOf("diktein") > -1 || service_name.indexOf("dikte.in") > -1) {
            service_name = "dikte.in";
        } else if (service_name == "") {
            service_name = "dikte.in";
            
        }
        // } else if (service_name.indexOf("stt") > -1) {
        //     service_name = "stt";
        // } else if (service_name.indexOf("tts") > -1) {
        //     service_name = "tts";
        // }

        // for (let i = 0; i < infoFile.length; i++){

        // }

        return service_name;
    }

    render() {
        let name = getServiceNameByAcronym(this.state.service_name);
        let docs = null;
        let desc = null;
        let features = null;
        let benefits = null;
        const service_data = getServiceDataByAcronym(this.state.service_name);
        if (service_data !== null) {
            // name = (service_data.name !== undefined ? service_data.name : "");
            docs = (service_data.docs !== undefined ? service_data.docs : null);
            desc = (service_data.desc !== undefined ? service_data.desc : null);
            features = (service_data.features !== undefined ? service_data.features : null);
            benefits = (service_data.benefits !== undefined ? service_data.benefits : null);
        }

        return (
            <>
                <div key={this.state.service_name} id="service-detail-container">
                    {/* <div className="main-bg"></div> */}
                    <div className="main-container">
                        <div className="main-header">
                            <div className="main-bg"></div>
                            {desc !== null &&
                                <>
                                    <p>{desc.title}. {desc.desc}</p>
                                    <p> <h3>{desc.tagline}</h3></p>
                                </>
                            }

                            {/* {docs !== null &&
                                <a className="see-docs-btn" href={docs} target="_blank" rel="noopener noreferrer">
                                    <Button variant="light">
                                        Dokumentasi API
                                    </Button>
                                </a>
                            } */}
                            {(this.state.service_name === "notula") &&
                                <a className="see-docs-btn" href={window._link_["notula"]} target="_blank" rel="noopener noreferrer">
                                    <Button variant="light">
                                        Notula
                                    </Button>
                                </a>
                            }
                            {(this.state.service_name === "notulite") &&
                                <a className="see-docs-btn" href={window._link_["notulite"]} target="_blank" rel="noopener noreferrer">
                                    <Button variant="light">
                                        Notulite
                                    </Button>
                                </a>
                            }
                            {(this.state.service_name === "dikte.in" || this.state.service_name === "diktein") &&
                                <a className="see-docs-btn" href={window._link_["diktein"]}>
                                    <Button variant="light">
                                        Dikte.in
                                    </Button>
                                </a>
                            }
                        </div>

                        <div className="main-card-container">
                            <Row>
                                <Col className="dashboard-card-col" xs={12} xl={7}>
                                    {/* { desc !== null &&
                                        <ServicesCard
                                            title={desc.title}
                                            desc={desc.desc}
                                            // onSeeDetail={()=>history.push(`/your-services/${val.id_user_package}/${(val.service_name)}/${(val.name.toLowerCase()).replace(/\s+/g, '-')}`)}
                                        />
                                    } */}
                                    {features !== null &&
                                        <div className="custom-card services-card" id="features">
                                            <h3>Fungsi dan Fitur</h3>
                                            {features.map((f) => {
                                                return (
                                                    <div key={f.title}>
                                                        <p className="title">{f.title}</p>
                                                        <p>{f.desc}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }

                                    {benefits !== null &&
                                        <div className="custom-card services-card" id="benefits">
                                            <h3>Keuntungan</h3>
                                            {benefits.map((f) => {
                                                return (
                                                    <div key={f.title}>
                                                        <p className="title">{f.title}</p>
                                                        <p>{f.desc}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }



                                    {/* {(this.state.service_name === "dikte.in" || this.state.service_name === "diktein") &&
                                        <div className="custom-card services-card" id="demo">
                                            <h3>Demo</h3>
                                            <iframe
                                                title="Alur Penggunaan"
                                                src={window._link_.diktein_demo_video}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen>

                                            </iframe>
                                        </div>
                                    } */}

                                    {this.state.service_name.indexOf("stt") > -1 &&
                                        <div className="custom-card services-card" id="demo">
                                            <h3>Demo</h3>
                                            <p>Ubah suara anda menjadi teks dengan klik dan tahan pada icon recording kemudian rekam dengan microphone</p>
                                            <STTDemo />
                                        </div>
                                    }

                                    {this.state.service_name.indexOf("tts") > -1 &&
                                        <div className="custom-card services-card" id="demo">
                                            <h3>Demo</h3>
                                            <p>Ketik kalimat anda di kotak yang tersedia dan teknologi kami akan mengubahnya menjadi suara seperti suara manusia</p>
                                            <TTSDemo />
                                        </div>
                                    }

                                </Col>
                                <Col className="dashboard-card-col" xs={12} xl={5}>
                                    <div className="custom-card services-card" id="pricing">
                                        <h3>Harga</h3>
                                        <BilingPackage {...this.props} landing={true} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="dashboard-card-col" xs={12} md={12}>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        id_token: state.UserManagementReducers.id_token
    };
}

export default withRouter(connect(mapStateToProps)(ServiceDetail));