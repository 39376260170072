import { useEffect } from "react";
import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import Input from "../Input/Input";

import './Account.scss'


function ModalVerifyEmailSent(props) {
    const { 
        show=false, 
        onHide=()=>{}, 
        onOkayClick=()=>{},
        email=""
    } = props;

    const onClick = () => {
        onHide();
        onOkayClick();
    }

    return (
        <>
            <Modal 
                className="modal-verify-email-sent" 
                show={show} 
                onHide={onHide}
                backdrop="static"
                centered
            >
                <Modal.Body>
                    <div className="content">
                        <div>Verification link has been sent to {email} <br/> Please check your email</div>
                        <div className="button-group">
                            <button type="button"
                                className="btn btn-primary" 
                                onClick={onClick}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalVerifyEmailSent;