import React, { useEffect, useState } from 'react';

// Functions

// Components
import { Navbar } from 'react-bootstrap';
import { history } from '../../../store';


// Styles
import "./HeadMenu.scss"

// ETC


function HeadMenu({Logo, Content, ...props}) {    
    const [expanded, setExpanded] = useState(false);

    useEffect(() => { 
		// console.log(history.location.pathname)
        setExpanded(false)
    }, [history.location.pathname])


    return (
        <Navbar 
            className="head-menu" 
            bg="light" 
            expand="lg" 
            fixed="top"
            onToggle={(exp) => {setExpanded(exp)}}
            expanded={expanded}
        >
            <Navbar.Brand>
                <Logo />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Content {...props} />
            </Navbar.Collapse>
        </Navbar>              
    )
}

export default HeadMenu;