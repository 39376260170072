/** 
 * !README FIRST BEFORE EDITING THIS MODULE!
 * 
 * You can import actions from another modules into this ws middleware.
 * 1. If the action needs to send request from the client,
 *    please put a case within the return function inside the MIDDLEWARE SECTION.
 * 2. If the action is a server response, please put a case within onMessage 
 *    variable inside the  CONST VARIABLE SECTION.
 * 
 * further information about redux middleware =>
 * https://redux.js.org/advanced/middleware
 * 
*/
//JS IMPORTS
import {
    WS_OPEN,
    WS_DISCONNECT,
    WS_MSG_SEND,
    wsConnOpen,
    wsConnConnected,
    wsConnConnecting,
    wsConnDisconnected,
    wsConnDisconnect,
    wsMessageSend,
} from '../reducers/WebSocketDefaultReducers';
import { updateSttToken } from '../reducers/STTReducers';
import store from '../store';
import { WebSocketProtocolIncomingSwitcher } from './WebSocketProtocolIncoming';
// import { initTokenAndWS } from '..';


// MIDDLEWARE SECTION 
/** 
 * WebSocket Middleware 
 * */
export var ws = null 
const ws_reconnect_timeout = 10000; //30000;

var ws_req_queue = [];
export const addWsReqToQueue = (req) => {
    ws_req_queue.push(req);
}
const wsSendMessage = (req) => {
    // console.log("wsSendMessage")

    if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {    
        console.log("====== OUTGOING DATA ======");
        console.log(JSON.parse(req));
        console.log("===========================");
    }

    ws.send(req)
};

var ws_send_msg_interval = null;
var ws_try_reconnect_interval = null;

export const startWsSendMessageInterval = () => {
    stopWsSendMessageInterval();

    ws_send_msg_interval = setInterval(() => {
        // console.log("setInterval")
        // console.log(store.getState().WebSocketDefaultReducers.ws_status)
        if ( store.getState().WebSocketDefaultReducers.ws_status !== "connected" )
            return;

        if ( ws_req_queue.length === 0 ) 
            return;

        while ( ws_req_queue.length > 0 ) {
            const req = ws_req_queue.splice(0, 1);
            wsSendMessage(req[0]);
        }

    }, 100)
}
export const stopWsSendMessageInterval = () => {
    clearInterval(ws_send_msg_interval);
    ws_send_msg_interval = null;
}

export const startWsTryReconnectInterval = () => {
    if ( ws_try_reconnect_interval !== null ) return;

    ws_try_reconnect_interval = setInterval(() => {
        if ( store.getState().NATSDefaultReducers.ws_status === "connected" ) {
            clearInterval(ws_try_reconnect_interval);
            ws_try_reconnect_interval = null;
        } else {
            store.dispatch(wsConnOpen());
        }
    }, ws_reconnect_timeout)
}

const WebSocketMiddleware = (function () {
    /** CONFIGURATION */
    
    const onConnected = (store) => {
        console.log("WS connected")
        // console.log(url);
        
        // Tell the store we're connected
        store.dispatch(wsConnConnected());

        startWsSendMessageInterval();
    }
    
    const onError = (store) => {
        console.log("WS error")

        // // Disconnect ws
        // store.dispatch(wsConnDisconnect());
                
        stopWsSendMessageInterval();

        // const target = document.querySelector('#popup-notification-container');
        // if ( target !== null ) {
        //     ReactDOM.render(
        //         <PopupNotification
        //             content={
        //                 <div>
        //                     <div>Tidak dapat terkoneksi dengan server. Coba <a onClick={() => { window.location.reload() }}>refresh halaman ini</a> beberapa saat lagi.</div>
        //                     <div>Jika kendala masih terjadi, harap segera hubungi admin.</div>
        //                 </div>
        //             } 
        //         />,
        //         target
        //     );
        // }
    }

    const onClose = (store) => {    
        console.log("WS close")
        
        stopWsSendMessageInterval();
            
        // Tell the store we've disconnected
        store.dispatch(wsConnDisconnected());

        // Try to reconnect in <ws_reconnect_timeout> seconds
        startWsTryReconnectInterval();
    }

    // CORE MIDDLEWARE SECTION 
    return store => next => action => {
        switch (action.type) {
            // Create new connection to server
            case WS_OPEN:
                if ( ws != null ) {
                    ws.close();
                }

                console.log("Connecting to WS")
                // console.log(url);

                // Send an action that shows a "connecting..." status for now
                store.dispatch(wsConnConnecting());

                // Run ws API to connect to server
                // [begin connect_url]

                const sttToken = store.getState().STTReducers.sttToken;
                // console.log(sttToken)
                
                const uri = encodeURI(`${process.env.REACT_APP_WEBSOCKET_URL}/v2/prod/stream?token=${sttToken}`);
                // const uri = encodeURI(`wss://apidev.bahasakita.co.id:8765/v1/prod/stream?token=${sttToken}`);
                
                // console.log("WebSocket: " + uri);

                ws = new WebSocket(uri);         

                // connect callback initTokenAndWSprovides a reference to the connection as an argument
                ws.onopen = () => {
                    onConnected(store);
                }

                ws.onclose = () => {
                    onClose(store);
                }

                ws.onerror = () => {
                    onError(store);
                }

                ws.onmessage = (e) => {
                    const data = JSON.parse(e.data);
                    // console.log(data)
                    if ( data.bk.status === 400 ) {
                        store.dispatch(wsConnDisconnect());

                        store.dispatch(updateSttToken(null));

                        //initTokenAndWS();
                        
                    } else {
                        WebSocketProtocolIncomingSwitcher(e.data)
                    }
                }

                break;

            // Disconnection process
            case WS_DISCONNECT:
                if (ws != null) {
                    ws.close();
                }
                ws = null;
                break;

            // Send message process
            case WS_MSG_SEND:
                if ( store.getState().WebSocketDefaultReducers.ws_status !== "connected" ) {
                    setTimeout(() => {
                        store.dispatch(wsMessageSend(action.request_topic, action.message));
                    }, 100)
                    return;
                }
                ws.send(action.message)
                break;

            // This is mandatory for passing the state to the next middleware
            default:
                return next(action);
        }
    }
})();

export default WebSocketMiddleware;