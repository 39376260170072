export let services = [
    {
        acronym: ["notula", "notulite", "dikte.in", "diktein"],
        name: ["Notula", "Notulite", "Dikte.in", "Dikte.in"],
        path: ["/notula", "/notulite", "/diktein", "/diktein"],
        json_url: "/stt.json",
        docs: null,
        // desc: {
        //     title: "Transkripsi Rapat Otomatis",
        //     desc: "Mulai dari rapat hingga seminar, Diktein dapat menjadi solusi transkripsi Anda. Dilengkapi berbagai fitur seperti pemformatan teks otomatis hingga pengenal suara, Diktein mempermudah proses Notulensi rapat."
        // },
        // benefits: [
        //     {
        //         title: "Rapat Anda akan di transkripsi dengan mudah",
        //         desc: "Diktein dapat menjadi solusi untuk berbagai kebutuhan transkripsi anda. Portable dan mudah penggunaanya.",
        //     },
        //     {
        //         title: "Mencatat Pembicaraan Rapat Secara Otomatis",
        //         desc: "Transkripsi di kala rapat berlangsung atau unggah rekaman rapat, Diktein akan langsung bekerja untuk mentranskripsi dalam Bahasa Indonesia.",
        //     },
        //     {
        //         title: "Sistem Manajamen yang Terintegrasi",
        //         desc: "Seluruh percakapan rapat anda dapat direkam, ditelusuri kembali dan dibagikan kepada rekan kerja anda.",
        //     },
        // ],
        // features: [
        //     {
        //         title: "Penyutingan Semasa Transkripsi",
        //         desc: "Revisi transkripsi dapat dilakukan tanpa harus menunggu rapat selesai.",
        //     },
        //     {
        //         title: "Diarisasi Pembicara",
        //         desc: "Mengenali suara pembicara secara otomatis.",
        //     },
        //     {
        //         title: "Performatan Teks",
        //         desc: "Menghasilkan transkripsi sesuai dengan ejaan yang disempurnakan.",
        //     },
        //     {
        //         title: "Berbagai Sumber Audio",
        //         desc: "Dapat mentranskripsi rapat yang sedang berlangsung atau melalui rekaman rapat.",
        //     },
        // ]
    },
    {
        acronym: ["stt"],
        name: ["Speech to Text"],
        path: ["/stt"],
        docs: window._link_.stt_api_docs,
        // desc: {
        //     title: "Transkripsi Ucapan Menjadi Teks",
        //     desc: "Mengubah ucapan dalam bentuk teks, sehingga anda dapat menyimpan setiap percakapan anda maupun membagikannya"
        // },
        // features: [
        //     {
        //         title: "Call Center",
        //         desc: "Automasi call center dan asisten virtual"
        //     },
        //     {
        //         title: "IoT Control",
        //         desc: "Memberikan pengalaman interaktif dengan respon suara"
        //     },
        //     {
        //         title: "Narator",
        //         desc: "Membantu penyandang disabilitas untuk mendapatkan pesan atau informasi dalam bentuk suara"
        //     }
        // ]
    },
    {
        acronym: ["tts"],
        name: ["Text to Speech"],
        path: ["/tts"],
        docs: window._link_.tts_api_docs,
        // desc: {
        //     title: "Transkripsi Teks menjadi Ucapan",
        //     desc: "Unggah teks atau dokumen dan konversi menjadi audio. Anda dapat mendengarkan teks atau dokumen seperti mendengarkan cerita di mana saja dan kapan saja"
        // },
        // features: [
        //     {
        //         title: "Call Center",
        //         desc: "Automasi call center dan asisten virtual"
        //     },
        //     {
        //         title: "IoT Control",
        //         desc: "Memberikan pengalaman interaktif dengan respon suara"
        //     },
        //     {
        //         title: "Narator",
        //         desc: "Membantu penyandang disabilitas untuk mendapatkan pesan atau informasi dalam bentuk suara"
        //     }
        // ]
    },
    {
        acronym: ["text-translate"],
        name: ["Text Translation"],
        path: ["/text-translate"],
        docs: window._link_.tts_api_docs,
        // desc: {
        //     title: "Transkripsi Teks menjadi Ucapan",
        //     desc: "Unggah teks atau dokumen dan konversi menjadi audio. Anda dapat mendengarkan teks atau dokumen seperti mendengarkan cerita di mana saja dan kapan saja"
        // },
        // features: [
        //     {
        //         title: "Call Center",
        //         desc: "Automasi call center dan asisten virtual"
        //     },
        //     {
        //         title: "IoT Control",
        //         desc: "Memberikan pengalaman interaktif dengan respon suara"
        //     },
        //     {
        //         title: "Narator",
        //         desc: "Membantu penyandang disabilitas untuk mendapatkan pesan atau informasi dalam bentuk suara"
        //     }
        // ]
    }, {
        acronym: ["audio-translate"],
        name: ["Audio Translation"],
        path: ["/audio-translate"],
        docs: window._link_.tts_api_docs,
        // desc: {
        //     title: "Transkripsi Teks menjadi Ucapan",
        //     desc: "Unggah teks atau dokumen dan konversi menjadi audio. Anda dapat mendengarkan teks atau dokumen seperti mendengarkan cerita di mana saja dan kapan saja"
        // },
        // features: [
        //     {
        //         title: "Call Center",
        //         desc: "Automasi call center dan asisten virtual"
        //     },
        //     {
        //         title: "IoT Control",
        //         desc: "Memberikan pengalaman interaktif dengan respon suara"
        //     },
        //     {
        //         title: "Narator",
        //         desc: "Membantu penyandang disabilitas untuk mendapatkan pesan atau informasi dalam bentuk suara"
        //     }
        // ]
    }
]
export function updateServices(new_services) {
    services = new_services;
} 

export const getServiceDataByAcronym = (acr) => {
    let def_data = null;
    
    const index = services.findIndex((s) => s.acronym.indexOf(acr.toLowerCase()) > -1)
    
    if ( index === -1 ) return def_data;

    let data = services[index];
    
    return data;
}

export const getServiceNameByAcronym = (acr) => {
    let def_name = acr;

    const index = services.findIndex((s) => s.acronym.indexOf(acr.toLowerCase()) > -1)
    
    if ( index === -1 ) return def_name;

    const index_acr = services[index].acronym.indexOf(acr.toLowerCase());

    if ( index_acr === -1 ) return def_name;

    let name = services[index].name[index_acr];
    name = ( name === undefined ? def_name : name );
    
    // console.log(acr +"|"+name)
    return name;
}

export const getServicePathByAcronym = (acr) => {
    let def_path = "/"+acr;
    
    const index = services.findIndex((s) => s.acronym.indexOf(acr.toLowerCase()) > -1)
    
    if ( index === -1 ) return def_path;

    const index_path = services[index].acronym.indexOf(acr.toLowerCase());

    if ( index_path === -1 ) return def_path;

    let path = services[index].path[index_path];
    path = ( path === undefined ? def_path : path );
    
    return path;
}

export const getServiceDescByAcronym = (acr) => {
    let def_desc = null;

    const index = services.findIndex((s) => s.acronym.indexOf(acr.toLowerCase()) > -1)
    
    if ( index === -1 ) return def_desc;

    let desc = services[index].desc;
    desc = ( desc === undefined ? def_desc : desc );

    return desc;
}

export const getServiceDocsByAcronym = (acr) => {
    let def_docs = null;

    const index = services.findIndex((s) => s.acronym.indexOf(acr.toLowerCase()) > -1)
    
    if ( index === -1 ) return def_docs;

    let docs = services[index].docs;
    docs = ( docs === undefined ? def_docs : docs );

    return docs;
}

export const getServiceFeaturesByAcronym = (acr) => {
    let def_features = null;

    const index = services.findIndex((s) => s.acronym.indexOf(acr.toLowerCase()) > -1)
    
    if ( index === -1 ) return def_features;

    let features = services[index].features;
    features = ( features === undefined ? def_features : features );

    return features;
}