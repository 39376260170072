import React, { Component } from 'react';
import store, { history } from '../../../../store';
import { withRouter } from 'react-router';
import { connect } from "react-redux";

// FUNCTIONS
import { fetchUserPackageList, reqUserPackageList } from "../../../../reducers/UserPackageReducers";

// COMPONENTS
import { Row, Col } from 'react-bootstrap';
import Loading from '../../../../components/Loading';
import DashboardCard from '../../../../components/Cards/DashboardCards';

// STYLES
import "../Board.scss"


class YourServices extends Component {
    constructor(props) {
        super(props)

        this.state = {
            userpackage_list: this.props.userpackage_list,
        }
    }

    componentDidMount() {
        store.dispatch(fetchUserPackageList(this.props.id_token))
            .then(() => { })
            .catch(() => { });
    }

    componentDidUpdate(prevProps) {

        if (prevProps.userpackage_list !== this.props.userpackage_list && this.props.userpackage_list !== null) {
            this.setState({
                userpackage_list: this.props.userpackage_list,
            })
        }
    }

    componentWillUnmount() {
        store.dispatch(reqUserPackageList(this.props.userpackage_list))
    }

    render() {
        return (
            <>
                <div id="your-services-container">
                    <div className="main-bg"></div>
                    <div className="main-container">

                        <div className="main-header">
                            <h3>Layanan Anda</h3>
                            {/* <h3><span role="img" aria-label="icon">👋</span>  Selamat Datang, {this.props.username}!</h3> */}
                            {/* <p>Selamat datang di beranda web service Bahasa Kita, kamu dapat menikmati pelayanan dari teknologi mutakhir kami</p> */}
                        </div>
                        <div className="main-card-container">
                            <Row>
                                {
                                    (this.state.userpackage_list === null) ?
                                        <Loading /> :
                                        this.state.userpackage_list.map((val, i) => {
                                            return (
                                                <>
                                                    {val.service_name !== "dikte.in" &&

                                                        <Col className="dashboard-card-col" xs={12} lg={6} xl={3} key={i}>
                                                            <DashboardCard
                                                                status={val.status}
                                                                planType={val.name_plan}
                                                                title={val.name}
                                                                service_name={val.service_name}
                                                                desc={val.description}
                                                                onSeeDetail={() => history.push(`/your-services/${val.id_user_package}/${(val.service_name)}/${(val.name.toLowerCase()).replace(/\s+/g, '-')}`)}
                                                            />

                                                        </Col>
                                                    }
                                                </>
                                            )
                                        })
                                }
                            </Row>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


function mapStateToProps(state) {
    return {
        id_token: state.UserManagementReducers.id_token,
        username: state.UserManagementReducers.username,
        userpackage_list: state.UserPackageReducers.userpackage_list,
    };
}

export default withRouter(connect(mapStateToProps)(YourServices));