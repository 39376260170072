
import getRandomValues from 'get-random-values';

// Get random uuid
export function get_random_uuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    // (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    (c ^ getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

export function checkPasswordRegex(strings = "") {
  // /^
  // (?=.*\d)          // should contain at least one digit
  // (?=.*[a-z])       // should contain at least one lower case
  // (?=.*[A-Z])       // should contain at least one upper case
  // [a-zA-Z0-9]{8,}   // should contain at least 8 from the mentioned characters
  // $/

  const result = strings.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/);
  // console.log(result)
  if (result === null) {
    return { result: false, error_info: "password should contain at least one digit, one lower case, one upper case, and at least 8 from the mentioned characters" };
  } else {
    return { result: true };
  }
}

export function checkNameRegex(strings = "") {
  const result = strings.match(/^[a-zA-Z\s]*$/);
  // console.log(result)
  if (result === null) {
    return { result: false, error_info: "name should only contain letters" };
  } else {
    return { result: true };
  }
}

export const languageMap = [
  {
    "value": "en",
    "label": "Inggris"
  },
  {
    "value": "zh",
    "label": "Cina"
  },
  {
    "value": "de",
    "label": "Jerman"
  },
  {
    "value": "es",
    "label": "Spanyol"
  },
  {
    "value": "ru",
    "label": "Rusia"
  },
  {
    "value": "ko",
    "label": "Korea"
  },
  {
    "value": "fr",
    "label": "Perancis"
  },
  {
    "value": "ja",
    "label": "Jepang"
  },
  {
    "value": "pt",
    "label": "Portugis"
  },
  {
    "value": "tr",
    "label": "Turki"
  },
  {
    "value": "pl",
    "label": "Polandia"
  },
  {
    "value": "ca",
    "label": "Katalan"
  },
  {
    "value": "nl",
    "label": "Belanda"
  },
  {
    "value": "ar",
    "label": "Arab"
  },
  {
    "value": "sv",
    "label": "Swedia"
  },
  {
    "value": "it",
    "label": "Italia"
  },
  {
    "value": "id",
    "label": "Indonesia"
  },
  {
    "value": "hi",
    "label": "India"
  },
  {
    "value": "fi",
    "label": "Finlandia"
  },
  {
    "value": "vi",
    "label": "Vietnam"
  },
  {
    "value": "he",
    "label": "Ibrani"
  },
  {
    "value": "uk",
    "label": "Ukraina"
  },
  {
    "value": "el",
    "label": "Yunani"
  },
  {
    "value": "cs",
    "label": "Ceko"
  },
  {
    "value": "ro",
    "label": "Romania"
  },
  {
    "value": "da",
    "label": "Dansk"
  },
  {
    "value": "hu",
    "label": "Hungaria"
  },
  {
    "value": "ta",
    "label": "Tamil"
  },
  {
    "value": "no",
    "label": "Norwegia"
  },
  {
    "value": "th",
    "label": "Thai"
  },
  {
    "value": "ur",
    "label": "Urdu"
  },
  {
    "value": "hr",
    "label": "Kroasia"
  },
  {
    "value": "bg",
    "label": "Bulgaria"
  },
  {
    "value": "lt",
    "label": "Lithuania"
  },
  {
    "value": "ml",
    "label": "Malayalam"
  },
  {
    "value": "cy",
    "label": "Welsh"
  },
  {
    "value": "sk",
    "label": "Slovakia"
  },
  {
    "value": "te",
    "label": "Telugu"
  },
  {
    "value": "lv",
    "label": "Latvia"
  },
  {
    "value": "bn",
    "label": "Benggala"
  },
  {
    "value": "sr",
    "label": "Serbia"
  },
  {
    "value": "az",
    "label": "Azerbaijani"
  },
  {
    "value": "sl",
    "label": "Slovenia"
  },
  {
    "value": "kn",
    "label": "Kanada"
  },
  {
    "value": "et",
    "label": "Estonia"
  },
  {
    "value": "mk",
    "label": "Makedonia"
  },
  {
    "value": "eu",
    "label": "Basque"
  },
  {
    "value": "is",
    "label": "Islandia"
  },
  {
    "value": "hy",
    "label": "Armenia"
  },
  {
    "value": "ne",
    "label": "Nepali"
  },
  {
    "value": "bs",
    "label": "Bosnia"
  },
  {
    "value": "kk",
    "label": "Kazakh"
  },
  {
    "value": "sq",
    "label": "Albania"
  },
  {
    "value": "sw",
    "label": "Swahili"
  },
  {
    "value": "gl",
    "label": "Galicia"
  },
  {
    "value": "mr",
    "label": "Marathi"
  },
  {
    "value": "pa",
    "label": "Punjabi"
  },
  {
    "value": "si",
    "label": "Sinhala"
  },
  {
    "value": "km",
    "label": "Khmer"
  },
  {
    "value": "sn",
    "label": "Shona"
  },
  {
    "value": "yo",
    "label": "Yoruba"
  },
  {
    "value": "so  ",
    "label": "Somalia"
  },
  {
    "value": "af",
    "label": "Afrikaans"
  },
  {
    "value": "oc",
    "label": "Occitan"
  },
  {
    "value": "ka",
    "label": "Georgia"
  },
  {
    "value": "be",
    "label": "Belarusia"
  },
  {
    "value": "tg",
    "label": "Tajik"
  },
  {
    "value": "sd",
    "label": "Sindhi"
  },
  {
    "value": "gu",
    "label": "Gujarat"
  },
  {
    "value": "am",
    "label": "Amharik"
  },
  {
    "value": "yi",
    "label": "Yiddish"
  },
  {
    "value": "lo",
    "label": "Lao"
  },
  {
    "value": "uz",
    "label": "Uzbek"
  },
  {
    "value": "fo",
    "label": "Faroe"
  },
  {
    "value": "ht",
    "label": "Kreol haiti"
  },
  {
    "value": "ps",
    "label": "Pashto"
  },
  {
    "value": "tk",
    "label": "Turki"
  },
  {
    "value": "nn",
    "label": "Nynorsk"
  },
  {
    "value": "mt",
    "label": "Malta"
  },
  {
    "value": "sa",
    "label": "Sansekerta"
  },
  {
    "value": "lb",
    "label": "Meluksemburg"
  },
  {
    "value": "saya",
    "label": "Myanmar"
  },
  {
    "value": "bo",
    "label": "Tibet"
  },
  {
    "value": "tl",
    "label": "Tagagalog"
  },
  {
    "value": "as",
    "label": "Assam"
  },
  {
    "value": "tt",
    "label": "Tatar"
  },
  {
    "value": "ln",
    "label": "Lingala"
  },
  {
    "value": "ha",
    "label": "Hausa"
  },
  {
    "value": "ba",
    "label": "Bashkir"
  },
  {
    "value": "jw",
    "label": "Jawa"
  },
  {
    "value": "su",
    "label": "Sunda"
  }
]

export const languageTTS = [
  {"label": "Bulgarian", "value": "bg", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Czech", "value": "cs", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Danish", "value": "da", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Dutch", "value": "nl", "gender": [{"male": "Speaker-A"}]}, 
  {"label": "English (US)", "value": "en", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Estonian", "value": "et", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Finnish", "value": "fi", "gender": [{"male": "Speaker-A"}]}, 
  {"label": "French (France)", "value": "fr", "gender": [{"male": "Speaker-A"}]}, 
  {"label": "German", "value": "de", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Greek", "value": "el", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Hungarian", "value": "hu", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Croatian", "value": "hr", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Indonesian", "value": "id", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Irish (Gaelic)", "value": "ga", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Italian", "value": "it", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Latvian", "value": "lv", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Lithuanian", "value": "lt", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Maltese", "value": "mt", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Polish", "value": "pl", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Portuguese (Portugal)", "value": "pt", "gender": [{"male": "Speaker-A"}]}, 
  {"label": "Romanian", "value": "ro", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Slovak", "value": "sk", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Slovenian", "value": "sl", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Spanish (Spain)", "value": "es", "gender": [{"male": "Speaker-A"}]}, 
  {"label": "Swedish", "value": "sv", "gender": [{"female": "Speaker-A"}]}, 
  {"label": "Ukrainian", "value": "uk", "gender": [{"female": "Speaker-A"}]}
]