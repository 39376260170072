export function clearCookie() {
    // document.cookie = "LOGGED_IN= ; expires=Thu, 01 Jan 1970 00:00:00 GMT"
}

export function clearLocalStorage() {
    localStorage.removeItem("id_token");
    localStorage.removeItem("keycloakToken")
}

export function timeCalculation(duration) {
        
    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(duration / (60 * 60 * 24));
    var hours = Math.floor((duration % (60 * 60 * 24)) / (60 * 60));
    var minutes = Math.floor((duration % (60 * 60)) / 60);
    var seconds = Math.floor((duration % 60));


    // Display the result in the element with id="live-meeting-timer"
    var time;

    if ( days !== 0 ) {
        if (days < 10) {
            days = "0" + days;
        }

        if (hours < 10) {
            hours = "0" + hours;
        }

        if (minutes < 10) {
            minutes = "0" + minutes;
        }

        if (seconds < 10) {
            seconds = "0" + seconds;
        }

        time = days + ":" + hours + ":" + minutes + ":" + seconds;

    } else {
        if ( hours !== 0 ) {
            if (hours < 10) {
                hours = "0" + hours;
            }

            if (minutes < 10) {
                minutes = "0" + minutes;
            }

            if (seconds < 10) {
                seconds = "0" + seconds;
            }
            
            time = hours + ":" + minutes + ":" + seconds;

        } else {
            
            if (minutes < 10) {
                minutes = "0" + minutes;
            }

            if (seconds < 10) {
                seconds = "0" + seconds;
            }
            
            time = minutes + ":" + seconds;

        }
    }
    
    return time;
}