import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Image, Button, Row, Col } from 'react-bootstrap'
import { history } from '../../store';

// Functions

// COMPONENTS
import NavbarService from '../../components/navbar/NavbarService'
import FooterLanding from '../../components/footerlanding/FooterLanding'
import BilingPackage from "../../components/BilingPackage/BilingPackage"
import Testimony from '../../components/Testimony/Testimony';
import Faq from '../../components/Faq/Faq';
import UsecaseSTT from '../../components/UsecaseLanding/UsecaseSTT';
import STTDemo from '../../components/STTDemo/STTDemo';

//ASSETS
import bubble from "../../img/landing/bubble3.svg"
import video from "../../img/landing/videostt.svg"
import play from "../../img/landing/playvideo.svg"
import itemmenu1 from "../../img/landing/itemmenu1.svg"
import itemmenu2 from "../../img/landing/itemmenu2.svg"
import itemmenu3 from "../../img/landing/itemmenu3.svg"
import kontakkami from "../../img/landing/kontakkami.png"
import landing2 from "../../img/landing/STT.png"
import home from '../../img/landing/home-icon.svg'
import down from '../../img/landing/c-down.svg'

//ANIMATION
import Fade from 'react-reveal/Fade'


export class LandingPageSTT extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    contentPackage = () => {
        return (
            <div className="content-landing-package">
                <Fade>
                    <BilingPackage
                        landing={true}
                    />
                </Fade>
            </div>
        )
    }

    contentEngine = () => {
        return (
            <div id="content-engine">
                <div className="content-wrapper landing-wrapper">
                    <div className="content-paragraf">
                        <h2>
                            Yuk Konversikan Ucapan anda menjadi Teks
                        </h2>
                        <p>
                            Ubah suara anda menjadi teks dengan klik dan tahan pada icon recording kemudian rekam dengan microphone
                        </p>
                    </div>
                    
                    <STTDemo />
                    
                </div>
            </div>
        )
    }

    contentServiceMenu = () => {
        return (
            <div id="content-service-menu">
                <div className="landing-wrapper">
                    <Fade>
                        <h2>
                            Ucapan menjadi Teks memungkinkan konversi otomatis ucapan menjadi teks
                        </h2>
                        <div className="content-service-menu-a">
                            <div className="content-service-menu-item">
                                <Image src={itemmenu1} />
                                <h3>Call Center</h3>
                                <p>
                                    Automasi call center dan asisten virtual untuk perusahaan
                                </p>
                            </div>
                            <div className="content-service-menu-item">
                                <Image src={itemmenu2} />
                                <h3>IoT Control</h3>
                                <p>
                                    Memberikan pengalaman interaktif dengan respon suara
                                </p>
                            </div>
                            <div className="content-service-menu-item">
                                <Image src={itemmenu3} />
                                <h3>Narator</h3>
                                <p>
                                    Membantu penyandang disabilitas untuk mendapatkan pesan atau informasi dalam bentuk suara
                                </p>
                            </div>
                            {/* <div className="content-service-menu-item">
                                <Image src={itemmenu4} />
                                <h3>Text and SSML Support</h3>
                                <p>
                                    Optimasisasi penggunaan teknologi TTS dan STT
                                </p>
                            </div> */}
                        </div>
                    </Fade>
                </div>
            </div>
        )
    }

    contentFeatureTop = () => {
        return (
            <div id="content-top-landing-feature">
                <div className="landing-wrapper">
                    <Fade>
                        <div className="content-landing-feature-paragraf">
                            <h1>
                                Transkripsi Ucapan Menjadi Teks
                            </h1>
                            <p>
                                Mengubah ucapan dalam bentuk teks, sehingga anda dapat menyimpan setiap percakapan anda maupun membagikannya
                            </p>
                            <Button
                                // disabled
                                id="landingpagetts-getstarted"
                                className="btn-landing-blue"
                                onClick={() => { history.push("/register") }}
                            >
                                Try the Demo
                            </Button>
                            {/* <Image src={bubble} className="span-bubble" /> */}
                        </div>
                    </Fade>
                </div>
            </div>
        )
    }

    contentVideo = () => {
        return (
            <div className="content-landingpage-video">
                <Image src={video} className="video-play-image" />
                <button className="btn-video-play" id="landingtts-playvideo">
                    <Image src={play} />
                </button>
            </div>
        )
    }

    contentBottom = () => {
        return (
            <div id="content-bottom">
                <div className="content-item bg-gray">
                    <div className="landing-wrapper">
                        <Row>
                            <Col md={6}>
                                <Fade>
                                    <div className="content-item-wrapper">
                                        <h1>Tertarik dengan Produk kami ?</h1>
                                        <a href={window._link_.contact} target="_blank" rel="noopener noreferrer">
                                            <Button
                                                id="landingpageservice-kontakkami"
                                                className="btn-landing blue">
                                                Kontak Kami
                                            </Button>
                                        </a>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md={6}>
                                <Fade>
                                    <div className="">
                                        <Image src={kontakkami} alt="pic" className="content-item-img" />
                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }

    scrollToContent = () => {
        const element = document.getElementById('content-engine');
        element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }

    contentFeature = () => {
        return (
            <div id="landing-page-feature">
                <div className="content-bubble-2">
                    <Image src={bubble} />
                </div>
                <div id="landingpageservice-content-feature" className="landing-wrapper">
                    <div className="content-wrapper left">
                        <Fade>
                            <div className="content-paragraf medium ">
                                <h2>
                                    Transkripsi Ucapan Menjadi Teks
                                </h2>
                                <p>
                                    Mengubah ucapan dalam bentuk teks, sehingga anda dapat menyimpan setiap percakapan anda maupun membagikannya
                                </p>
                                <div className="btn-landing-wrapper">
                                    <Button
                                        onClick={() => history.push("/register")}
                                        id="landingpageservice-getstartedstt"
                                        className="btn-lihat-detail">
                                        Segera Mulai
                                    </Button>
                                    {/* <Button className="btn-lihat-detail secondary">
                                        <img src={playbtn} alt="icon" id="icon-play" /> Putar Video
                                    </Button> */}
                                    <Button
                                        onClick={() => this.scrollToContent()}
                                        id="landingpageservice-seedetailstt"
                                        className="btn-lihat-detail secondary">
                                        <img src={down} alt="icon" id="icon-play" /> Coba Demonya
                                    </Button>
                                </div>
                                {/* <Button 
                                    onClick={() => this.scrollToContent()}
                                    id="landingpageservice-seedetailstt"
                                    className="btn-lihat-detail demo">
                                    <img src={down} alt="icon" id="icon" /> Coba Demonya
                                </Button> */}
                            </div>
                            <div className="landing-img-wrapper">
                                <Image src={landing2} id="landing-img-ilustration" />
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                <NavbarService
                    logo={home}
                    sttActive={true}
                />
                <div id="landing-page-stt">
                    {this.contentFeature()}

                    {/* {this.contentFeatureTop()} */}
                    {/* {this.contentVideo()} */}
                    {this.contentEngine()}
                    {this.contentServiceMenu()}
                    <UsecaseSTT />
                    {this.contentPackage()}
                    <Testimony type={"stt"} />
                    <Faq type={"stt"} />

                </div>
                {this.contentBottom()}
                <FooterLanding />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        fetchingDataStreamStatus: state.STTReducers.fetchingDataStreamStatus,
        fetchedDataStream: state.STTReducers.fetchedDataStream,

        recordingStatus: state.STTReducers.recordingStatus,
        recordingDuration: state.STTReducers.recordingDuration,
        streamStatus: state.STTReducers.streamStatus,
        transcriptResult: state.STTReducers.transcriptResult
    }
}

export default connect(mapStateToProps)(LandingPageSTT);
