import moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { history } from "../../../store";

// Functions
import { nats } from '../../../middleware/NATSMiddleware';
import { NC_USER_NOTIFICATION } from '../../../middleware/NATSTopics';
import { fetchUserNotificationList, fetchUserNotificationRead } from '../../../reducers/NotificationReducers';
import { toastNotification } from '../../../modules/ToastNotification';

// Components
import { Badge, Col } from "react-bootstrap";
import NcloudPagination from '../../../components/NcloudPagination';
import Loading from '../../../components/Loading';

// Style
import './Notification.scss'

// ETC


function Notification() {
    const dispatch = useDispatch();

	const { id_token, userDetail } = useSelector(state => state.UserManagementReducers);

	const [idUser, setIdUser] = useState("");

    const [notifList, setNotifList] = useState([]);
    const [unseenTotal, setUnseenTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [offset, setOffset] = useState(0);
    const limit = 10;

    const [activePage, setActivePage] = useState(1);

    // const defaultStatus = "transaction"
    // const statusList = ["transaction", "promo"];
    // const [selectedStatus, setSelectedStatus] = useState(defaultStatus);

    const [isLoading, setIsLoading] = useState(false);

    const notifListRef = useRef([]);
    const unseenTotalRef = useRef(0);
    const totalRef = useRef(0);

    var userNotificationListener = null;

    
    useEffect(() => {
        getNotifListData(0);

        return () => {
            NatsUserNotificationUnsubcribers();
        }
    }, [])

	useEffect(() => {
        if ( userDetail === null ) return;

        const { id_user } = userDetail;

        if ( id_user !== idUser ) {
            setIdUser(id_user)
            NatsUserNotificationUnsubcribers();
            NatsUserNotificationSubcribers(id_user);
        }
	}, [userDetail])

	useEffect(() => {
        notifListRef.current = notifList
        unseenTotalRef.current = unseenTotal
        totalRef.current = total
	}, [notifList, unseenTotal, total])

	// useEffect(() => {
    //     console.log(history.location.search)

    //     parseSearchParams(history.location.search); 
	// }, [history.location.search])

	// useEffect(() => {
    //     const params = new URLSearchParams({status: selectedStatus });
    //     history.push({ pathname: history.location.pathname, search: params.toString() }); 
	// }, [selectedStatus])

	useEffect(() => {
        const offset = (activePage-1)*limit;
        
        getNotifListData(offset);

	}, [activePage])

    const getNotifListData = (req_offset=0, req_limit=limit) => {
        setIsLoading(true);
        setNotifList([]);
        setOffset(req_offset);

        // return;
        dispatch(fetchUserNotificationList(id_token, req_offset, req_limit))
            .then((result) => {
                if ( result.offset === req_offset ) {
                    const data = result.data;
                    
                    if ( JSON.stringify(data) !== JSON.stringify(notifList) ) {
                        setNotifList(data)
                    }
                    
                    setUnseenTotal(result.unseen)
                    setTotal(result.total)

                    setIsLoading(false)
                }

            })
            .catch((err) => {
                toastNotification(
                    'error',
                    'Gagal memuat data notifikasi pengguna',
                    err.toString()
                );

                setIsLoading(false)                
            })
    }

    const NatsUserNotificationSubcribers = (id_user) => {
        if ( userNotificationListener !== null ) return;
    
        const topic = `${NC_USER_NOTIFICATION}.${id_user}`

        // console.log(topic)
        
        // console.log("NatsUserNotificationSubcribers " + topic)
    
        userNotificationListener = nats.subscribe(topic, (response) => {
            const data = JSON.parse(response);

            rcvNewUserNotification(data);
        });
    }
    
    const NatsUserNotificationUnsubcribers = () => {
        // if ( userNotificationListener === null ) return;
    
        // console.log("NatsUserNotificationUnsubcribers")
        nats.unsubscribe(userNotificationListener);
    
        userNotificationListener = null;
    }

    // const parseSearchParams = (search) => {
    //     const search_params = queryString.parse(search);
    //     const { status } = search_params;
    //     if ( status !== undefined ) {
    //         setSelectedStatus(status)
    //     } else {
    //         setSelectedStatus(defaultStatus)
    //     }
    // }
    

    const rcvNewUserNotification = (data) => {
        // console.log("rcvNewUserNotification")

        let newNotifList = notifListRef.current.slice();
        
        const index = newNotifList.findIndex(({id}) => id === data.id)
        
        if ( index === -1 ) {
            newNotifList.unshift(data)
            setNotifList(newNotifList)

            setUnseenTotal(unseenTotalRef.current+1)
            setTotal(totalRef.current+1)
        };

    }

    const handleNotifRowClick = (notif) => {
        if ( !notif.seen ) {
            dispatch(fetchUserNotificationRead(id_token, notif.id))
                .then((result) => {
                    let newNotifList = notifList.slice();
                    
                    const index = newNotifList.findIndex(({id}) => id === notif.id)
                    if ( index === -1 ) return;

                    if ( newNotifList[index].seen ) return;
                    
                    setUnseenTotal(unseenTotal-1)
                    
                    newNotifList[index].seen = true;

                    setNotifList(newNotifList)

                })
                .catch((err) => {

                })
        }

        history.push(notif.url);
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }



    return (
        <div className="notification-page-main-container">
            <div className="notification-page-content-container">
                <div className="head">
                    <Col xs={12}>
                        <div className="title">Notification</div>
                    </Col>
                </div>
                <div className="body">
                    {/* <StatusNav
                        statusList={statusList} 
                        selectedStatus={selectedStatus} 
                        setSelectedStatus={setSelectedStatus} 
                    /> */}

                    { isLoading ?
                        <Loading />
                        :
                        <>
                            { notifList.map((notif) => 
                                <div key={"notif-"+notif.id} 
                                    className={"notification-row "+(notif.seen?"seen":"unseen")} 
                                    onClick={() => handleNotifRowClick(notif)}
                                >
                                    <Badge  
                                        variant={(
                                            notif.type === "transaction" ? "success" :
                                            notif.type === "promo" ? "primary" :
                                            "info"
                                        )}
                                    >{notif.type}</Badge>
                                    {/* <div>{notif.info}</div> */}
                                    <div dangerouslySetInnerHTML={{ __html: notif.info }} />
                                    <div className="date">{moment(notif.date).format("DD MMM YYYY, HH:mm")}</div>
                                </div>
                            ) }
                        </>
                    }
                </div>
                <div className="footer">
                    <NcloudPagination
                        activePage={activePage}
                        itemPerPage={limit}
                        totalItems={total}
                        onChange={handlePageChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default Notification;