import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from 'react-router-dom';

//FUNCTIONS
import { fetchUserDetail } from '../reducers/UserManagementReducers';
import { fetchServiceList } from '../reducers/ServiceReducers';

//COMPONENTS
import LoadingFullPage from '../components/LoadingFullPage';
import Redirector from '../page/Redirector';
import NewPasswordPage from '../page/OnBoard/NewPasswordPage/NewPasswordPage';
import Dashboard from '../page/Dashboard/Dashboard';
import Cart from '../page/payment/Cart';
import PrivacyPolicy from '../page/OnBoard/PrivacyPolicy';
import TermsService from '../page/OnBoard/TermsService';
import VerifyEmailPage from '../page/OnBoard/VerifyEmailPage/VerifyEmailPage';

//CSS IMPORTS


function PrivateRoutes() {
	const dispatch = useDispatch();
	
	const { id_token, userDetail } = useSelector(state => state.UserManagementReducers);
    
    const [tokenExist, setTokenExist] = useState(false);

    useEffect(() => {
		if ( tokenExist || id_token === null || id_token === undefined ) 
            return;

        setTokenExist(true);
    }, [id_token])

	useEffect(() => {
		if ( !tokenExist ) return;

		dispatch(fetchUserDetail(id_token))
            .then(()=>{})
            .catch(()=>{});
		dispatch(fetchServiceList(id_token))
            .then(()=>{})
            .catch(()=>{});
	}, [tokenExist])	


	// show loading page if token not updated yet
	if ( !tokenExist || userDetail === null || userDetail === undefined ) { 
		return <LoadingFullPage info="" />;
	}

	return (
		<>
            <Switch>
                <Route exact path="/reset" component={NewPasswordPage} />
                <Route exact path="/verify" component={VerifyEmailPage} />
                {/* <Route exact path="/verify-account" component={VerifyAccountPage} /> */}
                {/* <Route exact path="/change" component={ChangePasswordPage} /> */}
                <Route exact path="/cart" component={Cart} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/terms-service" component={TermsService} />
                <Route path="/redirector?" component={Redirector} />
                <Route path="/" component={Dashboard} />
                <Redirect from="/:someroute" to="/" />
            </Switch>
		</>
	)
}

export default PrivateRoutes;
