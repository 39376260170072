/** 
Declare the type of action as constant.
WHY?
    - Help reduce typos 
    - Help reduce bugs and mistake
    - If you make typo and dispatch an undefined constants,
      the app will throw error to alert the mistake. 
*/

// Import
import axios from 'axios';

// Functions
import {
    streamAudioBlob,
    lastOffsetStream,
    spliceAudioBlobs,
    setLastOffsetStream,
} from '../page/landingpage/StreamingRecorder';
import { addWsReqToQueue } from '../middleware/WebSocketMiddleware';
import store, { history } from '../store';

// Action Types
export const FETCHING_DATA = 'STTReducers/FETCHING_DATA'
export const REQUEST_ID_AND_RESPONSE_CHANNEL_CHANGE = "STTReducers/REQUEST_ID_AND_RESPONSE_CHANNEL_CHANGE"
export const UPDATE_KEY = "STTReducers/UPDATE_KEY"
export const UPDATE_STREAM_STATUS = "STTReducers/UPDATE_STREAM_STATUS";
export const INIT_SEND_BLOB = "STTReducers/INIT_SEND_BLOB";
export const START_RECORDING = "STTReducers/START_RECORDING";
export const STOP_RECORDING = "STTReducers/STOP_RECORDING";
export const HOLD_RECORDING = "STTReducers/HOLD_RECORDING";
export const RESUME_RECORDING = "STTReducers/RESUME_RECORDING";
export const UPDATE_RECORDING_DURATION = "STTReducers/UPDATE_RECORDING_DURATION";
export const UPDATE_TRANSCRIPT_RESULT = "STTReducers/UPDATE_TRANSCRIPT_RESULT";
export const UPDATE_UPLOADING_STATE = "STTReducers/UPDATE_UPLOADING_STATE";
export const CLEAR_NEW_MEETING_STATES = "STTReducers/CLEAR_NEW_MEETING_STATES";
export const UPDATE_STT_TOKEN = "STTReducers/UPDATE_STT_TOKEN";


// Action Creators
/* 
 *  !LEGEND! 
 *      req = request
 *      rcv = receive
 *      fch = fetch
 *      snd = send
 */


// Actions


// You can put impure functions within your action creators

export const fchStartRecording = () => ({
    type: START_RECORDING,
    recording: true,
    recordingStatus: "start"
});

export const fchPauseRecording = () => ({
    type: HOLD_RECORDING,
    recording: false,
    recordingStatus: "pause"
});

export const fchResumeRecording = () => ({
    type: RESUME_RECORDING,
    recording: true,
    recordingStatus: "resume"
});

export const fchStopRecording = () => ({
    type: STOP_RECORDING,
    recording: false,
    recordingStatus: "stop"
});

export const updateRecordingDuration = (recordingDuration) => ({
    type: UPDATE_RECORDING_DURATION,
    recordingDuration: recordingDuration
});


/** STREAMING PROCESS START */

// STREAM STATUS
export const STREAM_STATUS_REQ_BOS = 'req_bos';
export const STREAM_STATUS_RCV_BOS = 'rcv_bos';
export const STREAM_STATUS_SND_AUDIO = 'snd_audio';
export const STREAM_STATUS_RCV_AUDIO = 'rcv_audio';
export const STREAM_STATUS_REQ_EOS = 'req_eos';
export const STREAM_STATUS_RCV_EOS = 'rcv_eos';

// Update Stream Status 
export const updateStreamStatus = (streamStatus) => ({
    type: UPDATE_STREAM_STATUS,
    streamStatus: streamStatus
});

export const updateRequestIdAndResponseChannel = (requestId, responseChannel) => ({
    type: REQUEST_ID_AND_RESPONSE_CHANNEL_CHANGE,
    requestId: requestId,
    responseChannel: responseChannel
});

export const updateKey = (key) => ({
    type: UPDATE_KEY,
    key: key
});



export const fetchSttToken = () => (dispatch) => {
    // console.log("fetchSttToken")
    return new Promise((resolve, reject) => {
        const email = process.env.REACT_APP_EMAIL;
        const password = process.env.REACT_APP_PASSWORD;

        const basicBase64 = Buffer.from(`${email}:${password}`).toString('base64');
        // console.log(basicBase64)

        const url = `${process.env.REACT_APP_API_URL}/v2/prod/getToken`;
        //const url = `${process.env.REACT_APP_API_URL}/v2/prod/stt/getToken`;
        //const url = `${process.env.REACT_APP_SERVER_URL}/app/console/${process.env.REACT_APP_SYSTEM_STATUS}/service/gettoken/`
        // const url = `https://apidev.bahasakita.co.id/v2/prod/getToken`;

        // fetch(url, {
        //     headers: {
        //         "Authorization"	: "Basic "+basicBase64,
        //     },
        //     method: "POST"
        // })
        // const body = {
        //     id_token: store.getState().UserManagementReducers.id_token
        // };
        const body = {
            bk: { data: { service: "stt" } }
        };


        fetch(url, {
            headers: {
                "Authorization"	: "Basic "+basicBase64,
                "Content-Type": 'application/json'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw (response.status + " " + response.statusText)
                }
            })
            .then(result => {
                // console.log(result)
                const stt_token = result.bk.data.token;
                // let stt_token;
                // for (let count in result.tokens) {
                //     console.log("datatoken :", result.tokens[count])

                //     if (result.tokens[count].type === "STT") {
                //         stt_token = result.tokens[count].token;
                //         break;
                //     }
                // }
                console.log("stt_token :", stt_token)
                dispatch(updateSttToken(stt_token))

                resolve(stt_token)
            })
            .catch((err) => {
                reject(err.toString())
            })
    })
}

export const updateSttToken = (token) => ({
    type: UPDATE_STT_TOKEN,
    sttToken: token
});

export const rcvTranscriptResult = (msg) => (dispatch, getState) => {
    if (msg.partial === undefined && msg.final === undefined) return;

    var state = "";
    var text = "";

    if (msg.partial !== undefined) {
        state = "partial"
        text = msg.partial;
    } else if (msg.final !== undefined) {
        state = "final"
        text = msg.final;
    }

    var transcriptResult = getState().STTReducers.transcriptResult.slice();

    if (
        transcriptResult.length === 0 ||
        (transcriptResult.length > 0 && transcriptResult[transcriptResult.length - 1].state === "final")
    ) {
        // new transcript
        const data = {
            state: state,
            text: text,
            full_audio: ""
        }
        transcriptResult.push(data);

    } else {
        const index = transcriptResult.length - 1;

        transcriptResult[index].datetime = new Date();
        transcriptResult[index].state = state

        if (state === "partial") {
            transcriptResult[index].text = transcriptResult[index].text + " " + text;
        } else if (state === "final") {
            transcriptResult[index].text = text.trim() + ".";
        }

    }

    dispatch(updateTranscriptResult(transcriptResult));
}

export const updateTranscriptResult = (transcriptResult) => ({
    type: UPDATE_TRANSCRIPT_RESULT,
    transcriptResult: transcriptResult
});

/********** STREAM - START **********/
var sess_id = "";

export const reqStartStream = () => (dispatch, getState) => {
    // console.log("reqStartStream")

    dispatch(updateStreamStatus(STREAM_STATUS_REQ_BOS));

    let data = {
        "bk": {
            "service": "stt",
            "type": "audioConn"
        }
    }
    data = JSON.stringify(data);

    // dispatch(natsMessageSend("audioConn", data));
    addWsReqToQueue(data);
}

export const rcvStartStream = (msg) => (dispatch, getState) => {
    // console.log("rcvStartStream")

    // startTransriptShowInterval()

    dispatch(updateStreamStatus(STREAM_STATUS_RCV_BOS));

    sess_id = msg.sess_id

    // Send first saved audio to server
    dispatch(reqReadAndSendBlobStream());

}

// var blobSizeSent = 0;
// var totalAudioSent = 0;

export const reqSendAudioStream = (audio_chunk) => (dispatch, getState) => {
    // console.log("reqSendAudioStream")

    dispatch(updateStreamStatus(STREAM_STATUS_SND_AUDIO));

    // blobSizeSent += audio_chunk.size;
    // totalAudioSent += audio_chunk.size;;

    setLastOffsetStream(lastOffsetStream + audio_chunk.size);

    var reader = new FileReader();
    reader.onload = function (event) {
        var audio_base64 = event.target.result;
        // console.log("--------------------------")
        // console.log(audio_base64)
        audio_base64 = audio_base64.substr(audio_base64.indexOf(',') + 1);
        // console.log(audio_base64)

        let data = {
            "bk": {
                "service": "stt",
                "type": "audioStream",
                "sess_id": sess_id,
                "data": {
                    "audio": audio_base64
                }
            }
        }
        data = JSON.stringify(data);

        // dispatch(natsMessageSend("audioStream", data));
        addWsReqToQueue(data);

        dispatch(rcvSendAudioStream());
    }
    reader.readAsDataURL(audio_chunk);
}

export const rcvSendAudioStream = () => (dispatch, getState) => {
    // console.log("rcvSendAudioStream")

    dispatch(updateStreamStatus(STREAM_STATUS_RCV_AUDIO));

    var streamAudioBlobSize = streamAudioBlob.size;

    // if audio blob exist
    if (streamAudioBlobSize > 0) {
        // try to send audio
        dispatch(reqReadAndSendBlobStream());
    }
    // if audio blob not exist
    else {
        // if not recording
        if (
            getState().STTReducers.recordingStatus === "" ||
            getState().STTReducers.recordingStatus === "pause" ||
            getState().STTReducers.recordingStatus === "stop"
        ) {
            // then request eos
            dispatch(reqStopStream());

        }
        // if still recording
        else {
            // wair for next audio to be recorded and try to send it
            setTimeout(function () {
                dispatch(reqReadAndSendBlobStream());
            }, 200)
        }
    }
}

export const reqStopStream = () => (dispatch, getState) => {

    // console.log("reqStopStream")

    dispatch(updateStreamStatus(STREAM_STATUS_REQ_EOS));

    // set init send blob true 
    // so it will request start stream on audio available next time
    dispatch(reqInitSendBlobChange(true));

    let data = {
        "bk": {
            "service": "stt",
            "type": "audioStop",
            "sess_id": sess_id
        }
    }
    data = JSON.stringify(data);

    addWsReqToQueue(data);
}

export const rcvStopStream = () => (dispatch, getState) => {
    // console.log("rcvStopStream")

    dispatch(updateStreamStatus(STREAM_STATUS_RCV_EOS));
}
/********** STREAM - END **********/


/********** UPLOAD - START **********/
export var cancelUploadSTTAudioTokenSource = null;

export const cancelUploadSTTAudio = () => () => {
    if (cancelUploadSTTAudioTokenSource === null) return;

    // Cancel request
    cancelUploadSTTAudioTokenSource.cancel();
    cancelUploadSTTAudioTokenSource = null
}

// export const uploadSTTAudio = (file) => (dispatch, getState) => {
//     return new Promise((resolve, reject) => {
//         const request_id        = get_random_uuid();
//         const response_channel  = `${NC_UTTERANCE_LISTENER}.${request_id}`;

//         dispatch(updateRequestIdAndResponseChannel(request_id, response_channel));

//         cancelUploadSTTAudioTokenSource = axios.CancelToken.source();

//         // upload with axios
//         const data = new FormData();
//         data.append('request_id', request_id); 
//         data.append('response_channel', response_channel); 
//         data.append('files', file);
//         // console.log(response_channel)

//         var url = process.env.REACT_APP_SERVER_URL + "/app/console/" + process.env.REACT_APP_SYSTEM_STATUS + "/stt/upload/";

//         const config = {
//             cancelToken: cancelUploadSTTAudioTokenSource.token,
//             onUploadProgress: progressEvent => {
//             }
//         }

//         axios.post(url, data, config)
//             .then(res => { // then print response status
//                 // console.log(res.data)
//                 if ( res.data.status === "success" ) {
//                     // const response_channel  = getState().STTReducers.responseChannel;
//                     NatsSttSubcribers(response_channel);

//                     resolve(res.data)
//                 } else {
//                     throw(res.data.error)
//                 }
//             })
//             .catch(err => {
//                 // if ( err === undefined ) return;

//                 reject(err.toString())

//                 // if ( err.toString() === "Cancel" ) return;
//             });
//     });
// }


// NEW API
export const updateUploadingState = (isUploading, uploadingProgress = 0) => ({
    type: UPDATE_UPLOADING_STATE,
    isUploading: isUploading,
    uploadingProgress: uploadingProgress
});

export const uploadSTTAudio = (file, progressCallback) => (dispatch, getState) => {
    // console.log(file)

    return new Promise(async (resolve, reject) => {

        cancelUploadSTTAudioTokenSource = axios.CancelToken.source();

        dispatch(updateUploadingState(true));

        const basicBase64 = getState().STTReducers.sttToken;

        const url = `${process.env.REACT_APP_API_URL}/v2/prod/stt/upload`;
        // const url = `https://apidev.bahasakita.co.id/v2/prod/stt/upload`;

        let formData = new FormData()
        formData.append("file", file);

        const config = {
            cancelToken: cancelUploadSTTAudioTokenSource.token,
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Basic " + basicBase64,
            },
            onUploadProgress: (progressEvent) => {
                const progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
                // console.log(progress)
                dispatch(updateUploadingState(true, progress));
                progressCallback(progress)
            }
        }

        axios.post(url, formData, config)
            .then((response) => {
                // console.log(response.data)

                dispatch(updateUploadingState(false));
                progressCallback(100)
                    ;
                // dispatch(updateTranscriptResultUpload(response.data.bk.data.text));
                dispatch(updateTranscriptResult([{
                    state: "final",
                    text: response.data.bk.data.text,
                    full_audio: ""
                }]));

                resolve(response)
            })
            .catch((err) => {
                // console.log(err)

                dispatch(updateUploadingState(false, "error"));

                reject(err.toString())
            })
    })
}
/*********** UPLOAD - END ***********/

/********** UPLOAD - START **********/
export const uploadAudio = (file, link, language, diarization, subtitle, summary, topicSummary, numberSummary, progressCallback) => (dispatch, getState) => {
    // console.log(file)

    return new Promise((resolve, reject) => {


        dispatch(updateUploadingState(true));

        const token = getState().STTReducers.sttToken;

        //console.log(token);

        const url = `${process.env.REACT_APP_API_URL}/v2/prod/stt/async/upload`;

        console.log(url);
        let formData = new FormData()
        if (file === null) {
            formData.append("url", link);
            formData.append("target_language", language);
            formData.append("diarization", diarization);
            formData.append("subtitle_cc", subtitle);
            formData.append("check_summary", summary);
            formData.append("title", topicSummary);
            formData.append("max_sentence_summary", numberSummary);
            formData.append("priority", "reguler");
        }
        if (link === null) {
            formData.append("file", file);
            formData.append("target_language", language);
            formData.append("diarization", diarization);
            formData.append("subtitle_cc", subtitle);
            formData.append("check_summary", summary);
            formData.append("title", topicSummary);
            formData.append("max_sentence_summary", numberSummary);
            formData.append("priority", "reguler");
        }



        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + token,
            },
            onUploadProgress: (progressEvent) => {
                const progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
                // console.log(progress)
                dispatch(updateUploadingState(true, progress));
                progressCallback(progress)
            }
        }
        // console.log('upload',file,link)

        axios.post(url, formData, config)
            .then((response) => {
                // console.log('upload',response)
                resolve(response)
            })
            .catch((err) => {

                reject(err)
            })
    })
}
/*********** UPLOAD - END ***********/


/********** GET - RETRIEVE TRANSCRIPT **********/
export const retrieveTranscript = (uuid) => (dispatch, getState) => {
    // console.log(file)

    return new Promise((resolve, reject) => {

        //dispatch(updateUploadingState(true, 100));

        const token = getState().STTReducers.sttToken;
        //console.log(token);
        const config = {
            headers: {
                "Authorization": "Bearer " + token
            }
        }

        const url = `${process.env.REACT_APP_API_URL}/v2/prod/stt/async/content/`;
        const urlRetrieve = url.concat("", uuid);
        //console.log(urlRetrieve);

        // console.log("URL :", urlRetrieve);
        // const url = `https://apidev.bahasakita.co.id/v1/prod/stt/upload`;


        axios.get(urlRetrieve, config)
            .then((response) => {

                resolve(response)
            })
            .catch((err) => {
                console.log(err)
                reject(err)
            })
    })
}




/** STREAMING PROCESS END */

/** AUDIO PROCESS */

export const reqReadAndSendBlobStream = () => (dispatch, getState) => {
    var streamAudioBlobSize = streamAudioBlob.size;
    // console.log(streamAudioBlob)
    if (streamAudioBlobSize > 0) {
        var blob;
        var blob_length;

        // blob_length = length of blob that will be sent
        blob_length = getState().STTReducers.streamLimit;

        /*** If streamAudioBlob size < blob_length,  blob that will be sent = streamAudioBlob size ***/
        if (streamAudioBlobSize < blob_length) {
            blob_length = streamAudioBlobSize;
        }

        blob = streamAudioBlob.slice(0, blob_length, 'audio/wav');
        spliceAudioBlobs(blob_length);

        dispatch(reqSendAudioStream(blob));

        setLastOffsetStream(lastOffsetStream + blob_length);

    } else {
        if (getState().STTReducers.recordingStatus === "stop") {
            if (
                getState().STTReducers.streamStatus !== "req_eos" &&
                getState().STTReducers.streamStatus !== "rcv_eos"
            ) {
                dispatch(reqStopStream());
            }

        } else {
            setTimeout(function () {
                dispatch(reqReadAndSendBlobStream());
            }, 200)
        }
    }
}

/** AUDIO PROCESS END */

export const reqInitSendBlobChange = (value) => ({
    type: INIT_SEND_BLOB,
    initSendBlob: value
});

export const clearRecordingStates = () => ({
    type: CLEAR_NEW_MEETING_STATES
})

export const updateFetchingDataStream = (topic, data) => ({
    type: FETCHING_DATA,
    fetchingDataStreamStatus: topic,
    fetchedDataStream: data
});

// Reducer's initial state
const initialState = {
    fetchingDataStreamStatus: "",
    fetchedDataStream: {},

    requestId: "",
    responseChannel: "",
    key: "",

    recording: false,
    recordingStatus: "",
    recordingDuration: 0,

    streamStatus: "",
    streamLimit: 6400,
    initSendBlob: true, // To check whether it's the first time 
    // sending audio stream blobs

    isUploading: false,
    uploadingProgress: 0,

    transcriptResult: [],

    sttToken: ""
};


// Reducers

// You must only write pure function when trying to build the reducer! 

export default function STTReducers(state = initialState, action) {
    switch (action.type) {
        case FETCHING_DATA:
            return {
                ...state,
                fetchingDataStreamStatus: action.fetchingDataStreamStatus,
                fetchedDataStream: action.fetchedDataStream
            }
        case REQUEST_ID_AND_RESPONSE_CHANNEL_CHANGE:
            return {
                ...state,
                requestId: action.requestId,
                responseChannel: action.responseChannel
            };
        case UPDATE_KEY:
            return {
                ...state,
                key: action.key
            };

        /******* STREAM *******/
        case START_RECORDING:
            return {
                ...state,
                recording: action.recording,
                recordingStatus: action.recordingStatus
            };
        case HOLD_RECORDING:
            return {
                ...state,
                recording: action.recording,
                recordingStatus: action.recordingStatus
            };
        case RESUME_RECORDING:
            return {
                ...state,
                recording: action.recording,
                recordingStatus: action.recordingStatus
            };
        case STOP_RECORDING:
            return {
                ...state,
                recording: action.recording,
                recordingStatus: action.recordingStatus
            };

        case UPDATE_RECORDING_DURATION:
            return {
                ...state,
                recordingDuration: action.recordingDuration
            };

        case INIT_SEND_BLOB:
            return {
                ...state,
                initSendBlob: action.initSendBlob
            };

        case UPDATE_STREAM_STATUS:
            return { ...state, streamStatus: action.streamStatus };

        /******* STREAM *******/


        /******* UPLOAD *******/
        case UPDATE_UPLOADING_STATE:
            return {
                ...state,
                isUploading: action.isUploading,
                uploadingProgress: action.uploadingProgress
            };
        /******* UPLOAD *******/

        case UPDATE_TRANSCRIPT_RESULT:
            return {
                ...state,
                transcriptResult: action.transcriptResult
            };

        case UPDATE_STT_TOKEN:
            return {
                ...state,
                sttToken: action.sttToken
            };

        case CLEAR_NEW_MEETING_STATES:
            return {
                ...state,
                fetchingDataStreamStatus: "",
                fetchedDataStream: {},

                requestId: "",
                responseChannel: "",
                key: "",

                recording: false,
                recordingStatus: "",
                recordingDuration: 0,

                streamStatus: "",
                initSendBlob: true, // To check whether it's the first time 
                // sending audio stream blobs

                transcriptResult: []
            };
        default:
            return state;
    }
}



// Side effects, only as applicable
// e.g. thunks,epics, etc