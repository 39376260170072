
import React, { Component } from 'react'

// COMPONENTS
import { Button, Form } from 'react-bootstrap'

// FUNCTIONS
import { getServiceNameByAcronym } from '../../utils/services';

// ASSETS
import diamond from '../../img/diamond-gray.svg'


class OrderSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            maxTransaction: 99999999999, // max transaction is 99999999999
            maxPackageQuantity: 0, // need to be calculate based on maxTransaction and package price         
        }
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.calculateMaxPackageQuantity(this.props.price)
    }

    componentDidUpdate(prevProps) {
        if (this.props.price !== prevProps.price) {
            this.calculateMaxPackageQuantity(this.props.price)
        }
    }

    calculateMaxPackageQuantity = (price) => {
        this.setState({
            maxPackageQuantity: Math.floor(this.state.maxTransaction / price)
        }, () => {
            // adjut input quantity width
            const new_width = (this.state.maxPackageQuantity.toString().length * 10);
            if (this.inputRef.current !== null) {
                this.inputRef.current.style.width = new_width + "px";
            }
        })
    }

    priceFormatter = (price) => {
        // add '.' on every thousand
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        // Trilyun
        if (price / 1000000000000 >= 1) {
            price = parseFloat((price / 1000000000000).toFixed(2)).toString() + " Trilyun";
        }
        // Milyar
        else if (price / 1000000000 >= 1) {
            price = parseFloat((price / 1000000000).toFixed(2)).toString() + " Milyar";
        }
        // Juta
        else if (price / 1000000 >= 1) {
            price = parseFloat((price / 1000000).toFixed(2)).toString() + " Juta";
        }
        // Ribu
        else if (price / 1000 >= 1) {
            price = parseFloat((price / 1000).toFixed(2)).toString() + " Ribu";
        }
        return price;
    }

    priceFormat2 = (value) => {
        let price = Math.floor(value/100)*100
        return new Intl.NumberFormat("id-UD", {
            style: "currency",
            currency: "IDR"
        }).format(price);

    }


    render() {        
        const selected_package = this.props.service_package_selected;
        
        // add minimal selected quantity
        let price = this.priceFormat2(this.props.price * selected_package.minimal);
        let total = this.priceFormat2(this.props.price * this.props.quantity);
        
        if (process.env.NODE_ENV === 'development') {
            console.log("order quantity :", this.props.quantity);
            console.log("type data :", typeof this.props.quantity);
            console.log("select :", selected_package)
        }
        if (this.props.quantity <= selected_package.minimal) {
            this.props.setQuantity(selected_package.minimal)
        }
        return (
            <React.Fragment>
                <div className="header">
                    <div className="title">Ringkasan Pesanan</div>
                    <div className="desc">
                        Cek kembali pesanan Anda, apakah sudah sesuai dengan kebutuhan Anda
                    </div>
                    <hr />
                </div>
                <div className="payment-plan-type">
                    <img src={diamond} alt="image" />
                    <div className="payment-plan-type-name">
                        <div className="title">{getServiceNameByAcronym(selected_package.type)}</div>
                        <div className="type">{selected_package.name}</div>
                        <div className="price">{price}<span className="unit">/{selected_package.unit_name}</span></div>
                    </div>
                </div>
                <div className="payment-form">
                    {/* {selected_package.type !== 'dikte.in' &&
                        <Form.Group className="plan-duration">
                            <Form.Label>Jumlah Paket ({selected_package.unit_name})</Form.Label>
                            <Form.Label className="input-duration">
                                <Button className="operand-btn"
                                    disabled={this.props.quantity <= selected_package.minimal ? true : false}
                                    onClick={() => this.props.setQuantity(this.props.quantity - 1)}
                                >-</Button>
                                <Form.Control size="sm" type="number"
                                    ref={this.inputRef}
                                    id="input-ammount"
                                    min={selected_package.minimal}
                                    max={this.state.maxPackageQuantity}
                                    value={this.props.quantity}
                                    onChange={(e) => {
                                        if (parseInt(e.target.value) > this.state.maxPackageQuantity) {
                                            this.props.setQuantity(this.state.maxPackageQuantity)
                                        } else if (e.target.value > 0) {
                                            this.props.setQuantity(parseInt(e.target.value))
                                        } else {
                                            this.props.setQuantity(1)
                                        }
                                    }}
                                />
                                <Button className="operand-btn"
                                    disabled={this.props.quantity === this.state.maxPackageQuantity ? true : false}
                                    onClick={() => this.props.setQuantity(this.props.quantity + 1)}
                                >+</Button>
                            </Form.Label>
                        </Form.Group>} */}

                    {/* <Form.Group className="engine">
                        <Form.Label>Engine</Form.Label>
                        <Form.Label className="right">1</Form.Label>
                    </Form.Group> */}

                    <hr />
                    <Form.Group className="total-amount">
                        <Form.Label>Total</Form.Label>
                        <Form.Label className="right">{total}</Form.Label>
                    </Form.Group>
                </div>
            </React.Fragment>
        )
    }
}

export default OrderSummary
