// import { get_random_uuid } from "./utils";

export function downloadFromURL(url) {
    // var hiddenIFrameID  = 'hiddenDownloader-'+get_random_uuid();
    // var iframe  = document.getElementById(hiddenIFrameID);
    // if (iframe === null) {
    //     iframe = document.createElement('iframe');
    //     iframe.id = hiddenIFrameID;
    //     iframe.style.display = 'none';
    //     document.body.appendChild(iframe);
    // }
    // iframe.src = url;
    // setTimeout(() => {
    //     iframe.remove();
    // }, 2000)

    var filename = url.substring(url.lastIndexOf('/')+1);

    fetch(url)
        .then(resp => resp.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            // alert('your file has downloaded!'); // or you know, something with better UX...
            a.remove();
        })
        .catch(() => {});
};