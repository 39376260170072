import store from '../store';

import { NATSProtocolIncomingSwitcher } from '../middleware/NATSProtocolIncoming';

var responseTimeout;

export function setStreamResponseTimeout() {
    clearStopWatchInterval()
    stopWatch();

    if (responseTimeout) { 
        clearTimeout(responseTimeout);
    }

    responseTimeout = setTimeout(function () {
        stopWatchTime = 0;
        
        clearStreamResponseTimeout();       

        var topic = store.getState().STTReducers.fetchingDataStreamStatus;
        if ( topic.indexOf("request_") === -1 ) {
            return;
        }

        topic = topic.replace("request_", "");
        
        
        var response = {};

        var fetchedDataStream = store.getState().STTReducers.fetchedDataStream;
        for ( var key in fetchedDataStream ) {
            response[key] = fetchedDataStream[key];
        }

        response["status"] = "failed";
        response["error"] = "Tidak dapat menerima respon dari server";//. Cek koneksi Kamu dan coba lagi.";
        response["data"] = [];
        
        NATSProtocolIncomingSwitcher(topic, JSON.stringify(response)); 

    }, 20000); //20 detik
    // }, 60000); //60 detik
    
};

export function clearStreamResponseTimeout() {
    if (responseTimeout) { 
        clearTimeout(responseTimeout);
    }
    
    clearStopWatchInterval()
};

var stopWatchTime = 0;
var stopWatchInterval;

function stopWatch() {
    stopWatchInterval = setInterval(function () {
        stopWatchTime+=1;
        // console.log(stopWatchTime);
    }, 1000)
}

function clearStopWatchInterval(){
    if (stopWatchInterval) { 
        clearInterval(stopWatchInterval);
    }
    stopWatchTime = 0;
}