import mammoth from 'mammoth'
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { cancelConvertTTS, convertTextToSpeech, fetchReqTts, fetchTTSToken, TTSASnyc, retrieveTTS, retrieveTTSUUID } from "../../reducers/TTSReducers"
import { captchaGoogle } from "../../reducers/ServiceReducers"
import store from "../../store"

// COMPONENTS
import { Image, Col } from 'react-bootstrap'

// STYLES
import './TTSDemo.scss'

//ASSETS
import arrowd from '../../img/landing/arrow-down.svg'
import downloadicon from '../../img/landing/download-icon.svg'
import fail from '../../img/landing/file-failed.png'
import fileIcon from '../../img/landing/file-tts.svg'
import reload from '../../img/landing/reload.svg'
import BKAudioPlayer from '../../page/landingpage/BKAudioPlayer/BKAudioPlayer';
import { downloadFromURL } from '../../utils/HiddenDownloader';
import { languageTTS } from '../../utils/utils';
import Dropdown from '../Dropdown';
import ReCAPTCHA from "react-google-recaptcha";
import { fetchSttToken } from '../../reducers/STTReducers';

export class TTSDemo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //input_text: "Oleh karena orang yang diperintahkan untuk cek dan amankan saksi Irfan maka perintah dari Hendra Kurniawan ke terdakwa",
            input_text: "",
            convert: "",
            audioUrl: "",
            progress: 80,
            showListDownload: false,
            listDownload: []
        };

        this.captchaRef = React.createRef();

        this.textBoxRef = React.createRef();
        this.hiddenFileInput = React.createRef();
        this.options = languageTTS.sort((a, b) => a.label.localeCompare(b.label));

        function b64toBlob(b64Data, contentType, sliceSize) {
            contentType = contentType || "";
            sliceSize = sliceSize || 512;
        
            var byteCharacters = atob(b64Data);
            var byteArrays = [];
        
            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);
                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
        
                byteArrays.push(byteArray);
            }
        
            return new Blob(byteArrays, { type: contentType });
        }
        
        this.loopSynthesize = (uuid) => {
            store.dispatch(retrieveTTSUUID(uuid)).then(retrieve => {
                if (retrieve.data.bk.message_status === "success"){
                    const blob = b64toBlob(retrieve.data.bk.audio, "audio/wav")
			        const fileURLTTS = URL.createObjectURL(blob)
			                    
                    this.setState({
                        convert: "converted",
                        listDownload: [{ audio: fileURLTTS, type: 'audio/wav' }],
                        audioUrl: fileURLTTS
                    })

                } else {
                    setTimeout(() => {
                        this.loopSynthesize(uuid);
                    }, 2000);

                }
            }).catch((err) => {
                console.log("err :", err);
                console.log("Gagal Upload");

                this.setState({
                    convert: "failed"
                })

            });
        }

        // this.loopSynthesize = (path) => {
        //     store.dispatch(retrieveTTS(path)).then(retrieve => {
        //         if (retrieve.size > 0) {
        //             var fileURLTTS = URL.createObjectURL(new Blob([retrieve], { type: 'audio/wav' }));

        //             this.setState({
        //                 convert: "converted",
        //                 listDownload: [{ audio: fileURLTTS, type: 'audio/wav' }],
        //                 audioUrl: fileURLTTS
        //             })

        //         } else {
        //             setTimeout(() => {
        //                 this.loopSynthesize(path);
        //             }, 2000);

        //         }
        //     }).catch((err) => {
        //         console.log("err :", err);
        //         console.log("Gagal Upload");

        //         this.setState({
        //             convert: "failed"
        //         })

        //     });
        // }
    }


    componentDidUpdate(prevProps) {
        // if (this.props.status !== prevProps.status) {
        //     if (this.props.status === "rcv_tts") {
        //         this.rcvConvertResult(this.props.data_rcv_tts);
        //     }
        // }
    }

    autoGrow = (element) => {
        if (element !== null) {
            element.style.height = "5px";
            element.style.height = (element.scrollHeight) + "px";
        }
    }

    updateInputText = (target, text) => {
        var txt = text;
        if (txt.length > 120) {
            txt = txt.substring(0, 120);
        }

        this.setState({
            input_text: txt
        }, () => {
            this.autoGrow(target)
        });
    }

    handleSelectFile = (e) => {
        let file = e.target.files[0];

        // get file extension
        var fileExt = file.name.split('.').pop();

        if (fileExt !== "txt" && fileExt !== "docx") {
            this.updateInputText(this.textBoxRef.current, "Unacceptable file extension. You can only upload txt and docx")
            return
        }

        if (fileExt === "txt") {
            // parse file content
            const reader = new FileReader()
            reader.onload = (e) => {
                // parsed text
                var text = e.target.result;
                // remove line breaks and multiple spaces
                text = text.replace(/(\r\n|\n|\r)/gm, " ").trim();
                // get only first 120 characters
                text = text.substring(0, 120);

                this.updateInputText(this.textBoxRef.current, text)
            };
            reader.readAsText(file)

        } else if (fileExt === "docx") {
            var reader = new FileReader();
            reader.onloadend = (e) => {
                var arrayBuffer = reader.result;

                try {
                    mammoth.extractRawText({ arrayBuffer: arrayBuffer })
                        .then((resultObject) => {
                            // parsed text
                            var text = resultObject.value;
                            // remove line breaks and multiple spaces
                            text = text.replace(/(\r\n|\n|\r)/gm, " ").trim();
                            // get only first 120 characters
                            text = text.substring(0, 120);

                            this.updateInputText(this.textBoxRef.current, text)
                        })
                        .done();
                } catch (e) {
                    this.updateInputText(this.textBoxRef.current, "Sorry, we couldn't read this file. Please use another file.")
                }
            };
            reader.readAsArrayBuffer(file);
        }

    }

    handleInputChange = ({ target }) => {
        this.updateInputText(target, target.value)
    };

    handleOnBlur = () => {
        if (this.state.input_text.length !== 0 || this.state.input_text.length > 0) {
            this.setState({
                convert: "1"
            })
        }
    }

    handleOnKey = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleConvert();
        }
    }

    handleConvert = () => {
        if (this.state.input_text.replace(/\s+/g, '').length === 0) {
            return;
        }

        var text_req = this.state.input_text;

        const language = document.getElementById('dropdown-tts-demo').getAttribute('value');

        var tokenCaptcha = ""
        if (this.captchaRef.current !== undefined) {
            tokenCaptcha = this.captchaRef.current.getValue();
            
        }


        store.dispatch(fetchTTSToken()).then((token) => {
            // console.log("tts_token :", token);
            // console.log("tokenCaptcha :", tokenCaptcha)
            store.dispatch(captchaGoogle(tokenCaptcha,token)).then(res => {
                // console.log("res captcha :", res)
            
                store.dispatch(TTSASnyc(text_req, "Text", language, "text", 10, "x-low", 1.1)).then(response => {
                    //this.loopSynthesize(response.bk.data.path, this.state);
                    this.loopSynthesize(response.bk.data.uuid, this.state);

                }).catch((err) => {
                    var errInfo = "Pastikan jaringan anda stabil"


                    if (err.response && err.response.status === 413) {
                        errInfo = "Jumlah Karakter terlalu panjang, maksimal 100 karakter. Gunakan akun anda untuk proses audio yang lebih panjang"

                    } else {
                        console.log(err)
                        console.log("Gagal Upload");
                        errInfo = err;

                    }
                    this.setState({
                        convert: "failed",
                        errInfo: errInfo
                    })
                })
                // store.dispatch(convertTextToSpeech("text", text_req))
                //     .then((res) => {
                //         // console.log(res)
                //         this.rcvConvertResult(res)
                //     })
                //     .catch((err) => {
                //         // console.log(err)   
                //         this.setState({
                //             convert: "failed"
                //         })
                //     })

                this.setState({
                    convert: "converting",
                    listDownload: []
                })
            }).catch((err) => {
                this.setState({
                    convert: "failed",
                    errInfo: "Silakan gunakan CAPTCHA terlebih dahulu"
                })
            })
        }).catch((err) => {
            // console.log(err)   
            this.setState({
                convert: "failed"
            })
        })

        if (this.captchaRef.current !== undefined) {
            this.captchaRef.current.reset();
        }

    }

    rcvConvertResult = (msg) => {
        // if (msg.status === "success") {
        //     this.setState({
        //         convert: "converted",
        //         listDownload: msg.data
        //     })

        //     if (msg.data.length > 0) {
        //         this.setState({
        //             audioUrl: msg.data[0].audio
        //         })
        //     }

        // } else {
        //     this.setState({
        //         convert: "failed"
        //     })

        // }


        const audioBase64 = "data:audio/wav;base64," + msg.bk.data.audio;
        const audioFormat = msg.bk.data.audio_format.format;
        // const audioFile = new Audio("data:audio/wav;base64," + msg.bk.data.audio);
        // const audioUrl = URL.createObjectURL(audioFile);
        // console.log(audioBase64)

        this.setState({
            convert: "converted",
            listDownload: [{ audio: audioBase64, type: audioFormat }],
            audioUrl: audioBase64
        })
    }

    playResult = () => {
        const audio = new Audio(this.state.audioUrl);
        audio.play()
            .then(() => {
                // Audio is playing.
            })
            .catch(error => {
                // console.log(error);
            });
    }

    handleInputClear = () => {

        this.setState({
            input_text: ""
        });
    };

    handleClick = event => {
        this.hiddenFileInput.current.click();
    };

    cancelUpload = () => {
        this.setState({
            convert: ""
        });

        store.dispatch(cancelConvertTTS());

        // //simulation
        // this.setState({ 
        //     convert: "converted"
        // });
    };

    handleListDownload = () => {
        this.setState({
            showListDownload: !this.state.showListDownload
        });
    };

    render() {
        return (
            <div id="tts-demo" className="content-image-wrapper m-3">
                {this.state.convert === "" &&
                    <div className="content-default">
                        <textarea
                            ref={this.textBoxRef}
                            type="text"
                            className="input-text-engine"
                            placeholder="Ketik kalimat anda di sini"
                            value={this.state.input_text}
                            onChange={this.handleInputChange}
                            // onBlur={this.handleOnBlur}
                            onKeyDown={this.handleOnKey}
                        // disabled={this.state.input_text.length >= 100 ? true : false }
                        />

                        <div>
                            <Dropdown id="dropdown-tts-demo" placeHolder="Indonesian" options={this.options} />
                            <ReCAPTCHA
                                sitekey={`${process.env.REACT_APP_SITE_KEY}`}
                                ref={this.captchaRef}
                            />
                        </div>

                        <div className="convert-btn-wrapper">
                            <label id="input-length">{this.state.input_text.replace(/\s+/g, '').length}/100</label>
                            {/* <button className="clear-btn" onClick={this.handleInputClear} id="landingpage_convertTTS_btn">
                                    Clear
                                </button> */}
                            <button className="upload-btn" onClick={this.handleClick} id="landingpage_convertTTS_btn">
                                Upload text file
                            </button>
                            <input ref={this.hiddenFileInput}
                                type="file"
                                accept=".txt, .docx"
                                onChange={this.handleSelectFile.bind(this)}
                                style={{ display: 'none' }}
                            />

                            {this.state.input_text.replace(/\s+/g, '').length > 0 ?
                                <button className="convert-btn" onClick={this.handleConvert} id="landingpage_convertTTS">
                                    Ubah ke Suara
                                </button>
                                : <button className="convert-btn disabled" disabled onClick={this.handleConvert} id="landingpage_convertTTS">
                                    Ubah ke Suara
                                </button>

                            }

                        </div>
                    </div>
                }
                {this.state.convert === "converting" &&
                    <div className="loading">
                        <div className="loading-img-wrapper">
                            <Image src={fileIcon} id="file" alt="ilustration" />
                            <Image src={reload} id="reload" alt="ilustration" />
                        </div>
                        Ubah ke Suara
                        <div className="btn-cancel" onClick={this.cancelUpload}>Cancel</div>
                    </div>
                }
                {this.state.convert === "converted" &&
                    <div className="content-default">
                        <textarea
                            ref={this.textBoxRef}
                            type="text"
                            className="input-text-engine"
                            placeholder="Ketik kalimat anda di sini"
                            value={this.state.input_text}
                            // onChange={this.handleInputChange}
                            disabled
                        />
                        <BKAudioPlayer audioFile={this.state.audioUrl} />
                        <div className="convert-btn-wrapper">
                            <label id="input-length" onClick={() => this.setState({ convert: "", input_text: "" })}>Retake</label>
                            {this.state.listDownload.length !== 0 &&
                                <>
                                    <button className="upload-btn" id="landingpage_downloadTTS_btn" onClick={this.handleListDownload}>
                                        Download <img src={arrowd} id="img-download" alt="icon" />
                                        {this.state.showListDownload === true &&
                                            // <div className="download-type-list">
                                            //     <div className="item-type"><span id="green">●</span>Mp3<img src={downloadicon} alt="download" id="dl-icon"/></div>
                                            //     <div className="item-type"><span id="blue">●</span>Mp4<img src={downloadicon} alt="download" id="dl-icon"/></div>
                                            //     <div className="item-type"><span id="red">●</span>Wav<img src={downloadicon} alt="download" id="dl-icon"/></div>
                                            // </div>
                                            <div className="download-type-list">
                                                {this.state.listDownload.map((d) => {
                                                    return (
                                                        <a key={d.audio}
                                                            // href={d.audio} download target="_blank"
                                                            // onClick={() => {this.setState({audioUrl: d.audio})}}
                                                            onClick={() => downloadFromURL(d.audio)}
                                                        >
                                                            <div className="item-type"><span>●</span>{d.type}<img src={downloadicon} alt="download" id="dl-icon" /></div>
                                                        </a>
                                                    )
                                                })}
                                                {/* <a href={d.audio} download></a> */}
                                            </div>
                                        }
                                    </button>
                                    {/* <button className="convert-btn" id="landingpage_playTTS" onClick={this.playResult}>
                                        Putar Suara
                                    </button> */}
                                </>
                            }
                        </div>
                    </div>
                }
                {this.state.convert === "failed" ?
                    <div className="loading">
                        <Image src={fail} id="i-failed" alt="ilustration" />
                        Gagal melakukan konversi
                        <span>Pastikan jaringan anda stabil dan gunakan kata-kata dan tanda baca yang sesuai</span>
                        <div className="btn-cancel-1" onClick={this.cancelUpload}>Retake</div>
                    </div>
                    : null
                }
            </div>
        )

    }
}

function mapStateToProps(state) {
    return {
        status: state.TTSReducers.status,
        data_rcv_tts: state.TTSReducers.data_rcv_tts,
    }
}

export default connect(mapStateToProps)(TTSDemo);
