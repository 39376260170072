import React, { Component } from "react";

// Components
import Particles from 'react-tsparticles';
import { loadFull } from "tsparticles";

// Style
import './ParticlesBackground.scss'


class ParticlesBackground extends Component {
    particlesInit = async (main) => {
        // console.log(main);
    
        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(main);
    };
    
    particlesLoaded = (container) => {
        // console.log(container);
    };

    render() {
        return (
            <Particles 
                className="particles-bg"
                init={this.particlesInit}
                loaded={this.particlesLoaded}
                options={{
                    particles: {
                        number: {
                            value: 10,
                            density: {
                                enable: true,
                                value_area: 800
                            }
                        },
                        color: {
                            value: "#1b1e34"
                        },
                        shape: {
                            type: "polygon",
                            options: {
                                stroke: {
                                    width: 0,
                                    color: "#000"
                                },
                                polygon: [{
                                    nb_sides: 6
                                }]
                            }
                        },
                        opacity: {
                            value: 0.3,
                            random: true,
                            anim: {
                                enable: false,
                                speed: 1,
                                opacity_min: 0.1,
                                sync: false
                            }
                        },
                        size: {
                            value: 80,
                            random: false,
                            anim: {
                                enable: true,
                                speed: 10,
                                size_min: 40,
                                sync: false
                            }
                        },
                        line_linked: {
                            enable: false,
                            distance: 200,
                            color: "#ffffff",
                            opacity: 1,
                            width: 2
                        },
                        move: {
                            enable: true,
                            speed: 8,
                            direction: "none",
                            random: false,
                            straight: false,
                            out_mode: "out",
                            bounce: false,
                            attract: {
                                enable: false,
                                rotateX: 600,
                                rotateY: 1200
                            }
                        }
                    },
                    interactivity: {
                        // detect_on: "canvas",
                        events: {
                            onhover: {
                                enable: false,
                                mode: "grab"
                            },
                            onclick: {
                                enable: false,
                                mode: "push"
                            },
                            resize: true
                        },
                        modes: {
                            grab: {
                                distance: 400,
                                line_linked: {
                                    opacity: 1
                                }
                            },
                            bubble: {
                                distance: 400,
                                size: 40,
                                duration: 2,
                                opacity: 8,
                                speed: 3
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4
                            },
                            push: {
                                particles_nb: 4
                            },
                            remove: {
                                particles_nb: 2
                            }
                        }
                    },
                    detectRetina: true
                }}
            />
        )
    }
}

export default ParticlesBackground;
