import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
// Functions

// Components

// Style
import './TransactionHistory.scss'

// ETC


class StatusNav extends Component {
    render() {
        return (
            <div className="status-nav-container">
                {this.props.statusList.map((status, index) => {
                    return (
                        <div key={"status-" + index}
                            className={"status-nav " +
                                (this.props.selectedStatus === status ? "active " : " ") +
                                status}
                            onClick={() => this.props.setSelectedStatus(status)}
                        >
                            {status}
                        </div>
                    )
                })}
                <div style={{justifyContent:'right'}}>
                <button
                    className='refresh-btn'
                    id='refreshBtn'
                    onClick={() => window.location.reload(true)}
                >
                    <FontAwesomeIcon icon={faRefresh} size="2x" />

                </button>
                </div>
            </div>
        )
    }
}

export default StatusNav;