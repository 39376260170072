import React, { Component } from 'react';
import { Col } from 'react-bootstrap';

import "./Cards.scss"

// ANIMATION
import Fade from 'react-reveal/Fade'


class ServicesCard extends Component {

    handleClick = () => {
        if ( this.props.onAddNew === undefined )
            return;

        this.props.onAddNew();
    }

    render() {
        return (
            <div className="custom-card services-card" id={this.props.id} onClick={this.handleClick}>
                {/* <Fade> */}
                    <div className="card-top">{this.props.planType}</div>
                    <h3>{this.props.title}</h3>
                    <p>{this.props.desc}</p> 
                    {/* <button className="services" id="dashboardAddService_addNew" onClick={this.handleClick}>Add New</button>                */}
                {/* </Fade> */}
            </div>
        )
    }
}

export default ServicesCard;