import React from 'react';

// Functions

// Components

// Styles
import "./SideMenu.scss"

// ETC


function SideMenu({Logo, Content, ...props}) {
    return (
        <div className="side-menu">
            <div className="fix">
                <Logo />
                <Content {...props} />
            </div>
        </div>                
    )
}

export default SideMenu;