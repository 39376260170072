//JS IMPORTS
// import { toastNotification } from "../components/ToastNotification/ToastNotification";
import { clearLocalStorage, clearCookie } from "../modules/utils";

/**
 * NOTICE
 * ONLY PUT ACTIONS AND REDUCERS THAT DEAL WITH NATS API 
 * IN THIS MODULE
 */

/** 
Declare the type of action as constant.
WHY?
    - Help with reducing typos 
    - Help with reducing bugs and mistakes
    - If you make typos and dispatch an undefined constant,
      the app will throw an error to alert the mistake. 
*/

// IMPORT ACTIONS FROM OUTSIDE SECTION
/** Put your imported actions here */

// ACTION TYPES SECTION
export const WS_OPEN             = "WS_OPEN";
export const WS_CLOSE            = "WS_CLOSE";
export const WS_ONOPEN           = "WS_ONOPEN";
export const WS_ONCLOSE          = "WS_ONCLOSE";
export const WS_ONERROR          = "WS_ONERROR";
export const WS_CONNECTING       = "WS_CONNECTING";
export const WS_CONNECTED        = "WS_CONNECTED";
export const WS_DISCONNECT       = "WS_DISCONNECT";
export const WS_DISCONNECTED     = "WS_DISCONNECTED";
export const WS_MSG_SEND         = "WS_MSG_SEND";
export const WS_NO_DATA_RECEIVED = "WS_NO_DATA_RECEIVED";


// DEFAULT ACTION NATS SECTION

/** Call this to open NATS connection */
export const wsConnOpen = () => ({
    type: WS_OPEN,
    ws_status: 'open'
});

/** Will be called when connecting to NATS */
export const wsConnConnecting = () => ({
    type: WS_CONNECTING,
    ws_status: 'connecting'
});

/** Will be called when connected to NATS */
export const wsConnConnected = () => ({
    type: WS_CONNECTED,
    ws_status: 'connected'
});

/** Call this to close NATS connection */
export const wsConnDisconnect = () => ({
    type: WS_DISCONNECT,
    ws_status: 'disconnecting'
});

/** Will be called when disconnected from NATS */
export const wsConnDisconnected = () => ({
    type: WS_DISCONNECTED,
    ws_status: 'disconnected'
});

// SIDE EFFECTS 

export const wsConnDisconnectAndLogout = () => (dispatch) => {
    // remove user's data from local storage
    clearCookie();
    clearLocalStorage();
    
    return dispatch(wsConnDisconnect());
}

/** Call this to send message via NATS */
export const wsMessageSend = (topic, msg) => {
    // console.log("====== OUTGOING DATA ======");
    // const msg2 = JSON.parse(msg)
    // console.log(msg2.bk.service, msg2.bk.type);
    // console.log("sess_id: "+msg2.bk.sess_id)
    // console.log("===========================");
    
    return {
        type: WS_MSG_SEND,
        request_topic: topic,
        message: msg
    };

};

// INITIAL STATE SECTION
const initialState = {
    ws_status     : 'disconnected',
    request_topic   : '',
    message         : {}
};


// REDUCERS SECTION

// You must only write pure function when trying to build the reducer! 

export default function WebSocketDefaultReducers(state = initialState, action) {
    switch (action.type) {
        case WS_OPEN:
            return {
                ...state,
                ws_status: action.ws_status
            }
        case WS_CONNECTING:
            return {
                ...state,
                ws_status: action.ws_status
            }
        case WS_CONNECTED:
            return {
                ...state,
                ws_status: action.ws_status
            }
        case WS_DISCONNECT:
            return {
                ...state,
                ws_status: action.ws_status
            }
        case WS_DISCONNECTED:
            return {
                ...state,
                ws_status: action.ws_status
            }
        case WS_CLOSE:
            return {
                ...state,
                ws_status: action.ws_status
            }
        case WS_MSG_SEND:
            return {
                ...state,
                request_topic   : action.request_topic,
                message         : action.message
            }
        case WS_NO_DATA_RECEIVED:
            return {
                ...state
            }
        default:
            return { ...state }
    }
}
