import { useEffect, useState } from 'react';
import { withRouter } from 'react-router'; 
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getServiceNameByAcronym } from '../../utils/services';
import BaseWalkthrough, { basic_option, locale } from './BaseWalkthrough';


const StepContent = styled.div`
	font-size: 20px;
`;

function WalkthroughServiceDetail(props) {
	const { 
		callback=()=>{}, 
		showTutorialNotif=false, 
		isNotifDropdownShown=false
	} = props;

	const location = useLocation();
	const pathname = location.pathname;
	
	const userDetail = useSelector(state => state.UserManagementReducers.userDetail)

	const [username, setUsername] = useState("")

	const [run, setRun] = useState(true);
	const [stepIndex, setStepIndex] = useState(null)
	const [currentIndex, setCurrentIndex] = useState(0)
	
	const service_acr 	= pathname.replace(/[^a-z]/ig, ''); 
	const service_name	= getServiceNameByAcronym(service_acr);

	console.log(service_acr)
	const basic_steps = [
		{
			content: (
				<StepContent>
					In this page, you can find information about {service_name} service
				</StepContent>
			),
			...basic_option,
			placement: 'center',
			target: 'body',
		}, {
			content: (
				<StepContent>
					Here, you can see all benefits you will get if you're using {service_name} service
				</StepContent>
			),
			...basic_option,
			target: ".services-card#benefits"
		},{
			content: (
				<StepContent>
					It shows all features you can use by purchasing {service_name}
				</StepContent>
			),
			...basic_option,
			target: ".services-card#features"
		},{
			content: (
				<StepContent>
					{ service_acr === "stt" ?
						<>Try our service before you buy. <br /> To see transcription result, click the microphone icon to start recording or you can upload audio file from your computer</>
						:
						service_acr === "tts" ?
							<>Try our service before you buy. <br /> To see audio result, type or upload text and click 'Ubah ke Suara' button</>
							:
						<>Watch this video to learn how to use {service_name}</>
					}
				</StepContent>
			),
			...basic_option,
			target: ".services-card#demo"
		},{
			content: (
				<StepContent>
					You can find {service_name} package here
				</StepContent>
			),
			...basic_option,
			target: ".services-card#pricing"
		},{
			content: (
				<StepContent>
					Click here to buy {service_name} package
				</StepContent>
			),
			...basic_option,
			target: "#buy-btn"
		},
	];

	const notif_steps = [
		{
			content: (
				<StepContent>
					You have new notification, let's check it out!
				</StepContent>
			),
			...basic_option,
			locale: {
				...locale,
				next: "OK"
			},
			target: "#notification-bell-container"
		},
		{
			content: (
				<StepContent>
					Congratulation! you've got free quota for Diktein, Speech to Text and Text to Speech API. Click on the notification to check it now!
				</StepContent>
			),
			...basic_option,
			target: ".notification-row"
		}
	];

	let steps = basic_steps;
	if ( showTutorialNotif ) {
		steps = [...basic_steps, ...notif_steps]
	}

	useEffect(() => {
	}, [])

	useEffect(() => {
		if ( userDetail === null )
			return;

		// get firstname from userDetail data
		const firstname = userDetail.firstname !== "" ? userDetail.firstname : userDetail.id_user;
		setUsername(firstname);
	}, [userDetail])

	useEffect(() => {
		if ( isNotifDropdownShown && currentIndex === basic_steps.length ) {
			setRun(false);
			setStepIndex(currentIndex+1);
			
			setTimeout(() => {
				setRun(true);
			}, 100)
		} else if ( !isNotifDropdownShown ) {
			setStepIndex(null);
		}
	}, [isNotifDropdownShown])
	
    const _callback = (data) => {
		callback(data);

        const { action, index, status, type, step} = data;

		setCurrentIndex(index)
    }

	// console.log(steps)

	return (
        <>
			<BaseWalkthrough 
				run={run}
				steps={steps} 
				stepIndex={stepIndex}
				callback={_callback} 
			/>
        </>
    )
}

export default WalkthroughServiceDetail;