import React, { Component } from 'react'
import { useSelector } from "react-redux";
import { NavLink } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web';
import { Button, Navbar, Image, Nav } from 'react-bootstrap'

import "./Navbar.scss"

function NavbarService(props) {
    const { id_token } = useSelector(state => state.UserManagementReducers);

    const { keycloak } = useKeycloak();

    return (
        <div id="navbar-service">
            <Navbar collapseOnSelect expand="md" id="navbar-wrapper">
                <Navbar.Brand href="/">
                    <Image src={props.logo} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    {/* <Nav className="mr-auto"></Nav> */}
                    <Nav className="ml-auto">
                        <NavLink
                            to="/stt"
                            className={props.sttActive ? "navbar-service-nav active" : "navbar-service-nav"}
                            activeClassName="active">
                            Speech to Text
                        </NavLink>
                        <NavLink
                            to="/tts"
                            className={props.ttsActive ? "navbar-service-nav active" : "navbar-service-nav"}
                            activeClassName="active">
                            Text to Speech
                        </NavLink>

                        {/* <NavLink
                            to="/diktein"
                            className={props.notulaActive?"navbar-service-nav active":"navbar-service-nav"}
                            activeClassName="active">
                            Diktein
                        </NavLink> */}
                        <a href={window._link_.contact}>
                            <Button className="btn-navbar-kontak-kami-landing blue" id="landing_navbar_contact">
                                Kontak Kami
                            </Button>
                        </a>
                        {/* { id_token === null && 
                            <NavLink to="/login">
                                <Button className="btn-navbar-kontak-kami-landing second" id="landing_navbar_login">
                                    Login
                                </Button>
                            </NavLink>    
                        }   */}
                        {!keycloak.authenticated &&
                            <NavLink to="/login">
                                <Button className="btn-navbar-kontak-kami-landing second" id="landing_navbar_login">
                                    Login
                                </Button>
                            </NavLink>
                        }
                    </Nav>

                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default NavbarService
