import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Image, Button, Row, Col } from 'react-bootstrap'

// COMPONENTS
import FooterLanding from '../../components/footerlanding/FooterLanding'
import BilingPackage from "../../components/BilingPackage/BilingPackage"

// STYLES
import "./LandingPage.scss"

// ASSETS
import landing2 from "../../img/landing/TTS.png"
import NavbarFeature from '../../components/navbar/NavbarFeature'
import video from "../../img/landing/videostt.svg"
import play from "../../img/landing/playvideo.svg"
import itemmenu3 from "../../img/landing/itemmenu3.svg"
import itemmenu4 from "../../img/landing/itemmenu4.svg"
import kontakkami from "../../img/landing/kontakkami.png"

// ANIMATION
import Fade from 'react-reveal/Fade'


export class LandingPageNotulite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            convert: "",
            transcript: ""
        };        
        this.recordingTimeout = null;
    }
    
    componentDidMount() {
        window.scrollTo(0,0)
    }

    contentPackage = () => {
        return (
            <div className="content-landing-package">
                <Fade>
                    <BilingPackage
                        landing={true}
                    />
                </Fade>
            </div>
        )
    }

    contentServiceMenu = () => {
        return (
            <div id="content-service-menu">
                <Fade>
                    <h2>
                    Diktein dapat menjadi solusi untuk berbagai kebutuhan transkripsi anda.
                    Portable dan mudah penggunaanya.
                    </h2>
                    <div className="content-service-menu-b">
                        {/* <div className="content-service-menu-item">
                            <Image src={itemmenu1} />
                            <h3>Penyuntingan Transkripsi</h3>
                            <p>
                                Revisi transkripsi dapat dilakukan tanpa harus menunggu rapat selesai.
                            </p>
                        </div>
                        <div className="content-service-menu-item">
                            <Image src={itemmenu2} />
                            <h3>Diarisasi Pembicara</h3>
                            <p>
                                Mengenali suara pembicara secara otomatis.
                            </p>
                        </div> */}
                        <div className="content-service-menu-item">
                            <Image src={itemmenu3} />
                            <h3>Performatan Teks</h3>
                            <p>
                                Menghasilkan transkripsi sesuai dengan ejaan yang disempurnakan.
                            </p>
                        </div>
                        <div className="content-service-menu-item">
                            <Image src={itemmenu4} />
                            <h3>Berbagai Sumber Audio</h3>
                            <p>
                                Dapat mentranskripsi rapat yang sedang berlangsung atau melalui rekaman rapat.
                            </p>
                        </div>
                    </div>
                </Fade>
            </div>
        )
    }

    contentFeatureTop = () => {
        return (
            <div id="content-top-landing-feature">
                <Fade>
                    <div className="content-landing-feature-paragraf">
                        <h1>
                            Transkripsi Rapat Otomatis                            
                        </h1>
                        <p>
                            {/* Notulite dapat menjadi solusi untuk berbagai kebutuhan transkripsi anda.
                            Portable dan mudah penggunaanya. */}
                            Mulai dari rapat hingga seminar, Diktein dapat menjadi solusi transkripsi Anda.
                        </p>
                        <a href={window._link_.diktein}>
                        <Button
                            // disabled
                            id="landingpagetts-getstarted"
                            className="btn-landing-blue"
                            // onClick={() => {history.push("/register")}}
                        >
                            Coba Sekarang
                        </Button>
                        </a>
                        {/* <Image src={bubble} className="span-bubble" /> */}
                    </div>
                </Fade>
            </div>
        )
    }

    contentFeature = () => {
        return (
            <div id="landing-page-notulite-feature">
                <div id="landingpageservice-content-feature" className="landing-wrapper">
                    <div className="content-wrapper content-feature-container left">
                        <Fade>
                            <div className="content-paragraf medium ">
                                <h2>
                                    Transkripsi Rapat Otomatis
                                </h2>
                                <p>
                                Mulai dari rapat hingga seminar, Diktein dapat menjadi solusi transkripsi Anda. Dilengkapi berbagai fitur seperti pemformatan teks otomatis hingga pengenal suara, Diktein mempermudah proses Notulensi rapat.
                                </p>
                                <a href={window._link_.diktein}>
                                <div className="btn-landing-wrapper">
                                {/* <Link to="/tts"> */}
                                    <Button 
                                        id="landingpageservice-seedetailtts"
                                        className="btn-lihat-detail">
                                        Lihat Detail
                                    </Button>
                                {/* </Link> */}
                                </div>
                                </a>
                            </div>
                            <div className="content-image-wrapper">
                                <Image src={landing2} />
                            </div>
                        </Fade>
                    </div>
                    
                    {/* <div className="content-wrapper content-feature-container">
                        <Fade>
                            <div className="content-image-wrapper small">
                                <Image src={landing3} />
                            </div>
                            <div className="content-paragraf medium right">
                                <h2>
                                    Sistem Manajamen yang Terintegrasi
                                </h2>
                                <p>
                                    Seluruh percakapan rapat anda dapat direkam, ditelusuri kembali dan dibagikan kepada rekan kerja anda.
                                </p>
                                <Link to="/stt">
                                    <Button 
                                        id="landingpageservice-seedetailstt"
                                        className="btn-lihat-detail">
                                        Lihat detail
                                    </Button>
                                </Link>
                            </div>
                        </Fade>
                    </div> */}
                </div>
            </div>
        )
    }

    contentVideo = () => {
        return (
            <div className="content-landingpage-video">
                <Image src={video} className="video-play-image"/>
                <button className="btn-video-play" id="landingtts-playvideo">
                    <Image src={play}/>
                </button>
            </div>
        )
    }
    
    contentBottom = () => {
        return (
            <div id="content-bottom">
                <div className="content-item bg-gray">
                    <div className="landing-wrapper">
                        <Row>
                            <Col md={6}>
                                <Fade>
                                    <div className="content-item-wrapper">
                                        <h1>Tertarik dengan Produk kami ?</h1>
                                        <a href={window._link_.contact} target="_blank" rel="noopener noreferrer">
                                            <Button
                                                id="landingpageservice-kontakkami"
                                                className="btn-landing blue">
                                                Kontak Kami
                                            </Button>
                                        </a>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md={6}>
                                <Fade>
                                    <div className="">
                                        <Image src={kontakkami} alt="pic" className="content-item-img" />
                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                <NavbarFeature />
                <div id="landing-page-notulite">

                    {this.contentFeatureTop()}
                    {/* {this.contentVideo()} */}
                    
                    {this.contentServiceMenu()}
                    {this.contentFeature()}
                    {this.contentPackage()}

                </div>
                {this.contentBottom()}
                <FooterLanding />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return { 
   }
}

export default connect(mapStateToProps)(LandingPageNotulite);
