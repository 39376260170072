import store from '../store';
import { natsMessageSend } from '../reducers/NATSDefaultReducers';
// import { updateFetchingDataStream } from '../reducers/STTReducers';
// import { addResponseTimeout } from '../modules/ResponseTimeout';
// import { updateFetchingData } from '../reducers/GlobalReducers';
// import { setStreamResponseTimeout } from '../modules/StreamResponseTimeout';
import { NC_RECORD_START, NC_RECORD_POST, NC_RECORD_STOP, NC_TTS_REQ } from './NATSTopics';
import { setStreamResponseTimeout } from '../modules/StreamResponseTimeout';
import { updateFetchingDataStream } from '../reducers/STTReducers';
// import { isMeetingLessThanFiveMin } from '../js/VideoConference/VideoConference';
// import { NATSRecordConnectionMonitorSubscribers } from './NATSMethods';

// Function to send data via nats

/////////////////////// START - RECORD AUDIO MANAGEMENT ////////////////////////
export var start_stream = function (request_id, response_channel) {
    const topic = `${NC_RECORD_START}`;
    const req = {
        "request_id"        : request_id, 
        "response_channel"  : response_channel
    };

    natsSendMessage(topic, req);
}

export var send_audio_stream = function (request_id, key, audio_base64) {
    const topic = `${NC_RECORD_POST}`; //.${key}`;
    var req = { 
        "request_id"    : request_id,
        "audio"         : audio_base64
    };

    natsSendMessage(topic, req);
}

export var stop_stream = function (request_id, key) {
    const topic = `${NC_RECORD_STOP}`; //.${key}`;
    const req = { 
        "request_id"    : request_id
    };

    natsSendMessage(topic, req);
}

// TTS REQ //
export var req_tts = function (text_req) {
    const topic = `${NC_TTS_REQ}`;
    const req = {
        "text": text_req
    };
    
    natsSendMessage(topic, req);
}

//////////////////////// END - RECORD AUDIO MANAGEMENT /////////////////////////


function natsSendMessage(topic, msg){
    if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {    
        console.log("====== OUTGOING DATA ======");
        console.log(topic);
        console.log(JSON.stringify(msg));
        console.log("===========================");
    }

    setStreamResponseTimeout();
    store.dispatch(updateFetchingDataStream("request_" + topic, msg));

    return store.dispatch(natsMessageSend(topic, JSON.stringify(msg)));
}