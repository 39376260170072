
import { history } from "../store"
import { fetchRefreshToken } from "../reducers/UserManagementReducers"

// Actions

// Action Creators
/* 
 *  !LEGEND! 
 *      req = request // set
 *      rcv = receive // get
 *      fch = fetch
 *      snd = send
 */

export const fetchUserNotificationList = (id_token, offset, limit) => (dispatch) => {
    return new Promise((resolve, reject) => {        
        const body = {
            id_token: id_token,
            offset: offset,
            limit: limit
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/" + process.env.REACT_APP_SYSTEM_STATUS + "/user/notification/list/", {
            headers: {
                "Content-Type"  : 'application/json'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            })
            .then(result => {
                // console.log(result)
                if (result.status === "success") {
                    resolve(result)
                } 
                else {
                    if ( result.error === "token expired" ) {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchUserNotificationList(id_token))
                                    .then(()=>{})
                                    .catch(()=>{});
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                        } 
                    else {
                        throw(result.error)
                    }
                } 
                
            })
            .catch(err => {
                // dispatch(reqUserPackageList([]));
                // toastNotification(
                //     'error',
                //     'Gagal memuat data paket pengguna',
                //     err.toString()
                // );
                reject(err)
            });
    });
};


export const fetchUserNotificationRead = (id_token, id_notification) => (dispatch) => {
    return new Promise((resolve, reject) => {    
        const body = {
            id_token: id_token,
            id_notification: id_notification,
            seen: true
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/" + process.env.REACT_APP_SYSTEM_STATUS + "/user/notification/update/", {
            headers: {
                "Content-Type"  : 'application/json'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            })
            .then(result => {
                // console.log(result)
                if (result.status === "success") {
                    resolve(result);
                } 
                else {
                    if ( result.error === "token expired" ) {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchUserNotificationRead(id_token, id_notification))
                                    .then(()=>{})
                                    .catch(()=>{});
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                        } 
                    else {
                        throw(result.error)
                    }
                } 
                
            })
            .catch(err => {
                // dispatch(reqUserPackageList([]));
                // toastNotification(
                //     'error',
                //     'Gagal memuat data paket pengguna',
                //     err.toString()
                // );
                reject(err)
            });
    });
};

// Reducer's initial state
const initialState = {
}

// Reducers
export default function NotificationReducers(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}