import $ from 'jquery';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import store from '../../store';

// FUNCTIONS
import { download_meeting_handler } from '../../page/landingpage/DownloadDocumentFunction';
import { streamAudioBlob } from '../../page/landingpage/StreamingRecorder';

//import { clearAudioBlobs, getFullAudioUrl, startRecording, stopRecording, streamAudioBlob } from '../../page/landingpage/StreamingRecorder';
import { timeCalculation } from '../../modules/utils';
import { cancelUploadSTTAudio, clearRecordingStates, updateTranscriptResult, uploadAudio, retrieveTranscript, updateUploadingState, fetchSttToken } from '../../reducers/STTReducers';
import { captchaGoogle } from "../../reducers/ServiceReducers"

// COMPONENTS
import { Image } from 'react-bootstrap'
import BKAudioPlayer from '../../page/landingpage/BKAudioPlayer/BKAudioPlayer';
import Dropdown from '../Dropdown';
// STYLES
import './STTDemo.scss'
import { languageMap } from '../../utils/utils';
//ASSETS
import mic from "../../img/mic-white.svg"
import wave from '../../img/landing/wave.svg'
import arrowd from '../../img/landing/arrow-down.svg'
import downloadicon from '../../img/landing/download-icon.svg'
import fail from '../../img/landing/file-failed.png'
import fileIcon from '../../img/landing/file-stt.svg'
import reload from '../../img/landing/reload.svg'

import { faUpload, faStop, faRecordVinyl, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReCAPTCHA from "react-google-recaptcha";


async function requestRecorder() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    return new MediaRecorder(stream);
}



export class STTDemo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sttFlow: null, // upload|stream

            convertState: "",

            transcript: "",
            audioUrl: "",

            listdownload: false,

            isRecording: false,
            recordingTimeout: 60000, //in miliseconds

            uploadingProgress: 0,

            convertFailedInfo: "Pastikan jaringan anda stabil dan suara yang direkam terdengar jelas",

            recorder: null,
            dataAudio: null

        };
        this.recordingTimeout = null;
        this.captchaRef = React.createRef();
        this.hiddenFileInput = React.createRef();
        this.transcriptContainerRef = React.createRef();
        this.options = languageMap.sort((a, b) => a.label.localeCompare(b.label));
        this.loopTranscript = (uuid) => {
            store.dispatch(retrieveTranscript(uuid)).then(retrieve => {
                if (retrieve.data.bk.message_status === "success" && "transcripts" in retrieve.data.bk.data) {
                    const data = retrieve.data.bk.data.transcripts
                    var color = "red";
                    var res = "";
                    //console.log(data)
                    const speakers = new Map();
                    for (const number in data) {
                        const speaker = data[number].speaker;
                        if (speakers.has(speaker)) {
                            color = speakers.get(speaker);
                        } else {
                            const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
                            color = randomColor;
                            speakers.set(speaker, color);
                        }
                        console.log(data[number].text)
                        res = res + " " + data[number].text

                    }
                    //console.log(res)
                    store.dispatch(updateUploadingState(false));
                    // dispatch(updateTranscriptResultUpload(response.data.bk.data.text));
                    store.dispatch(updateTranscriptResult([{
                        state: "final",
                        text: res,
                        full_audio: ""
                    }]));
                    this.setState({
                        convertState: "converted"
                    })



                } else {

                    setTimeout(() => {
                        this.loopTranscript(uuid);
                    }, 2000);
                }
            }).catch((err) => {
                store.dispatch(updateUploadingState(false, "error"));
                console.log("err :", err);
                console.log("Gagal Upload");
            });
        }

    }

    handleData = (e) => {
        console.log("handle Data")
        const newBlob = new Blob([e.data], { type: 'audio/wav' })
        //this.setState({ dataAudio: newBlob });

        // setAudioURL();
        //this.setState({ audioUrl:URL.createObjectURL(newBlob)});
        //console.log(this.state.audioUrl)
        this.handleUploadAudio(newBlob);



    };

    componentDidMount() {
        if (this.state.recorder === null) {
            console.log("masuk")
            var mediaDevices = null
            requestRecorder().then(mediaDevices => { mediaDevices.addEventListener("dataavailable", this.handleData); this.setState({ recorder: mediaDevices }) }
                , console.error);

            console.log(this.state.recorder)

        }
    }

    componentDidUpdate(prevProps) {

        // get recording state and set/reset recording timeout
        if (this.state.recorder !== null) {
            //console.log(this.state.recorder)
            // Manage recorder state.
            //console.log("sini nih")

            if (this.state.isRecording) {
                this.state.recorder.start();
                //console.log("start")

                //console.log("wait")
            } else {
                if (this.state.recorder.state !== "inactive") {
                    this.state.recorder.stop();
                    //console.log("stop")
                    //this.state.recorder.removeEventListener("dataavailable", handleData);
                }
                // if (this.state.dataAudio !== null && this.state.dataAudio !== prevProps.dataAudio) {
                //     this.handleUploadAudio(this.state.dataAudio);

                // }

            }


        }


        // if (this.props.recordingStatus !== prevProps.recordingStatus) {
        //     const recording = !(this.props.recordingStatus === "" || this.props.recordingStatus === "stop");

        //     this.setState({
        //         recording: recording
        //     }, () => {
        //         if (this.state.recording) {
        //             this.setState({
        //                 convertState: "converted"
        //             });

        //             this.setRecordingTimeout();
        //         } else {
        //             this.resetRecordingTimeout();
        //         }
        //     })
        // }

        // scroll to last transcript when received new transcript
        if (JSON.stringify(this.props.transcriptResult) !== JSON.stringify(prevProps.transcriptResult)) {
            this.scrollToLastTranscript();
        }

        if (
            (
                prevProps.transcriptResult.length === 0 &&
                this.props.transcriptResult.length > 0
            ) ||
            (
                this.props.streamStatus !== prevProps.streamStatus &&
                this.props.streamStatus === "rcv_eos"
            )
        ) {
            if (this.state.convertState === "converting") {
                this.setState({
                    convertState: "converted"
                })
            }

        } else if (this.props.fetchingDataStreamStatus !== prevProps.fetchingDataStreamStatus) {
            if (this.props.fetchedDataStream.status === "failed") {
                this.setState({
                    convertState: "failed",
                    convertFailedInfo: "Pastikan jaringan anda stabil dan suara yang direkam terdengar jelas"
                })
            }
        }
    }

    componentWillUnmount() {
        //clearAudioBlobs();

        // clear prev transcript result
        store.dispatch(updateTranscriptResult([]));

        store.dispatch(cancelUploadSTTAudio());
        store.dispatch(clearRecordingStates());
    }

    setRecordingTimeout = () => {
        this.recordingTimeout = setTimeout(() => {
            if (this.state.recording) {
                // console.log("TIMEOUT")
                // console.log("stop recording")
                this.onRecordBtnClick();
            }
        }, this.state.recordingTimeout + 1000)
    }

    resetRecordingTimeout = () => {
        clearTimeout(this.recordingTimeout);
        this.recordingTimeout = null;
    }

    scrollToLastTranscript = () => {
        if ($(this.transcriptContainerRef.current)[0] === undefined) return;

        var scroll_height = $(this.transcriptContainerRef.current)[0].scrollHeight;
        $(this.transcriptContainerRef.current).animate({
            scrollTop: scroll_height
        }, "fast");
    }

    handleUploadAudio = (file) => {
        //backup audio to play
        var localAudio = URL.createObjectURL(file);

        var link = null;
        const language = document.getElementById('dropdown-stt-demo').getAttribute('value');
        //console.log(language)
        var tokenCaptcha = ""
        if (this.captchaRef.current !== undefined) {
            tokenCaptcha = this.captchaRef.current.getValue();
        }

        store.dispatch(fetchSttToken()).then((token) => {
            //console.log("stt_token", token)
            store.dispatch(captchaGoogle(tokenCaptcha,token)).then(res => {
                this.setState({
                    convertState: "converting",
                    audioUrl: localAudio,
                    uploadingProgress: 0
                })

                store.dispatch(uploadAudio(file, link, language, false, false, false, "", "", (progress) => {
                    this.setState({
                        uploadingProgress: progress
                    })
                })).then(response => {
                    console.log("Response :", response);
                    if ("uuid" in response.data.bk.data) {

                        this.loopTranscript(response.data.bk.data.uuid);
                    }
                }).catch((err) => {
                    console.log(err)
                    if (err === "Cancel") return;

                    var errInfo = "Pastikan jaringan anda stabil dan suara yang direkam terdengar jelas"

                    if (err.response.status === 413) {
                        errInfo = "Durasi audio terlalu panjang, maksimal 1 menit audio. Gunakan akun anda untuk proses audio yang lebih panjang"
                    } else if (err !== "") {
                        err = err + ''
                        errInfo = err;
                    }

                    this.setState({
                        convertState: "failed",
                        convertFailedInfo: errInfo,
                    })
                })
            }).catch((err) => {
                this.setState({
                    convert: "failed",
                    convertFailedInfo: "Silakan gunakan CAPTCHA terlebih dahulu"
                })
            })
        }).catch((err) => {
            // console.log(err)   
            this.setState({
                convertState: "failed"
            })
        })

    }

    handleSelectFile = (e) => {
        let file = e.target.files[0];

        // Only accept audio files.
        // Non audio files will not be processed
        if (file.type.match(/audio.*/)) {

            // can't upload larger than 2MB
            // if (file.size > 2000000) {
            //     this.setState({
            //         convertState: "failed",
            //         convertFailedInfo: "Maks. ukuran file 2MB",
            //     })
            //     return;
            // }




            file = e.target.files[0];

            this.handleUploadAudio(file);

            // store.dispatch(
            //     uploadSTTAudio(file, 
            //         (progress) => {
            //             this.setState({
            //                 uploadingProgress: progress
            //             })
            //         }
            //     )
            // )
            //     .then((res) => {
            //         this.setState({
            //             convertState: "converted"
            //         })
            //     })
            //     .catch((err) => {
            //         // console.log(err)

            //         if ( err === "Cancel" ) return;

            //         var errInfo = "Pastikan jaringan anda stabil dan suara yang direkam terdengar jelas"

            //         if ( err.indexOf(413) > -1 ) {
            //             errInfo = "Ukuran file terlalu besar"
            //         } else if (err !== "") {
            //             errInfo = err;
            //         }

            //         this.setState({
            //             convertState: "failed",
            //             convertFailedInfo: errInfo,
            //         })
            //     })

        } else {
            this.setState({
                convertState: "failed",
                convertFailedInfo: "Bukan file audio"
            })
        }

    }

    // onRecordBtnClick = () => {
    //     const recording = this.state.recording;

    //     if (!recording) {
    //         clearAudioBlobs();

    //         // clear prev transcript result
    //         store.dispatch(updateTranscriptResult([]));

    //         startRecording();

    //         this.setState({
    //             convertState: "converting"
    //         });

    //     } else {
    //         clearTimeout(this.recordingTimeout);
    //         this.recordingTimeout = null;

    //         stopRecording();

    //         this.setState({
    //             audioUrl: getFullAudioUrl()
    //         });
    //     }
    // }

    onUploadBtnClick = event => {
        // clearAudioBlobs();

        // clear prev transcript result
        store.dispatch(updateTranscriptResult([]));

        this.hiddenFileInput.current.click();
    };

    cancelUpload = () => {
        // clearAudioBlobs();

        // clear prev transcript result
        store.dispatch(updateTranscriptResult([]));

        store.dispatch(cancelUploadSTTAudio());


        //simulation
        this.setState({
            convertState: ""
        });
    };

    handleListDownload = () => {
        this.setState({
            listdownload: !this.state.listdownload
        });
    };


    startRecording = () => {
        this.setState({ isRecording: true })
    };

    stopRecording = () => {
        this.setState({ isRecording: false })
    };



    render() {
        //const recording = this.state.recording;
        // const recording = true;

        let transcript = "";
        let transcriptResult = this.props.transcriptResult;
        transcriptResult.map((tr, index) => {
            transcript += tr.text + " ";
        })

        // if (recording) {
        //     return (
        //         <div id="stt-demo" className="content-image-wrapper mid m-3 col-md-6">
        //             <div className="content-default">
        //                 <label id="label-top">Text hasil converting</label>
        //                 <div ref={this.transcriptContainerRef} className="input-text-engine">
        //                     {transcript}
        //                 </div>
        //             </div>
        //             <div className="on-recording-wrap">
        //                 <h5>{timeCalculation(this.props.recordingDuration)}</h5>
        //                 {/* <span>Sedang Merekam ...</span> */}
        //                 {/* <img src={wave} id="icon" alt="recording" /> */}
        //                 <p id="stop" onClick={this.onRecordBtnClick}>
        //                     Stop
        //                 </p>
        //             </div>
        //         </div>
        //     )
        // }

        return (
            <div id="stt-demo" className="content-image-wrapper mid m-3 col-md-6">
                {this.state.convertState === "" &&
                    <>
                        {/* <p id="stt-record">
                            <button
                                className="btn-record"
                                id="landingpage-buttonrecord"
                                onClick={() => {
                                    this.setState({ sttFlow: "stream" });
                                    this.onRecordBtnClick();
                                }}
                            >
                                <img src={mic} />
                            </button>
                            Klik icon untuk memulai merekam suara Anda atau
                        </p> */}

                        <div className="upload-btn-container mt-4 input-group input-group-sm d-flex align-items-center justify-content-center upload-border">
                            <div className='mr-2'>
                                {
                                    !this.state.isRecording ?
                                        <>
                                            <button
                                                className="upload-btn-file"
                                                disabled={this.state.isRecording}
                                                onClick={this.startRecording}>
                                                <FontAwesomeIcon icon={faRecordVinyl} size="2x" />
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button
                                                className="stop-btn"
                                                disabled={!this.state.isRecording}
                                                id='stopBtn'
                                                onClick={() => {
                                                    this.setState({ sttFlow: "upload" });
                                                    this.stopRecording();
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faStop} size="2x" />
                                            </button>
                                        </>
                                }

                            </div>
                            <div className="text-start my-2 p-0">
                                {
                                    !this.state.isRecording ?
                                        <>
                                            <b>Rekam Suara</b>
                                            <div style={{ fontSize: 12, color: 'grey' }}>*Suara akan direkam</div>
                                        </>
                                        :
                                        <>
                                            <b>Sedang Merekam...</b>
                                            <div style={{ fontSize: 12, color: 'grey' }}>*Suara sedang direkam</div>
                                        </>
                                }
                            </div>
                        </div>

                        <ReCAPTCHA
                            sitekey={`${process.env.REACT_APP_SITE_KEY}`}
                            ref={this.captchaRef}
                        />
                        <div>
                            <Dropdown id="dropdown-stt-demo" placeHolder="Indonesia" options={this.options} />
                        </div>
                        <p id="stt-upload">

                            <label
                                onClick={() => {
                                    this.setState({ sttFlow: "upload" });
                                    this.onUploadBtnClick();
                                }}
                            >Upload file suara Anda di sini</label>



                            <input type="file"
                                ref={this.hiddenFileInput}
                                accept=" audio/*"
                                onChange={this.handleSelectFile.bind(this)}
                                style={{ display: 'none' }}
                            />
                        </p>
                    </>
                }
                {this.state.convertState === "converting" &&
                    (this.state.sttFlow === "upload" ?
                        <div className="loading">
                            <div className="loading-img-wrapper">
                                <Image src={fileIcon} id="file" alt="ilustration" />
                                <Image src={reload} id="reload2" alt="ilustration" />
                            </div>
                            Ubah ke Teks
                            {this.state.uploadingProgress !== 100 ?
                                <span className="uploading-progress">Uploading {this.state.uploadingProgress}%...</span>
                                :
                                <span>Menunggu hasil transkrip...</span>
                            }
                            <div className="btn-cancel" onClick={this.cancelUpload}>Cancel</div>
                        </div>
                        :
                        <div className="loading">
                            <span>Initiating recorder...</span>
                        </div>
                    )
                }
                {this.state.convertState === "converted" &&
                    <div className="content-default">
                        <label id="label-top">Text hasil converting</label>
                        <div ref={this.transcriptContainerRef} className="input-text-engine">
                            {transcript}
                        </div>
                        {streamAudioBlob.size === 0 &&
                            <>
                                <BKAudioPlayer audioFile={this.state.audioUrl} />
                                <div className="convert-btn-wrapper">
                                    <>
                                        {transcriptResult.length > 0 &&
                                            <button className="upload-btn" id="landingpage_downloadSTT_btn" onClick={this.handleListDownload}>
                                                Download <img src={arrowd} id="img-download" alt="icon" />
                                                {this.state.listdownload === true &&
                                                    <div className="download-type-list">
                                                        <div className="item-type" onClick={() => download_meeting_handler("txt", transcript)}><span id="green">●</span>Txt<img src={downloadicon} alt="download" id="dl-icon" /></div>
                                                        <div className="item-type" onClick={() => download_meeting_handler("docx", transcript)}><span id="blue">●</span>Docx<img src={downloadicon} alt="download" id="dl-icon" /></div>
                                                    </div>
                                                }
                                            </button>
                                        }
                                        <button
                                            className="convert-btn"
                                            id="landingpage_retakeSTT"
                                            // disabled={streamAudioBlob.size > 0 ? true : false} 
                                            onClick={() => this.setState({ convertState: "", audioUrl: null })}
                                        >
                                            Rekam Ulang
                                        </button>
                                    </>
                                </div>
                            </>
                        }
                    </div>
                }

                {this.state.convertState === "failed" &&
                    <div className="loading">
                        <Image src={fail} id="i-failed" alt="ilustration" />
                        Gagal melakukan konversi
                        <span>{this.state.convertFailedInfo}</span>
                        <div className="btn-cancel-1" onClick={() => { this.setState({ convertState: "", audioUrl: false }) }}>Retake</div>
                    </div>
                }

            </div>
        )

    }
}

function mapStateToProps(state) {
    return {
        fetchingDataStreamStatus: state.STTReducers.fetchingDataStreamStatus,
        fetchedDataStream: state.STTReducers.fetchedDataStream,

        recordingStatus: state.STTReducers.recordingStatus,
        recordingDuration: state.STTReducers.recordingDuration,
        streamStatus: state.STTReducers.streamStatus,
        transcriptResult: state.STTReducers.transcriptResult,
        dataAudio: state.dataAudio
    }
}

export default connect(mapStateToProps)(STTDemo);
